import React from 'react';

const QuestionMark = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-220.000000, -1273.000000)">
        <g transform="translate(221.000000, 1274.000000)">
          <g id="Group-6">
            <circle id="Oval" stroke="#415765" strokeWidth="1.2" cx="8" cy="8" r="8" />
            <text fontFamily="GothamSSm-Bold, Gotham SSm" fontSize="11.2" fontWeight="bold" line-spacing="11.2" fill="#415765">
              <tspan x="5.5" y="12">?</tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default QuestionMark;
