import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import { parse } from 'query-string';
import loadable from '@loadable/component';
import { canUseDOM } from 'exenv';
import { List } from 'immutable';

import Loading from 'containers/common/loading';
import * as RoutingStyle from 'lib/routing-style';

/**
 * Preloads a components only if it's client-side
 * @param  {Array} components
 *         Array of React.Components that need to be preloaded
 * @return {null}
 *         null
 */
const preload = (components) => {
  if (canUseDOM) { components.forEach(component => component.preload()); }
};

const SearchWrapper = loadable(() => import('containers/search-wrapper'), {
  fallback: Loading,
});

const Checkout = loadable(() => import('containers/checkout'), {
  fallback: Loading,
});

const Receipt = loadable(() => import('containers/bookings/receipt'), {
  fallback: Loading,
});

const SignIn = loadable(() => import('containers/sign-in'), {
  fallback: Loading,
});

const SignUp = loadable(() => import('containers/sign-up'), {
  fallback: Loading,
});

const ParkingPasses = loadable(() => import('containers/parking-passes'), {
  fallback: Loading,
});

const OurAppsPage = loadable(() => import('containers/our-apps-page'), {
  fallback: Loading,
});

const Home = loadable(() => import('containers/home'), {
  fallback: Loading,
});

const OAuthPage = loadable(() => import('containers/oauth'), {
  fallback: Loading,
});

const HowItWorksPage = loadable(() => import('containers/how-it-works-page'), {
  fallback: Loading,
});

const BusinessPage = loadable(() => import('containers/business'), {
  fallback: Loading,
});

const ShippingAddressCapture = loadable(() => import('containers/shipping-address-capture'), {
  fallback: Loading,
});

const TransferPass = loadable(() => import('containers/account/transfer-pass'), {
  fallback: Loading,
});

const PressPage = loadable(() => import('containers/press-page'), {
  fallback: Loading,
});

const PressReleasePage = loadable(() => import('containers/press-release'), {
  fallback: Loading,
});

const ErrorPage = loadable(() => import('containers/error'), {
  fallback: Loading,
});

const PrivacyPolicyTermsPage = loadable(() => import('containers/legal'), {
  fallback: Loading,
});

const PasswordRecovery = loadable(() => import('containers/password-recovery'), {
  fallback: Loading,
});

const Search = loadable(() => import('containers/search'), {
  fallback: Loading,
});

const Account = loadable(() => import('containers/account'), {
  fallback: Loading,
});

const BookingCancel = loadable(() => import('containers/bookings/cancel'), {
  fallback: Loading,
});

const Venue = loadable(() => import('containers/venue'), {
  fallback: Loading,
});

const Hub = loadable(() => import('containers/hub'), {
  fallback: Loading,
});

const Location = loadable(() => import('containers/search/list/location-detail'), {
  fallback: Loading,
});

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string,
    key: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  app: PropTypes.string.isRequired,
  routingStyle: PropTypes.string,
  scrollToTop: PropTypes.func.isRequired,
  checkoutInitialized: PropTypes.bool.isRequired,
  receiptInitialized: PropTypes.bool.isRequired,
  cancelBookingInitialized: PropTypes.bool.isRequired,
  parkingPassesInitialized: PropTypes.bool.isRequired,
  signInInitialized: PropTypes.bool.isRequired,
  signUpInitialized: PropTypes.bool.isRequired,
  homePageInitialized: PropTypes.bool.isRequired,
  businessPageInitialized: PropTypes.bool.isRequired,
  howItWorksInitialized: PropTypes.bool.isRequired,
  ourAppsPageInitialized: PropTypes.bool.isRequired,
  oAuthPageInitialized: PropTypes.bool.isRequired,
  passwordRecoveryInitialized: PropTypes.bool.isRequired,
  initializeCheckout: PropTypes.func.isRequired,
  initializeReceipt: PropTypes.func.isRequired,
  initializeCancelBooking: PropTypes.func.isRequired,
  initializeParkingPasses: PropTypes.func.isRequired,
  initializeSignIn: PropTypes.func.isRequired,
  initializeSignUp: PropTypes.func.isRequired,
  initializeHomePage: PropTypes.func.isRequired,
  initializeBusinessPage: PropTypes.func.isRequired,
  initializeHowItWorks: PropTypes.func.isRequired,
  initializeOurAppsPage: PropTypes.func.isRequired,
  initializeOAuthPage: PropTypes.func.isRequired,
  initializePasswordRecovery: PropTypes.func.isRequired,
  initializePressPage: PropTypes.func.isRequired,
  pressPageInitialized: PropTypes.bool.isRequired,
  initializePressReleasePage: PropTypes.func.isRequired,
};

const defaultProps = {
  routingStyle: RoutingStyle.PARKWHIZ,
};

const Routes = ({
  app,
  routingStyle,
  scrollToTop,
  location,
  initializeCheckout,
  initializeReceipt,
  initializeCancelBooking,
  initializeParkingPasses,
  initializeSignIn,
  initializeSignUp,
  initializeHomePage,
  initializeBusinessPage,
  initializeHowItWorks,
  initializeOurAppsPage,
  initializeOAuthPage,
  initializePasswordRecovery,
  checkoutInitialized,
  receiptInitialized,
  cancelBookingInitialized,
  parkingPassesInitialized,
  signInInitialized,
  signUpInitialized,
  homePageInitialized,
  businessPageInitialized,
  howItWorksInitialized,
  ourAppsPageInitialized,
  oAuthPageInitialized,
  passwordRecoveryInitialized,
  initializePressPage,
  pressPageInitialized,
  initializePressReleasePage,
}) => {
  const query = parse(location.search);
  return (
    <Switch>
      <Route path="/404" render={() => <ErrorPage app={app} />} />
      <Route
        path="/support/terms"
        render={() => <PrivacyPolicyTermsPage app={app} />}
      />
      <Route
        path="/reserve/thankyou"
        render={() => {
          let { ids: selectedParkingPassIds } = query;
          if (typeof selectedParkingPassIds !== 'object') {
            selectedParkingPassIds = [selectedParkingPassIds];
          }
          if (!receiptInitialized) {
            initializeReceipt({ selectedParkingPassIds });
            if (canUseDOM) { scrollToTop(); }
          }
          preload([ParkingPasses]);
          return <Receipt routerLocation={location} />;
        }}
      />
      <Route
        path="/ticket/receipt/:bookingId"
        render={({ match }) => {
          const { bookingId } = match.params;
          if (!receiptInitialized) {
            initializeReceipt({ selectedParkingPassIds: [bookingId] });
            if (canUseDOM) { scrollToTop(); }
          }
          preload([ParkingPasses]);
          return <Receipt routerLocation={location} />;
        }}
      />
      <Route
        path="/ticket/monthly_receipt/:bookingId"
        render={({ match }) => {
          const { bookingId } = match.params;
          if (!receiptInitialized) {
            initializeReceipt({ selectedParkingPassIds: [bookingId] });
            if (canUseDOM) { scrollToTop(); }
          }
          preload([ParkingPasses]);
          return <Receipt routerLocation={location} />;
        }}
      />
      <Route
        path="/ticket/cancel/:bookingId"
        render={({ match }) => {
          let { bookingId } = match.params;
          bookingId = parseInt(bookingId, 10);
          if (!cancelBookingInitialized) {
            initializeCancelBooking({ selectedParkingPassIds: [bookingId] });
            if (canUseDOM) { scrollToTop(); }
          }
          preload([ParkingPasses, Account]);
          return <BookingCancel routerLocation={location} bookingId={bookingId} />;
        }}
      />
      <Route
        path="/reserve"
        render={() => {
          const { locationId } = (location.state && location.state.search) || {};
          if (!checkoutInitialized) {
            initializeCheckout({ quoteId: query.quote_id, locationId });
            if (canUseDOM) { scrollToTop(); }
          }
          preload([Receipt]);
          return <Checkout routerLocation={location} />;
        }}
      />
      <Route
        path="/oauth"
        render={() => {
          if (!oAuthPageInitialized) {
            initializeOAuthPage();
            if (canUseDOM) { scrollToTop(); }
          }
          return <OAuthPage routerLocation={location} />;
        }}
      />
      <Route
        path="/account/signup"
        render={() => {
          if (!signUpInitialized) {
            initializeSignUp();
            if (canUseDOM) { scrollToTop(); }
          }
          return <SignUp routerLocation={location} />;
        }}
      />
      <Route
        path="/account/signin"
        render={() => {
          if (!signInInitialized) {
            initializeSignIn();
            if (canUseDOM) { scrollToTop(); }
          }
          return <SignIn routerLocation={location} />;
        }}
      />
      <Route
        path="/resetpw"
        render={() => {
          if (!passwordRecoveryInitialized) {
            initializePasswordRecovery();
          }

          return <PasswordRecovery routerLocation={location} />;
        }}
      />
      <Route
        path="/account/transfer-pass"
        render={() => (
          <TransferPass />
        )}
      />
      <Route
        path="/account"
        render={() => {
          preload([ParkingPasses, Receipt, BookingCancel]);
          return <Account routerLocation={location} />;
        }}
      />
      <Route
        path="/ticket/:bookingId"
        render={({ match }) => {
          let { bookingId } = match.params;
          bookingId = parseInt(bookingId, 10);
          if (!parkingPassesInitialized) {
            initializeParkingPasses({ selectedParkingPassIds: [bookingId] });
            if (canUseDOM) { scrollToTop(); }
          }
          preload([BookingCancel]);
          return <ParkingPasses selectedParkingPassIds={List([bookingId])} />;
        }}
      />
      <Route
        path="/parking-app"
        render={() => {
          if (!ourAppsPageInitialized) {
            initializeOurAppsPage();
            if (canUseDOM) { scrollToTop(); }
          }
          return <OurAppsPage />;
        }}
      />
      <Route
        path="/how-it-works"
        render={() => {
          if (!howItWorksInitialized) {
            initializeHowItWorks();
            if (canUseDOM) { scrollToTop(); }
          }
          return <HowItWorksPage />;
        }}
      />
      <Route
        path="/business"
        render={() => {
          if (!businessPageInitialized) {
            initializeBusinessPage();
            if (canUseDOM) { scrollToTop(); }
          }
          return <BusinessPage routerLocation={location} />;
        }}
      />
      <Route
        path="/about/releases/:pressReleaseSlug"
        render={({ match }) => {
          const { pressReleaseSlug } = match.params;
          initializePressReleasePage({ pressReleaseSlug });
          if (canUseDOM) { scrollToTop(); }
          return <PressReleasePage />;
        }}
      />
      <Route
        path="/about/press"
        render={() => {
          if (!pressPageInitialized) {
            initializePressPage();
          }
          if (canUseDOM) { scrollToTop(); }
          return <PressPage />;
        }}
      />
      <Route
        path="/shipping-address/:bookingId"
        render={({ match }) => {
          const { bookingId } = match.params;
          const { u } = query;
          return (
            <ShippingAddressCapture
              authorizationCode={u}
              bookingId={bookingId}
            />
          );
        }}
      />
      <Route
        exact
        path="/"
        render={() => {
          if (!homePageInitialized) {
            initializeHomePage();
            if (canUseDOM) { scrollToTop(); }
          }
          preload([Search]);
          return <Home />;
        }}
      />
      <Route
        path="/parking-near-me"
        render={() => {
          preload([Search, Location, Checkout]);
          return <SearchWrapper location={location} app={app} />;
        }}
      />
      <Route
        path="/*"
        render={() => {
          preload([Hub, Venue, Search, Location, Checkout]);
          return <SearchWrapper location={location} app={app} routingStyle={routingStyle} />;
        }}
      />
    </Switch>
  );
};

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
