import { stringify } from 'query-string';
import env, { invalidateCachedAssets } from 'lib/env';
import isEmpty from 'lodash/isEmpty';

export function removeQueryParameter(url, parameter) {
  // Prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length < 2) { return url; }

  const prefix = `${encodeURIComponent(parameter)}=`;
  const pars = urlparts[1].split(/[&;]/g);

  // Reverse iteration as may be destructive
  for (let i = pars.length; i-- > 0;) {
    // Idiom for string.startsWith
    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
      pars.splice(i, 1);
    }
  }

  return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
}

const TYPE_INTEGER = 1;
const TYPE_NUMBER = 3;
const TYPE_STRING = 2;

const ALLOWED_QUERYSTRING_ARGS = new Map([
  ['lat', TYPE_NUMBER],
  ['lng', TYPE_NUMBER],
  ['event_id', TYPE_INTEGER],
  ['monthly', TYPE_INTEGER],
  ['daily', TYPE_INTEGER],
  ['seller_id', TYPE_INTEGER],
  ['quote_id', TYPE_STRING],
  ['start', TYPE_STRING],
  ['end', TYPE_STRING],
  ['meta', TYPE_STRING],
  ['coupon', TYPE_STRING],
  ['event_package_ids', TYPE_STRING],
  ['event_package_id', TYPE_INTEGER],
  ['view', ['map', 'list']],
]);

export function buildCleanUrl(req) {
  const query = req.query;
  const { sanitizedQuery, outputQuery } = sanitizeQuery(query);
  const url = !isEmpty(sanitizedQuery) ? `${req.path}?${sanitizedQuery}` : req.path;
  return { url, query: outputQuery };
}

export function sanitizeQuery(query) {
  const outputQuery = {};
  for (const [key, keyType] of ALLOWED_QUERYSTRING_ARGS) {
    const queryValue = query[key];
    if (queryValue != null) {
      switch (keyType) {
        case TYPE_INTEGER:
          if (isInteger(Number(queryValue, 10))) {
            outputQuery[key] = queryValue;
          }
          break;
        case TYPE_NUMBER:
          if (isNumber(queryValue)) {
            outputQuery[key] = queryValue;
          }
          break;
        case TYPE_STRING:
          outputQuery[key] = queryValue;
          break;
        default:
          if (Array.isArray(keyType) && keyType.includes(queryValue)) {
            outputQuery[key] = queryValue;
          } else {
            delete outputQuery[key];
          }
          break;
      }
    }
  }
  const sanitizedQuery = stringify(outputQuery);
  return { sanitizedQuery, outputQuery };
}

export function isNumber(obj) {
  return !isNaN(parseFloat(obj));
}

function isInteger(value) {
  return typeof value === 'number' &&
    isFinite(value) &&
    Math.floor(value) === value;
}

/**
 * IMPORTANT: ONLY pass fixed strings into this.
 *
 * If we pass in a variable with a fully-formed URL string, for example, it will BREAK.
 * It is not safe to use this method with arguments that may change (dynamic URL's).
 *
 * Prepend our CDN host URL to asset urls (usually images) so that get them via our CDN.
 * Also appends git hash to them for asset versioning.
 *
 * Original source of this code is the php Util::stat method in this repo.
 *
 * @param  {string} path
 *         URL path to modify
 * @return {string}
 *         Returns empty string for falsy values. For all else returns modified URL.
 */
export function imageCdnAsset(path = '') {
  // safety checks
  if (!path) { return ''; }
  if (path.substring(0, 7) === '/static') { return path; }

  const { CDN_HTTPS_HOST, GIT_HASH } = env();

  // only set GIT_HASH on the URL for cache-busting when INVALIDATE_CACHED_ASSETS is true.
  // it is basically always true except for local dev.
  const pathName = invalidateCachedAssets()
    ? path.concat('.', GIT_HASH)
    : path;

  if (CDN_HTTPS_HOST) {
    return CDN_HTTPS_HOST.concat(pathName);
  }

  // fallback
  return pathName;
}

/**
 * Use imageCdnAsset() to prepend all URL's in an img srcSet with our CDN_HTTPS_HOST host URL.
 *
 * @param   {string }srcSet
 *          img srcSet string. Examples:
 *          "/images/home/app-screenshot.png 203w, /images/home/app-screenshot.png 406w"
 *          "/images/home/app-screenshot.png,/images/home/app-screenshot.png 406w"
 * @return  {string}
 *          Results for examples:
 *          "/my-cdn-base-url/images/home/app-screenshot.png 203w, /my-cdn-base-url/images/home/app-screenshot.png 406w"
 *          "/my-cdn-base-url/images/home/app-screenshot.png, /my-cdn-base-url/images/home/app-screenshot.png 406w"
 */
export const imageCdnAssetSet = (srcSet = '') => {
  if (!srcSet) { return ''; }
  if (srcSet.includes('data:image/gif')) { return srcSet; }

  return srcSet
    .split(',')
    .map((src) => {
      const [imgUrl, width = ''] = src.trim().split(' ');
      return `${imageCdnAsset(imgUrl)} ${width}`.trim();
    })
    .join(', ');
};

export function cdnAsset(path) {
  const {
    ASSET_CDN_HOST,
    GIT_HASH,
  } = env();

  if (!ASSET_CDN_HOST || !GIT_HASH) { return path; }

  return `${ASSET_CDN_HOST}/${GIT_HASH}${path}`;
}

export function buildRedirectURI(routerLocation) {
  if (!routerLocation) { return null; }

  const redirectURI = encodeURIComponent(`${routerLocation.pathname}${routerLocation.search}`);
  return `?redirect_uri=${redirectURI}`;
}

export function parseDomain(host = '') {
  const urlParts = host.split('.');
  const tld = urlParts.pop();
  const domain = urlParts.pop();
  const subdomain = urlParts.join('.');

  return { tld, domain, subdomain };
}

export const isValidRedirectURI = redirectURI => (
  redirectURI && !redirectURI.match(/^\/account\/sign/) && redirectURI !== 'undefined'
);
