import { OrderedMap, Record, List } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';

import { Amenities, VALID_AMENITY_KEYS } from 'models/amenities';
import { AddOn } from 'models/addons';
import CancellableStatus from 'models/cancellable-status';
import Validation, { PASS_VALUE_METHOD } from 'models/validation';
import ShuttleTime from 'models/shuttle-times';
import { Vehicle } from 'models/vehicles';

import env from 'lib/env';

const { NODE_BASE_V4_API_URL } = env();
const GATE_BUFFER = 1;

export const TIME_FORMAT = 'h:mmA';
export const DATE_FORMAT = 'ddd MMM D';

export class ParkingPass extends Record({
  amenities: null,
  addOns: null,
  endTime: null,
  fullPrice: null,
  id: null,
  passType: null,
  pricePaid: null,
  startTime: null,
  status: null,
  validation: null,
  vehicleId: null,
  vehicle: null,
  disclaimers: null,
  locationId: null,
  arriveBy: null,
  departAfter: null,
  minParkingHours: null,
  hoursAfterEvent: null,
  eventId: null,
  venueId: null,
  pricingEventId: null,
  sellerId: null,
  packageName: null,
  isExpired: false,
  authorizationCode: null,
  resellerPass: false,
  // TODO: Remove `cancellable` once Parking Pass components updated. Replaced by cancellable_status.
  cancellable: false,
  cancellableStatus: null,
  active: true,
  pricingCode: null,
  orderId: null,
  customerId: null,
  pickupInstructions: null,
  dropoffInstructions: null,
  shuttleTimesToVenue: [],
  shuttleTimesFromVenue: [],
  displayCancelLink: true,
  firstname: '',
  lastname: '',
  showNameOnPass: false,
}) {
  constructor(props) {
    const parkingPass = props._embedded && props._embedded['pw:parking_pass'] ? props._embedded['pw:parking_pass'] : {};
    const location = parkingPass._embedded && parkingPass._embedded['pw:location'] ? parkingPass._embedded['pw:location'] : null;

    const amenities = parkingPass.amenities ? new Amenities(parkingPass.amenities) : null;
    const validation = parkingPass.validation ? new Validation(parkingPass.validation) : null;
    const { id, status } = parkingPass;

    const locationCurrency = get(location, 'currency');
    const fullPrice = get(parkingPass, ['full_price', locationCurrency]);
    const pricePaid = get(parkingPass, ['price_paid', locationCurrency]);

    let vehicle;
    let vehicleId;
    if (parkingPass._embedded && parkingPass._embedded['pw:vehicle']) {
      vehicle = new Vehicle(parkingPass._embedded['pw:vehicle']);
      vehicleId = vehicle.id;
    }

    const customerId = parkingPass.customer_id;
    const pricingCode = parkingPass.pricing_codes && parkingPass.pricing_codes[0];

    let startTime = moment(parkingPass.start_time);
    let endTime = moment(parkingPass.end_time);
    if (location && location.timezone) {
      startTime = startTime.tz(location.timezone);
      endTime = endTime.tz(location.timezone);
    }

    const addOns = { vehicleSize: [], general: [] };

    if (parkingPass._embedded && parkingPass._embedded['pw:add_ons']) {
      addOns.vehicleSize = new List(parkingPass._embedded['pw:add_ons'].filter(a => a.type === 'vehicle_size').map(addOn => new AddOn(addOn, { currency: locationCurrency })));
      addOns.general = new List(parkingPass._embedded['pw:add_ons'].filter(a => a.type !== 'vehicle_size').map(addOn => new AddOn(addOn, { currency: locationCurrency })));
    }

    const arriveBy = parkingPass.arrive_by ? moment(parkingPass.arrive_by, TIME_FORMAT) : null;
    const departAfter = parkingPass.depart_after ? moment(parkingPass.depart_after, TIME_FORMAT) : null;

    const isExpired = ParkingPass.expired(parkingPass);
    const displayCancelLink = parkingPass.display_cancel_link;

    const shuttleTimesToVenue = [];
    if (parkingPass.shuttle_times && parkingPass.shuttle_times.to_venue) {
      parkingPass.shuttle_times.to_venue.forEach((st) => {
        shuttleTimesToVenue.push(new ShuttleTime(
          st,
          startTime.clone(),
          startTime.clone().add(6, 'hours'),
        ));
      });
    }

    const shuttleTimesFromVenue = [];
    if (parkingPass.shuttle_times && parkingPass.shuttle_times.from_venue) {
      parkingPass.shuttle_times.from_venue.forEach((st) => {
        shuttleTimesFromVenue.push(new ShuttleTime(
          st,
          endTime.clone().subtract(3, 'hours'),
          endTime.clone().add(6, 'hours'),
        ));
      });
    }

    const firstname = get(props, '_embedded["pw:customer"].first_name', '');
    const lastname = get(props, '_embedded["pw:customer"].last_name', '');

    const showNameOnPass = get(props, '_embedded[pw:parking_pass].validation.display.customer_name', false);

    const cancellableStatus = new CancellableStatus(props.cancellable_status);

    super({
      amenities,
      addOns: new OrderedMap(addOns),
      endTime,
      fullPrice,
      id,
      passType: parkingPass.pass_type,
      pricePaid,
      startTime,
      status,
      validation,
      vehicleId,
      vehicle,
      disclaimers: parkingPass.disclaimers,
      locationId: location ? location.id : null,
      arriveBy,
      departAfter,
      minParkingHours: parkingPass.min_parking_hours,
      hoursAfterEvent: parkingPass.hours_after_event,
      eventId: parkingPass.event_id,
      pricingEventId: parkingPass.pricing_event_id,
      sellerId: parkingPass.seller_id,
      packageName: props.package && props.package.name,
      isExpired,
      authorizationCode: props.authorization_code,
      resellerPass: parkingPass.reseller_pass,
      cancellable: cancellableStatus.cancellableNow,
      cancellableStatus,
      active: !!(parkingPass.active),
      pricingCode,
      orderId: props.order_id,
      customerId,
      pickupInstructions: parkingPass.pickup_instructions,
      dropoffInstructions: parkingPass.dropoff_instructions,
      venueId: parkingPass._embedded && parkingPass._embedded['pw:venue'] && parkingPass._embedded['pw:venue'].id,
      shuttleTimesToVenue,
      shuttleTimesFromVenue,
      displayCancelLink,
      firstname,
      lastname,
      showNameOnPass: showNameOnPass === 'required',
    });
  }

  requireLicensePlate() {
    return !!(this.validation && this.validation.requireLicensePlate && !this.vehicleId);
  }

  getLocation(locations) {
    return this.locationId && locations.get(this.locationId.toString());
  }

  getSeller(sellers) {
    return this.sellerId && sellers.get(this.sellerId.toString());
  }

  getEvent(events) {
    return this.eventId && (events.get(this.eventId.toString()) || events.get(this.eventId));
  }

  getVenue(venues, events) {
    const event = this.getEvent(events);
    if (event && event.venueId) {
      return venues.get(event.venueId.toString()) || venues.get(event.venueId);
    }

    if (!this.venueId) { return null; }
    const venue = venues.get(this.venueId.toString());
    if (venue && venue.name) {
      return venue;
    }

    return null;
  }

  getVehicle(vehicles) {
    if (this.vehicle) { return this.vehicle; }
    return this.vehicleId && vehicles.get(this.vehicleId.toString());
  }

  showEventBuffer() {
    return !!(this.eventId && this.eventId === this.pricingEventId);
  }

  get url() {
    return `/ticket/${this.id}?u=${this.authorizationCode}`;
  }

  static expired(passProps) {
    return !passProps.active || (moment() > moment(passProps.end_time).add(3, 'hours'));
  }

  get scanCodeURL() {
    const scanCode = this.validation.scanCode;
    const width = scanCode.get('format') === 'QR' ? 105 : 200;
    const height = scanCode.get('format') === 'QR' ? 105 : 50;
    return `${NODE_BASE_V4_API_URL}/scan_codes?scan_code_format=${scanCode.get('format')}&code=${scanCode.get('code')}&format=png&width=${width}&height=${height}&margin=0`;
  }

  get gateAvailable() {
    return this.startTime.isBefore(moment().add(GATE_BUFFER, 'hours'));
  }

  get showPassValue() {
    const { requiredMethods } = this.validation;
    return !!(requiredMethods.get(PASS_VALUE_METHOD) || this.resellerPass);
  }

  get validAmenities() {
    if (this.amenities) {
      return VALID_AMENITY_KEYS.map(key => this.amenities.get(key)).filter(a => (a && a.visible));
    }

    return List();
  }
}

export function ParkingPasses(props) {
  const parkingPasses = {};
  if (!props) { return OrderedMap(parkingPasses); }

  props.forEach((parkingPass) => {
    const newPass = new ParkingPass(parkingPass);
    parkingPasses[newPass.id] = newPass;
  });

  return OrderedMap(parkingPasses);
}
