import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import { OrderedMap, Map } from 'immutable';
import cx from 'classnames';
import moment from 'moment-timezone';
import get from 'lodash/get';
import result from 'lodash/result';
import { canUseDOM } from 'exenv';
import 'react-dates/initialize';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import Insights from '@parkwhiz-js/insights-sdk';

import MobileMenu from 'components/common/mobile-menu';
import Header from 'components/common/header';
import LoadingIndicator from 'components/common/loading-indicator';
import ErrorBoundary from 'components/common/error-boundary';
import PartnerTransitionInterstitial from 'components/common/partner-transition-interstitial';
import CustomerIntercept from 'components/common/customer-intercept';

import Admin from 'containers/common/admin';
import Modal from 'containers/common/modal';
import Messages from 'containers/messages/index';
import DeepLinkBanner from 'containers/deep-links/banner';
import AnnouncementBanner from 'containers/announcement-banner';
import Footer from 'containers/common/footer';
import ReviewModal from 'containers/bookings/review-modal';
import SearchRestrictionModal from 'containers/search/search-restriction-modal';

import Coordinates from 'models/coordinates';
import UserModel from 'models/user';
import PartnerModel from 'models/partner';
import SearchModel from 'models/search';
import VenueModel from 'models/venue';
import EventModel from 'models/event';
import HubModel from 'models/hub';
import FeatureFlags from 'models/feature-flags';
import Brand from 'models/brand';

import Routes from 'routes';

import RequestQueue from 'lib/api/request-queue';
// TODO: Move this later into search after a DOM Refactor of some kind
import CheckoutButton from 'containers/search/checkout-button';
import { pageProps } from 'lib/analytics/page-properties';
import { SEARCH_RETURNED, HOME_PAGE_NAV_CLICK } from 'lib/analytics/events';
import * as Apps from 'lib/app-names';

import onPopState from 'action-creators/router/on-pop-state';
import replaceLocation from 'action-creators/router/replace-location';
import trackEventCreator from 'action-creators/analytics/track-event';
import signIn from 'action-creators/account/sign-in';
import addMessage from 'action-creators/messaging/add-message';
import businessEnroll from 'action-creators/brand/business-enroll';
import changeTimes from 'action-creators/search/change-times';
import changeParkingType from 'action-creators/search/change-parking-type';
import setModalState from 'action-creators/modal/set-modal-state';

import initializeCheckoutCreator from 'action-creators/checkout/initialize-checkout';
import initializeReceiptCreator from 'action-creators/bookings/initialize-receipt';
import initializeParkingPassesCreator from 'action-creators/bookings/initialize-parking-passes';
import initializeSignInCreator from 'action-creators/account/initialize-sign-in';
import initializeSignUpCreator from 'action-creators/account/initialize-sign-up';
import initializePasswordRecoveryCreator from 'action-creators/account/initialize-password-recovery';
import initializeHomePageCreator from 'action-creators/brand/initialize-home-page';
import initializeBusinessPageCreator from 'action-creators/brand/initialize-business-page';
import initializeHowItWorksCreator from 'action-creators/brand/initialize-how-it-works';
import initializeOurAppsPageCreator from 'action-creators/brand/initialize-our-apps-page';
import initializeOAuthPageCreator from 'action-creators/brand/initialize-oauth-page';
import initializeCancelBookingCreator from 'action-creators/bookings/initialize-cancel-booking';
import initializePressPageCreator from 'action-creators/app/initialize-press-page';
import initializePressReleasePageCreator from 'action-creators/app/initialize-press-release-page';

import dismissPartnerModalCreator from 'action-creators/brand/dismiss-partner-modal';

const propTypes = {
  affiliateId: PropTypes.string,
  requestQueueLength: PropTypes.number,
  brandingImageUrl: PropTypes.string,
  displayDeepLinkBanner: PropTypes.bool,
  displayMap: PropTypes.bool,
  user: PropTypes.instanceOf(UserModel),
  bookingCounts: PropTypes.instanceOf(Map),
  app: PropTypes.string.isRequired,
  isOrganic: PropTypes.bool,
  unsupportedBrowser: PropTypes.bool,
  displayModal: PropTypes.bool,
  partner: PropTypes.instanceOf(PartnerModel),
  locations: PropTypes.instanceOf(OrderedMap),
  currentSearch: PropTypes.instanceOf(SearchModel),
  venue: PropTypes.instanceOf(VenueModel),
  event: PropTypes.instanceOf(EventModel),
  hub: PropTypes.instanceOf(HubModel),
  timezone: PropTypes.string,
  requestQueue: PropTypes.instanceOf(RequestQueue).isRequired,
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  insights: PropTypes.instanceOf(Insights).isRequired,
  monthlyStart: PropTypes.instanceOf(moment),
  featureFlags: PropTypes.instanceOf(FeatureFlags).isRequired,
  affiliatePartner: PropTypes.instanceOf(Map),
  searchRequestId: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    action: PropTypes.string,
    length: PropTypes.number,
    block: PropTypes.func,
    createHref: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    listen: PropTypes.func,
    push: PropTypes.func,
    replace: PropTypes.func,
    location: PropTypes.shape({
      hash: PropTypes.string,
      key: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
  locale: PropTypes.string.isRequired,
  translations: PropTypes.instanceOf(Map),

  onPopState: PropTypes.func.isRequired,
  replaceLocation: PropTypes.func.isRequired,
  timesChange: PropTypes.func.isRequired,
  changeParkingType: PropTypes.func.isRequired,
  changeModalState: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  businessEnroll: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  reviewModalDismissed: PropTypes.bool.isRequired,
  appContext: PropTypes.string.isRequired,
  dismissPartnerModal: PropTypes.func.isRequired,
  partnerModalDismissed: PropTypes.bool.isRequired,
  routerLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  usejs: PropTypes.bool,
};

const defaultProps = {
  affiliateId: null,
  requestQueueLength: 0,
  brandingImageUrl: null,
  children: null,
  displayDeepLinkBanner: false,
  displayMap: false,
  toggleMap: null,
  user: null,
  bookingCounts: Map(),
  unsupportedBrowser: false,
  displayModal: false,
  partner: null,
  locations: null,
  currentSearch: null,
  isOrganic: true,
  venue: null,
  event: null,
  hub: null,
  timezone: null,
  monthlyStart: moment(),
  affiliatePartner: Map(),
  searchRequestId: null,
  translations: Map(),
  usejs: true,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
    };

    addLocaleData([...en, ...fr]);

    this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
    this.onPopState = this.onPopState.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentWillMount() {
    if (canUseDOM) {
      const { history } = this.props;
      this.props.replaceLocation();
      history.listen((location, action) => {
        if (action === 'POP') {
          this.props.onPopState(location.state);
        }
      });
    }
  }

  componentDidMount() {
    const { searchRequestId, app, currentSearch, trackEvent } = this.props;
    if (app === 'Search' && searchRequestId) {
      trackEvent({
        ...pageProps({ app, currentSearch }),
        ...SEARCH_RETURNED,
        properties: {
          Search_Request_Id: searchRequestId,
        },
      });
    }
  }

  onPopState(e) {
    e.preventDefault();
    this.props.onPopState(this.props.location.state);
  }

  get displayDeepLinkBanner() {
    const deepLinkBanner = result(this.deepLinkBanner, 'getWrappedInstance', null);
    return get(deepLinkBanner, 'displayable', false);
  }

  handleNavbarToggle() {
    if (this.props.app === 'Home') {
      this.props.trackEvent(HOME_PAGE_NAV_CLICK);
    }
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
    window.scrollTo(0, 0);
  }

  scrollToTop() {
    window.scrollTo(0, 0);
    if (this.wrapper) {
      this.wrapper.scrollTop = 0;
    }
  }

  footerStyle() {
    switch (this.props.app) {
      case Apps.CHECKOUT_APP:
        return 'none';
      case Apps.SEARCH_APP:
      case Apps.RECEIPT_APP:
        return 'minimal';
      default:
        return 'full';
    }
  }

  navigationStyle() {
    const { displayModal, app } = this.props;
    if (displayModal) {
      switch (app) {
        case Apps.HOME_APP:
          return 'hybrid';
        default:
          return 'floating';
      }
    }

    switch (app) {
      case Apps.HOME_APP:
      case Apps.HUB_APP:
      case Apps.HOW_IT_WORKS_APP:
      case Apps.LEGAL_APP:
      case Apps.PRESS_APP:
        return 'hybrid';
      default:
        return 'floating';
    }
  }

  headerStyle() {
    const { affiliateId, app, partner, affiliatePartner } = this.props;

    if (app === Apps.BUSINESS_APP) {
      return 'Business';
    }

    if (affiliateId && affiliatePartner && affiliatePartner.get('brandingImageUrl')) {
      return 'PartnerAffiliate';
    }

    switch (app === Apps.PARKING_PASS_APP && partner && partner.name) {
      case 'Groupon':
        return 'Groupon';
      case 'Ford':
        return 'Ford';
      case 'Lincoln':
        return 'Lincoln';
      case 'BestParking':
        return 'BestParking';
      case 'Ticketmaster':
        return 'Ticketmaster';
      default:
        return 'Normal';
    }
  }

  renderMessages({ float } = { float: false }) {
    if ((float === (this.navigationStyle() === 'floating')) || this.props.app === 'Search') {
      return null;
    }

    return <Messages />;
  }

  get modalRendered() {
    const { displayModal, reviewModalDismissed } = this.props;
    const { reviewModal } = this;

    return displayModal || (get(reviewModal, ['modal', 'shouldRender'], false) && !reviewModalDismissed);
  }

  render() {
    const {
      geoIPLocation,
      user,
      requestQueue,
      app,
      affiliatePartner,
      brand,
      translations,
      locale,
      insights,
      hub,
      venue,
      trackEvent,
      currentSearch,
      appContext,
      changeModalState,
      dismissPartnerModal,
      partnerModalDismissed,
    } = this.props;

    const siteWrapClasses = cx({
      'show-mobile-menu': this.state.showMobileMenu,
      // Need both the prop and the component logic to trigger re-render
      'with-deep-link-banner': this.displayDeepLinkBanner && this.props.displayDeepLinkBanner,
    });

    const contentClasses = cx({
      'snap-content': true,
      'modal-displayed': this.modalRendered,
      'receipt-page': app === 'Receipt',
    });

    const nonHeaderContentClasses = cx({
      'scrollable': true,
      'with-sub-navbar': app === 'Search',
      'margin-top-0-important': !brand.showHeader,
    });

    return (
      <ErrorBoundary>
        <IntlProvider locale={locale} messages={translations.get(locale)}>
          <Fragment>
            {this.renderMessages({ float: true })}
            <div id="site-wrap" className={siteWrapClasses}>
              <PartnerTransitionInterstitial
                brand={brand}
                appContext={appContext}
                changeModalState={changeModalState}
                dismissPartnerModal={dismissPartnerModal}
                partnerModalDismissed={partnerModalDismissed}
              />
              <MobileMenu
                geoIPLocation={geoIPLocation}
                requestQueue={requestQueue}
                user={user}
                insights={insights}
                currentSearch={currentSearch}
                trackEvent={trackEvent}
                hub={hub}
                venue={venue}
                app={app}
                appContext={this.props.appContext}
                brand={brand}
                showMobileMenu={this.state.showMobileMenu}
                routerLocation={this.props.routerLocation}
              />
              <DeepLinkBanner
                ref={(b) => { this.deepLinkBanner = b; }}
              />
              <Header
                affiliateId={this.props.affiliateId}
                app={app}
                brandingImageUrl={affiliatePartner && affiliatePartner.get('brandingImageUrl')}
                geoIPLocation={geoIPLocation}
                user={user}
                insights={insights}
                displayMap={this.props.displayMap}
                handleNavbarToggle={this.handleNavbarToggle}
                requestQueue={requestQueue}
                scrollToTop={this.scrollToTop}
                showMobileMenu={this.state.showMobileMenu}
                location={this.props.location}
                headerStyle={this.headerStyle()}
                navigationStyle={this.navigationStyle()}
                changeParkingType={this.props.changeParkingType}
                currentSearch={currentSearch}
                venue={this.props.venue}
                event={this.props.event}
                timezone={this.props.timezone}
                timesChange={this.props.timesChange}
                changeModalState={this.props.changeModalState}
                monthlyStart={this.props.monthlyStart}
                trackEvent={trackEvent}
                signIn={this.props.signIn}
                businessEnroll={this.props.businessEnroll}
                addMessage={this.props.addMessage}
                brand={brand}
              />
              {/*
                Don't remove this wrapper. It's needed for mobile safari
                scroll. Ask Mike if you have concerns.
              */}
              <div id="content-wrapper">
                <div id="content" className={contentClasses}>
                  <div id="non-header-content" className={nonHeaderContentClasses}>
                    <div id="main-content">
                      {this.renderMessages()}
                      <div id="app" ref={(w) => { this.wrapper = w; }}>
                        <Routes
                          {...this.props}
                          scrollToTop={this.scrollToTop}
                          routingStyle={brand.routingStyle}
                        />
                      </div>
                    </div>
                    <Footer footerStyle={this.footerStyle()} brand={brand} />
                  </div>
                </div>
              </div>
              <LoadingIndicator requestQueueLength={this.props.requestQueueLength} />
              <CheckoutButton />
              <Admin />
              <Modal />
              <ReviewModal ref={(r) => { this.reviewModal = result(r, 'getWrappedInstance'); }} />
              <SearchRestrictionModal />
              <CustomerIntercept affiliatePartner={affiliatePartner} isOrganic={this.props.isOrganic} />
              <AnnouncementBanner />
            </div>
          </Fragment>
        </IntlProvider>
      </ErrorBoundary>
    );
  }
}


const mapStateToProps = (state) => {
  const {
    name: app,
    featureFlags,
    unsupportedBrowser,
    affiliatePartner,
    partner,
    pressPageInitialized,
    locale,
    translations,
    appContext,
    usejs,
  } = state.app;

  const {
    user,
    signInInitialized,
    signUpInitialized,
    passwordRecoveryInitialized,
  } = state.account;

  const { bookingCounts } = user;

  const {
    currentSearch,
    locations,
    geoIPLocation,
    displayMap,
    timezone,
    event,
    venue,
    hub,
    selectedLocation,
    selectedQuote,
    searchRequestId,
  } = state.search;

  const {
    requestQueue,
    requestQueueLength,
  } = state.requests;

  const {
    display: displayDeepLinkBanner,
  } = state.deepLinks;

  const {
    affiliateId,
    monthlyStart,
    initialized: checkoutInitialized,
  } = state.checkout;

  const {
    receiptInitialized,
    parkingPassesInitialized,
    cancelBookingInitialized,
    reviewModalDismissed,
  } = state.bookings;

  const {
    insights,
    trackingProperties,
  } = state.analytics;

  const {
    displayModal,
  } = state.modal;

  const {
    homePageInitialized,
    businessPageInitialized,
    howItWorksInitialized,
    ourAppsPageInitialized,
    oAuthPageInitialized,
    brand,
    partnerModalDismissed,
  } = state.brand;

  const { isOrganic } = trackingProperties;

  const {
    location: routerLocation,
  } = state.router;

  const brandingImageUrl = affiliatePartner && affiliatePartner.get('brandingImageUrl');

  return {
    selectedLocation,
    selectedQuote,
    currentSearch,
    locations,
    user,
    bookingCounts,
    requestQueue,
    requestQueueLength,
    displayModal,
    geoIPLocation,
    displayMap,
    unsupportedBrowser,
    displayDeepLinkBanner,
    partner,
    affiliateId,
    monthlyStart,
    insights,
    app,
    event,
    venue,
    hub,
    featureFlags,
    isOrganic,
    timezone,
    affiliatePartner,
    brandingImageUrl,
    checkoutInitialized,
    receiptInitialized,
    parkingPassesInitialized,
    signInInitialized,
    signUpInitialized,
    homePageInitialized,
    businessPageInitialized,
    howItWorksInitialized,
    ourAppsPageInitialized,
    oAuthPageInitialized,
    passwordRecoveryInitialized,
    cancelBookingInitialized,
    brand,
    searchRequestId,
    reviewModalDismissed,
    pressPageInitialized,
    locale,
    translations,
    appContext,
    partnerModalDismissed,
    routerLocation,
    usejs,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    onPopState,
    replaceLocation,
    timesChange: changeTimes,
    changeParkingType,
    changeModalState: setModalState,
    signIn,
    addMessage,
    businessEnroll,
    trackEvent: trackEventCreator,
    initializeCheckout: initializeCheckoutCreator,
    initializeReceipt: initializeReceiptCreator,
    initializeParkingPasses: initializeParkingPassesCreator,
    initializeSignIn: initializeSignInCreator,
    initializeSignUp: initializeSignUpCreator,
    initializeHomePage: initializeHomePageCreator,
    initializeBusinessPage: initializeBusinessPageCreator,
    initializeHowItWorks: initializeHowItWorksCreator,
    initializeOurAppsPage: initializeOurAppsPageCreator,
    initializeOAuthPage: initializeOAuthPageCreator,
    initializePasswordRecovery: initializePasswordRecoveryCreator,
    initializeCancelBooking: initializeCancelBookingCreator,
    initializePressPage: initializePressPageCreator,
    initializePressReleasePage: initializePressReleasePageCreator,
    dismissPartnerModal: dismissPartnerModalCreator,
  }, dispatch)
);

App.propTypes = propTypes;
App.defaultProps = defaultProps;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
