import { Record, Set, Map } from 'immutable';
import moment from 'moment-timezone';
import trim from 'lodash/trim';
import get from 'lodash/get';

import Pricing from 'models/pricing';
import { AddOns } from 'models/addons';
import { Events } from 'models/event';
import { Locations } from 'models/locations';
import Search from 'models/search';

import { PARKING_UNAVAILABLE_MESSAGE } from 'components/checkout/modal-notices';

import { GOT_PRICING } from 'action-creators/checkout/got-pricing';
import { GET_PREVIEW } from 'action-creators/checkout/get-preview';
import { GOT_PREVIEW } from 'action-creators/checkout/got-preview';
import { CHANGE_ADD_ONS } from 'action-creators/checkout/change-add-ons';
import { CHANGE_MONTHLY_START } from 'action-creators/checkout/change-monthly-start';
import { TOGGLE_BOOK_EXTENDED_TIMES } from 'action-creators/checkout/toggle-book-extended-times';
import { DISABLE_BOOK_EXTENDED_TIMES } from 'action-creators/checkout/disable-book-extended-times';
import { SET_DEVICE_DATA } from 'action-creators/checkout/set-device-data';
import { CLEANUP_CHECKOUT_STATE } from 'action-creators/checkout/cleanup-checkout-state';
import { UPDATE_SEARCH } from 'action-creators/search/update-search';
import { CHECKOUT_MODIFY_REQUIRED_FIELD_LIST } from 'action-creators/checkout/modify-required-field-list';
import { CHANGE_PAYMENT_METHOD } from 'action-creators/payment-methods/change-payment-method';
import { TOGGLE_SAVE_PAYMENT } from 'action-creators/checkout/toggle-save-payment';
import { INITIALIZE_CHECKOUT } from 'action-creators/checkout/initialize-checkout';
import { SUBMIT_CHECKOUT } from 'action-creators/checkout/submit-checkout';
import { UNSET_FORM_SUBMITTED } from 'action-creators/checkout/unset-form-submitted';
import { SET_ERROR } from 'action-creators/checkout/set-error';
import { SET_API_ERROR } from 'action-creators/checkout/set-api-error';
import { CLEAR_ERROR } from 'action-creators/checkout/clear-error';
import { CHANGE_USER_INFO } from 'action-creators/account/change-user-info';
import { SET_REQUIRED_FIELD_ERRORS } from 'action-creators/payment-methods/set-required-field-errors';
import { GOT_VENUE } from 'action-creators/search/got-venue';
import { ADD_PREPURCHASE_LICENSE_PLATE } from 'action-creators/checkout/add-prepurchase-license-plate';
import { CHECKOUT_SET_SHOULD_SCROLL_TO_ERROR } from 'action-creators/checkout';

export class CheckoutState extends Record({
  requiredFieldErrors: Map(),
  apiError: Map(),
  error: Map(),
  alwaysValidateRequiredFields: false,
  shouldScrollToError: false,
  bookExtendedTimes: true,
  displayTimeChangeModal: false,
  subTotal: null,
  checkoutTotal: null,
  couponCode: null,
  autoApplyCoupon: true,
  couponAmount: null,
  pricing: new Pricing(),
  userCreditAmount: null,
  includeAll: true,
  selectedAddOns: new AddOns(),
  monthlyStart: moment(),
  requiredFields: Set(),
  affiliateId: null,
  portalAffiliateId: null,
  deviceData: null,
  submitted: false,
  isPaypal: false,
  saveCreditCard: true,
  initialized: false,
  venueName: null,
  events: Events(),
  plateNumber: null,
}) {
  constructor(props) {
    if (!props || !props.checkout) {
      super();
      return;
    }

    const {
      checkout: checkoutProps,
      currentSearch: currentSearchProps,
      directAffiliateTraffic,
      locations: locationsProps,
      plateNumber,
    } = props;

    const {
      couponCode = '',
      portalAffiliateId,
    } = checkoutProps || {};

    let {
      subTotal = 0,
      checkoutTotal = 0,
      couponAmount = 0,
      userCreditAmount = 0,
    } = checkoutProps || {};

    if (currentSearchProps && locationsProps) {
      const currentSearch = new Search(currentSearchProps);
      const locations = Locations(locationsProps);
      const location = currentSearch.getSelectedLocation(locations);

      if (location) {
        const currency = location.currency;

        checkoutTotal = parseFloat(get(checkoutTotal, currency));
        couponAmount = parseFloat(get(couponAmount, currency));
        subTotal = parseFloat(get(subTotal, currency));
        userCreditAmount = parseFloat(get(userCreditAmount, currency));
      }
    }

    const bookExtendedTimes = !directAffiliateTraffic;

    super({
      subTotal,
      checkoutTotal,
      couponAmount,
      userCreditAmount,
      couponCode,
      portalAffiliateId,
      bookExtendedTimes,
      plateNumber,
    });
  }
}

export default function checkout(state = new CheckoutState(), action = null) {
  switch (action.type) {
    case GET_PREVIEW: {
      const { couponCode = state.couponCode, autoApplyCoupon = state.autoApplyCoupon } = action.payload;
      return state.merge({
        couponCode,
        autoApplyCoupon,
      });
    }
    case GOT_PRICING: {
      const { pricing } = action.payload;
      return state.merge({
        pricing,
      });
    }
    case GOT_PREVIEW: {
      const {
        checkoutTotal,
        couponAmount,
        couponCode,
        events,
        subTotal,
        venueName,
        userCreditAmount,
      } = action.payload;
      let error = Map(action.payload.error);
      if (state.error.get('message') === PARKING_UNAVAILABLE_MESSAGE) {
        ({ error } = state);
      }

      return state.merge({
        couponCode,
        couponAmount,
        userCreditAmount,
        checkoutTotal,
        subTotal,
        venueName,
        events,
        error,
      });
    }
    case CLEANUP_CHECKOUT_STATE: {
      return state.merge({
        selectedAddOns: new AddOns(),
        isPaypal: false,
        saveCreditCard: true,
        deviceData: null,
        submitted: false,
        initialized: false,
        subTotal: null,
        checkoutTotal: null,
        couponAmount: null,
        pricing: new Pricing(),
        userCreditAmount: null,
        error: Map(),
      });
    }
    case CHANGE_ADD_ONS: {
      const { addOnType, addOn, addOnAction } = action.payload;
      let { selectedAddOns, requiredFieldErrors } = state;

      switch (addOnAction) {
        case 'add':
          selectedAddOns = selectedAddOns.set(addOnType, selectedAddOns.get(addOnType).push(addOn));
          break;
        case 'remove':
          selectedAddOns = selectedAddOns.set(addOnType, selectedAddOns.get(addOnType).filter(a => a.id !== addOn.id));
          break;
        case 'replace':
          selectedAddOns = selectedAddOns.set(addOnType, selectedAddOns.get(addOnType).shift().push(addOn));
          break;
        default:
          break;
      }

      if ((addOnAction === 'replace' || addOnAction === 'add') && requiredFieldErrors.get(addOnType)) {
        requiredFieldErrors = requiredFieldErrors.delete(addOnType);
      }

      return state.merge({
        selectedAddOns,
        requiredFieldErrors,
      });
    }
    case CHANGE_MONTHLY_START: {
      const { monthlyStart } = action.payload;

      return state.merge({
        monthlyStart,
      });
    }
    case TOGGLE_BOOK_EXTENDED_TIMES: {
      const bookExtendedTimes = !state.bookExtendedTimes;

      return state.merge({
        bookExtendedTimes,
      });
    }
    case DISABLE_BOOK_EXTENDED_TIMES: {
      return state.merge({
        bookExtendedTimes: false,
      });
    }
    case SET_DEVICE_DATA: {
      const { deviceData } = action.payload;
      return state.merge({
        deviceData,
      });
    }
    case UPDATE_SEARCH: {
      const { bookExtendedTimes } = action.payload;
      return state.merge({
        bookExtendedTimes,
      });
    }
    case CHECKOUT_MODIFY_REQUIRED_FIELD_LIST: {
      const { addedFields, removedFields } = action.payload;
      let requiredFields = state.requiredFields;
      let requiredFieldErrors = state.requiredFieldErrors;
      addedFields.forEach(field => (requiredFields = requiredFields.add(field)));
      removedFields.forEach(field => (requiredFields = requiredFields.remove(field)));
      removedFields.forEach(field => (requiredFieldErrors = requiredFieldErrors.remove(field)));

      return state.merge({
        requiredFields,
        requiredFieldErrors,
      });
    }
    case CHECKOUT_SET_SHOULD_SCROLL_TO_ERROR:
      return state.merge({
        shouldScrollToError: action.payload,
      });
    case CHANGE_PAYMENT_METHOD: {
      const { isPaypal } = action.payload;
      return state.merge({
        isPaypal,
      });
    }
    case TOGGLE_SAVE_PAYMENT:
      return state.merge({
        saveCreditCard: !state.saveCreditCard,
      });
    case INITIALIZE_CHECKOUT:
      return state.merge({
        initialized: true,
      });
    case SUBMIT_CHECKOUT:
      return state.merge({
        submitted: true,
      });
    case UNSET_FORM_SUBMITTED:
      return state.merge({
        submitted: false,
      });
    case SET_ERROR: {
      const { error } = action.payload;
      return state.merge({
        error: Map(error),
      });
    }
    // set null or empty object to clear apiError
    case SET_API_ERROR: {
      return state.merge({
        apiError: Map(action.payload),
      });
    }
    case CLEAR_ERROR:
      return state.merge({
        error: Map(),
      });
    case SET_REQUIRED_FIELD_ERRORS: {
      const { requiredFields } = state;
      const { requiredFieldErrors } = action.payload;

      let errors = Map();
      requiredFields.forEach((f) => { errors = errors.set(f, requiredFieldErrors.get(f)); });

      return state.merge({
        requiredFieldErrors: errors,
      });
    }
    case CHANGE_USER_INFO: {
      let { requiredFieldErrors } = state;

      Object.keys(action.payload).forEach((field) => {
        const fieldError = requiredFieldErrors.get(field);
        if (fieldError && !fieldError.isValid) {
          if (trim(action.payload[field])) {
            requiredFieldErrors = requiredFieldErrors.delete(field);
          } else {
            fieldError.isEmpty = true;
            requiredFieldErrors = requiredFieldErrors.set(field, fieldError);
          }
        }
      });

      return state.merge({ requiredFieldErrors });
    }
    case GOT_VENUE: {
      const { venue } = action.payload;
      return state.merge({ bookExtendedTimes: !venue.enhancedAirport });
    }
    case ADD_PREPURCHASE_LICENSE_PLATE: {
      const { plateNumber } = action.payload;
      return state.merge({ plateNumber });
    }
    default:
      return state;
  }
}
