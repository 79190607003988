import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment-timezone';
import get from 'lodash/get';

import SearchModel from 'models/search';
import Venue from 'models/venue';
import Brand from 'models/brand';

import SearchTimeRange from 'components/search/time-range';

const propTypes = {
  handleNavbarToggle: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  currentSearch: PropTypes.instanceOf(SearchModel),
  monthlyStart: PropTypes.instanceOf(moment),
  timezone: PropTypes.string,
  showMobileMenu: PropTypes.bool,
  changeModalState: PropTypes.func.isRequired,
};

const defaultProps = {
  currentSearch: null,
  monthlyStart: moment(),
  showMobileMenu: false,
  timezone: moment.tz.guess(),
};

class MobileSearch extends Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
  }
  eventHeader() {
    const { currentSearch } = this.props;
    const { destination } = currentSearch;
    const venueName = get(this, ['props', 'venue', 'name'], '');
    const eventName = destination.get ? destination.get('name') : destination;
    const eventStart = moment(destination.startTime || destination.get('startTime')).tz(this.props.timezone);
    return (
      <div className="mobile-header-location-wrapper">
        <div className="wrap-ellipses line-height-extra text-size-12 text-weight-medium" dangerouslySetInnerHTML={{ __html: venueName }} />
        <div className="wrap-ellipses line-height-extra text-size-12 text-weight-book" dangerouslySetInnerHTML={{ __html: eventName }} />
        <div className="line-height-extra text-size-10 text-weight-book">{eventStart.format('MMM D, h:mm A zz')}</div>
      </div>
    );
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  showModal() {
    this.scrollToTop();
    this.props.changeModalState({
      displayModal: true,
      header: 'searchPickerHeader',
      body: 'mobileDestinationTimePicker',
      footer: 'buttonFooter',
      footerProps: {
        footerButtonText: 'FIND PARKING',
      },
    });
  }

  renderDestinationInfo() {
    let destinationName = typeof (this.props.currentSearch.destination) !== 'string' ? get(this, ['props', 'currentSearch', 'destination', 'name'], '') : this.props.currentSearch.destination;
    if (this.props.currentSearch.isParkingNearMeSearch) { destinationName = 'Current Location'; }

    if (this.props.currentSearch.isEventSearch) {
      return this.eventHeader();
    }
    return (
      <div className="mobile-header-location-wrapper with-top-margin-half">
        <div
          className="wrap-ellipses line-height-extra text-size-12 text-weight-medium"
          dangerouslySetInnerHTML={{ __html: destinationName }}
        />
        <div className="wrap-ellipses line-height-extra text-size-xs-10 text-size-sm-12">
          <SearchTimeRange currentSearch={this.props.currentSearch} monthlyStart={this.props.monthlyStart} />
        </div>
      </div>
    );
  }

  renderMenuToggle() {
    const { brand, handleNavbarToggle, showMobileMenu } = this.props;
    if (brand.showMobileSearchLogo) {
      const mobileSearchChevronClasses = cx({
        'iconified-font': true,
        'iconified-absolute': true,
        'iconified-chevron-down': true,
        'transition-ease': true,
        'clockwise-180-before': this.props.showMobileMenu,
        'text-color-dark-slate': true,
        'text-size-8': true,
      });

      return (
        <div
          className="mobile-menu-toggle-wrapper col-xs-3 col-sm-2 col-gutter-right-xxs-0 col-gutter-right-8 text-center clickable nav-cta"
          onClick={handleNavbarToggle}
        >
          <div className={mobileSearchChevronClasses}>
            <img
              src={brand.mobileLogoURL}
              alt="ParkWhiz"
            />
          </div>
        </div>
      );
    }

    const imageSrc = showMobileMenu ? '/images/hamburger-x.png' : '/images/hamburger-menu.png';

    return (
      <div
        className="mobile-menu-toggle-wrapper col-xs-3 col-sm-2 col-gutter-right-xxs-0 col-gutter-right-8 text-center clickable nav-cta"
        onClick={handleNavbarToggle}
      >
        <img
          src={imageSrc}
          alt="ParkWhiz"
        />
      </div>
    );
  }

  render() {
    if (this.props.app === 'Search') {
      return (
        <div className="visible-lte-sm row">
          {this.renderMenuToggle()}
          <div className="col-xs-9 col-sm-10 text-color-white col-gutter-8 col-gutter-left-0 col-gutter-left-xxs-8 with-top-margin-half clickable" onClick={this.showModal}>
            {this.renderDestinationInfo(this.props.currentSearch)}
            <span className="iconified-space-top-extra iconified-vertical-align-top iconified-font iconified-pencil text-color-white text-size-20" />
          </div>
        </div>
      );
    }
    return null;
  }
}

MobileSearch.propTypes = propTypes;
MobileSearch.defaultProps = defaultProps;

export default MobileSearch;
