import { Record, List, Map } from 'immutable';
import sortBy from 'lodash/sortBy';
import isUndefined from 'lodash/isUndefined';

import { INITIALIZE_HOME_PAGE } from 'action-creators/brand/initialize-home-page';
import { CLEANUP_HOME_PAGE_STATE } from 'action-creators/brand/cleanup-home-page-state';
import { INITIALIZE_BUSINESS_PAGE } from 'action-creators/brand/initialize-business-page';
import { CLEANUP_BUSINESS_PAGE_STATE } from 'action-creators/brand/cleanup-business-page-state';
import { INITIALIZE_HOW_IT_WORKS } from 'action-creators/brand/initialize-how-it-works';
import { CLEANUP_HOW_IT_WORKS_STATE } from 'action-creators/brand/cleanup-how-it-works-state';
import { INITIALIZE_OUR_APPS_PAGE } from 'action-creators/brand/initialize-our-apps-page';
import { CLEANUP_OUR_APPS_PAGE_STATE } from 'action-creators/brand/cleanup-our-apps-page-state';
import { INITIALIZE_OAUTH_PAGE } from 'action-creators/brand/initialize-oauth-page';
import { CLEANUP_OAUTH_PAGE_STATE } from 'action-creators/brand/cleanup-oauth-page-state';
import { DISMISS_ANNOUNCEMENT_BANNER } from 'action-creators/brand/dismiss-announcement-banner';
import { GOT_PRESS_RELEASE } from 'action-creators/app/got-press-release';
import { GOT_BRAND } from 'action-creators/brand/got-brand';
import { REVERT_BRAND } from 'action-creators/brand/revert-brand';
import { CLEANUP_PARKING_PASSES_STATE } from 'action-creators/parking-pass/cleanup-parking-passes-state';
import { CLEANUP_CANCEL_BOOKING_STATE } from 'action-creators/bookings/cleanup-cancel-booking-state';
import { DISMISS_PARTNER_MODAL } from 'action-creators/brand/dismiss-partner-modal';

import Brand, { DIFF_BLACKLIST } from 'models/brand';
import { PressRelease } from 'models/press-release';

import { countryByLocale } from 'lib/locale';

export class BrandState extends Record({
  execBiographies: List(),
  homePageInitialized: false,
  businessPageInitialized: false,
  howItWorksInitialized: false,
  ourAppsPageInitialized: false,
  oAuthPageInitialized: false,
  brand: new Brand(),
  brandDiff: Map(),
  announcementBannerDismissed: false,
  pressArticles: List(),
  pressReleases: List(),
  pressPageInitialized: false,
  pressRelease: new PressRelease(),
  partnerModalDismissed: false,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const {
      announcementBannerDismissed = false,
      partnerModalDismissed = false,
      locale,
    } = props || {};

    let execBiographies = List();
    if (props.execBiographies) {
      execBiographies = List(sortBy(props.execBiographies, ['order']).map(b => Map(b)));
    }

    let pressArticles = List();
    let pressReleases = List();
    if (props.pressArticles) {
      pressArticles = List(sortBy(props.pressArticles, ['date_posted'])).reverse();
    }
    if (props.pressReleases) {
      pressReleases = List(sortBy(props.pressReleases, ['date_posted'])).reverse();
    }

    const country = countryByLocale(locale);
    const { brand, brandDiff } = Brand.cascadedBrand(props, { country });

    super({
      execBiographies,
      brand,
      brandDiff,
      announcementBannerDismissed,
      pressArticles,
      pressReleases,
      partnerModalDismissed,
    });
  }
}

export default function brandReducer(state = new BrandState(), action = null) {
  switch (action.type) {
    case INITIALIZE_HOME_PAGE:
      return state.merge({
        homePageInitialized: true,
      });
    case CLEANUP_HOME_PAGE_STATE:
      return state.merge({
        homePageInitialized: false,
      });
    case INITIALIZE_BUSINESS_PAGE:
      return state.merge({
        businessPageInitialized: true,
      });
    case CLEANUP_BUSINESS_PAGE_STATE:
      return state.merge({
        businessPageInitialized: false,
      });
    case INITIALIZE_HOW_IT_WORKS:
      return state.merge({
        howItWorksInitialized: true,
      });
    case CLEANUP_HOW_IT_WORKS_STATE:
      return state.merge({
        howItWorksInitialized: false,
      });
    case INITIALIZE_OUR_APPS_PAGE:
      return state.merge({
        ourAppsPageInitialized: true,
      });
    case CLEANUP_OUR_APPS_PAGE_STATE:
      return state.merge({
        ourAppsPageInitialized: false,
      });
    case INITIALIZE_OAUTH_PAGE:
      return state.merge({
        oAuthPageInitialized: true,
      });
    case CLEANUP_OAUTH_PAGE_STATE:
      return state.merge({
        oAuthPageInitialized: false,
      });
    case DISMISS_ANNOUNCEMENT_BANNER:
      return state.merge({
        announcementBannerDismissed: true,
      });
    case GOT_PRESS_RELEASE: {
      const { release } = action.payload;
      return state.merge({
        pressRelease: new PressRelease(release),
      });
    }
    case GOT_BRAND: {
      let { brand } = action.payload;
      brand = brand.toMap().filter((v, k) => !isUndefined(v) && !DIFF_BLACKLIST.includes(k));
      const brandDiff = state.brand.merge(state.brandDiff).diff(brand);
      brand = state.brand.merge(brand);
      return state.merge({ brand, brandDiff });
    }
    case CLEANUP_CANCEL_BOOKING_STATE:
    case CLEANUP_PARKING_PASSES_STATE:
    case REVERT_BRAND: {
      const { brand, brandDiff } = state;
      return state.merge({
        brand: brand.merge(brandDiff),
        brandDiff: Map(),
      });
    }
    case DISMISS_PARTNER_MODAL:
      return state.merge({
        partnerModalDismissed: true,
      });
    default:
      return state;
  }
}
