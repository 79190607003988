import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { AppConsumer } from 'providers/app-provider';

import MapboxAutocomplete from 'containers/mapbox/autocomplete';
import LocaleSelection from 'containers/common/locale-selection';

import MapboxGeolocation from 'components/mapbox/geolocation';
import BrandName from 'containers/common/brand-name';

import Coordinates from 'models/coordinates';
import UserModel from 'models/user';

import RequestQueue from 'lib/api/request-queue';
import env from 'lib/env';
import { buildRedirectURI } from 'lib/common/url-helpers';

const { PORTAL_URL, ADMIN_HOST } = env();

import Brand from 'models/brand';

const propTypes = {
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  requestQueue: PropTypes.instanceOf(RequestQueue).isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  appContext: PropTypes.string.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  mounted: PropTypes.bool,
  routerLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

const defaultProps = {
  mounted: false,
};

class MobileMenu extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.user.isLoggedIn() !== this.props.user.isLoggedIn()) { return true; }
    return false;
  }

  renderAdminLink() {
    return (
      <a href={ADMIN_HOST} className="snap-drawer-item deco-valet-white">
        <FormattedMessage id="common.admin" defaultMessage="PARKWHIZ ADMIN" />
      </a>
    );
  }

  renderAffiliateLink() {
    return (
      <a href={PORTAL_URL} className="snap-drawer-item deco-valet-white">
        <FormattedMessage id="common.affiliate-console" defaultMessage="AFFILIATE CONSOLE" />
      </a>
    );
  }

  renderSellerLink() {
    return (
      <a href={ADMIN_HOST} className="snap-drawer-item deco-valet-white">
        <FormattedMessage id="common.seller-console" defaultMessage="SELLER CONSOLE" />
      </a>
    );
  }

  renderValidationLink() {
    return (
      <a href="/validation/" className="snap-drawer-item deco-valet-white">
        <FormattedMessage id="common.validation-console" defaultMessage="PARKING VALIDATION" />
      </a>
    );
  }

  renderAccountMenu() {
    if (!this.props.user.isLoggedIn()) { return this.renderLogIn(); }
    return (
      <div>
        <a className="snap-drawer-item deco-cog-white" href="/account/settings/">
          <FormattedMessage id="common.account-settings" defaultMessage="ACCOUNT SETTINGS" />
        </a>
        <a className="snap-drawer-item deco-car-white" href="/account/vehicles/">
          <FormattedMessage id="common.saved-vehicles" defaultMessage="SAVED VEHICLES" />
        </a>
        <a className="snap-drawer-item deco-credit-card-white" href="/account/billing/">
          <FormattedMessage id="common.payment-methods" defaultMessage="PAYMENT METHODS" />
        </a>
        { this.props.user.affiliate ? this.renderAffiliateLink() : null }
        { this.props.user.seller ? this.renderSellerLink() : null }
        { this.props.user.validated ? this.renderValidationLink() : null }
        { this.props.user.admin ? this.renderAdminLink() : null }
        <a className="snap-drawer-item deco-sign-out-white" href="/signout/">
          <FormattedMessage id="common.sign-out" defaultMessage="SIGN OUT" />
        </a>
      </div>
    );
  }

  renderLogIn() {
    let redirectURI;
    if (this.props.mounted) {
      redirectURI = buildRedirectURI(this.props.routerLocation);
    }
    return (
      <a className="snap-drawer-item deco-sign-out-white" href={`/account/signin/${redirectURI}`}>
        <FormattedMessage id="common.sign-in" defaultMessage="SIGN IN" />
      </a>
    );
  }

  renderParkingHistory() {
    if (!this.props.user.isLoggedIn()) { return null; }
    return (
      <a href="/account/" className="snap-drawer-item deco-ticket-white">
        <FormattedMessage id="common.my-bookings" defaultMessage="MY PARKING" />
      </a>
    );
  }

  render() {
    const { geoIPLocation } = this.props;

    return (
      <div className="snap-drawers hidden-print">
        <div className="snap-drawer snap-drawer-left">
          <div className="snap-drawer-search destination-form">
            <fieldset>
              <div className="input-group mobile-search-input form-group mbs">
                <MapboxAutocomplete
                  buttonClassName="btn"
                  dropdownClassName="location-suggestion-bar-mobile"
                  geoIPLocation={geoIPLocation}
                  inputClassName="form-control input-sm gplaces-input-dropdown"
                  requestQueue={this.props.requestQueue}
                  trackEvent={this.props.trackEvent}
                  hub={this.props.hub}
                  venue={this.props.venue}
                  currentSearch={this.props.currentSearch}
                  app={this.props.app}
                  appContext={this.props.appContext}
                  showMobileMenu={this.props.showMobileMenu}
                  id="menu-autocomplete"
                  placeholderId="common.search"
                />
                <span className="input-icon valet-glyph-cross" />
              </div>
              <div className="form-group visible-xs text-center">
                <MapboxGeolocation
                  buttonClassName="btn btn-clean text-color-white progress-btn-background-green find-parking-btn"
                  geoIPLocation={geoIPLocation}
                />
              </div>
            </fieldset>
          </div>
          <div className="snap-drawer-group no-underline">
            <a href="/" className="snap-drawer-item deco-home-white" title="Home">
              <FormattedMessage id="common.home" defaultMessage="HOME" />
            </a>
            <a href="/how-it-works/" className="snap-drawer-item deco-info-circle-white" title="How ParkWhiz Works">
              <FormattedMessage
                id="common.pw-work"
                defaultMessage="{brand} WORKS"
                values={{ brand: <BrandName className="uppercase" /> }}
              />
            </a>
            { this.renderParkingHistory() }
            { this.renderAccountMenu() }
            <a href={this.props.brand.helpWebsiteURL} className="snap-drawer-item deco-help-white" title="Help">
              <FormattedMessage id="common.help" defaultMessage="HELP" />
            </a>
            <div className="margin-left-15">
              <LocaleSelection
                showMobileMenu={this.props.showMobileMenu}
                mobileMenu
              />
            </div>
          </div>
        </div>
        <div className="snap-drawer snap-drawer-right" />
      </div>
    );
  }
}

MobileMenu.propTypes = propTypes;
MobileMenu.defaultProps = defaultProps;

const MobileMenuWrapper = props => (
  <AppConsumer><MobileMenu {...props} /></AppConsumer>
);

export default MobileMenuWrapper;
