import { decamelizeKeys } from 'humps';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { generateQueryString, get, post, put, del, SLUG_FIELDS } from 'lib/api/base';
import env from 'lib/env';
import { cdnAsset } from 'lib/common/url-helpers';
import * as RoutingStyle from 'lib/routing-style';

const ClientApi = {
  // Properties
  NODE_BASE_V3_1_API_URL: env().NODE_BASE_V3_1_API_URL,
  NODE_BASE_V4_API_URL: env().NODE_BASE_V4_API_URL,
  NODE_BASE_BP_URL: env().NODE_BASE_BP_URL,
  NODE_BASE_INTERNAL_API_URL: env().NODE_BASE_INTERNAL_API_URL,
  ENTERPRISE_HOST: env().ENTERPRISE_HOST,
  NODE_BASE_ENTERPRISE_API_URL: env().NODE_BASE_ENTERPRISE_API_URL,
  BASE_LOCALE_API_URL: '/static',

  // Functions
  acceptEnterpriseInvite(authToken, userId, requestQueue = null) {
    const url = `${this.ENTERPRISE_HOST}/api/v1/invites/${authToken}/accept`;
    const [request, promise] = post({ url, data: { user_id: userId }, bypassLocaleHeader: true });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'enterprise-invite'); }
    return promise;
  },
  createUser(data, requestQueue = null, accessToken = null) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/accounts/?fields=customer::default&zoom=pw:session,customer:feedback_reminder`;
    [data] = [data].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = post({ url, data, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'user'); }
    return promise;
  },
  updateUser(data, accessToken, requestQueue = null) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/accounts/me`;
    const [request, promise] = put({ url, data, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'user'); }
    return promise;
  },
  resetPassword(data, accessToken, requestQueue = null) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/accounts/recoveries`;
    const [request, promise] = post({ url, data, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'reset_password'); }
    return promise;
  },
  recoverPassword(data, accessToken, requestQueue = null) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/accounts/reset_password`;
    [data] = [data].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = post({ url, data, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'recover_password'); }
    return promise;
  },
  venue(slug, requestQueue = null, { routingStyle = RoutingStyle.PARKWHIZ, accessToken = null } = {}) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/venues/?q=slug:${slug}&zoom=pw:search_suggestions&fields=venue::default,venue:description,venue:coordinates,venue:msa,venue:primarily_transient,venue:seo_meta,venue:timezone,venue:availability,venue:images,venue:venue_type,venue:site_url,venue:country&routing_style=${routingStyle}`;
    const [request, promise] = get({ url, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'venue'); }
    return promise;
  },
  venueEvents(venueId, { page = 1, requestQueue = null, routingStyle = RoutingStyle.PARKWHIZ, accessToken = null } = {}) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/venues/${venueId}/events?page=${page}&per_page=100&fields=event::default,event:site_url,event:availability&sort=start_time&routing_style=${routingStyle}`;
    const [request, promise] = get({ url, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'events'); }
    return promise;
  },
  quote(quoteId, requestQueue = null, { routingStyle = RoutingStyle.PARKWHIZ } = {}) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/quotes/${quoteId}/?zoom=:default,pw:seller&fields=venue::default,venue:enhanced_airport&routing_style=${routingStyle}`;
    const [request, promise] = get({ url });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'quote'); }
    return promise;
  },
  search(q, searchParams, requestQueue = null, { routingStyle = RoutingStyle.PARKWHIZ, accessToken = null } = {}) {
    const url = `${this.NODE_BASE_V4_API_URL}/quotes/`;
    [q, searchParams] = [q, searchParams].map(v => decamelizeKeys(omitBy(v, isNil)));
    q = generateQueryString(q);
    if (q) { searchParams.q = q; }
    searchParams.routing_style = routingStyle;
    searchParams.capabilities = 'capture_plate:always';

    const [request, promise] = get({
      url,
      accessToken,
      data: searchParams,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'search', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  streetParking(q, searchParams, requestQueue = null, { routingStyle = RoutingStyle.PARKWHIZ, accessToken = null } = {}) {
    const url = `${this.NODE_BASE_V4_API_URL}/quotes/`;
    [q, searchParams] = [q, searchParams].map(v => decamelizeKeys(omitBy(v, isNil)));
    q = generateQueryString(q);
    if (q) { searchParams.q = q; }
    searchParams.returns = 'onstreet';
    searchParams.routing_style = routingStyle;
    searchParams.capabilities = 'capture_plate:always';

    const [request, promise] = get({ url, accessToken, data: searchParams });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'streetParking'); }
    return promise;
  },
  seller(sellerId, requestQueue = null, accessToken = null) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/sellers/${sellerId}?fields=seller:id,seller:name,seller:logo`;
    const [request, promise] = get({ url, accessToken });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'seller'); }
    return promise;
  },
  reviews(locationId, requestQueue = null) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/locations/${locationId}/reviews/?q=comment_not_empty:true min_rating:4&per_page=10&page=1`;
    const [request, promise] = get({ url });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'reviews'); }
    return promise;
  },
  /*
   * TODO: Refactor ClientApi to be more similar to how the ParkWhiz API is.
   * As seen below, when we have the requests that hit the same endpoint, but do different things
   * it can get messy and confusing for developers who don't know exactly which one to use.
   */
  getBookings({ bookingId, q, queryParams, monthly } = {}, accessToken, requestType = 'bg', requestQueue = null) {
    const resource = monthly ? 'monthly_bookings' : 'bookings';
    let url = `${this.NODE_BASE_V4_API_URL}/${resource}/`;
    url = bookingId ? `${url}${bookingId}/` : url;
    [q, queryParams] = [q, queryParams].map(v => decamelizeKeys(omitBy(v, isNil)));
    if (q && Object.keys(q).length) {
      queryParams.q = generateQueryString(q);
    }

    const [request, promise] = get({ url, data: queryParams, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'bookings'); }

    return promise;
  },
  bookings(q, searchParams, accessToken, requestType = 'fg', requestQueue = null, { routingStyle = RoutingStyle.PARKWHIZ } = {}) {
    const url = `${this.NODE_BASE_V4_API_URL}/bookings/`;
    [q, searchParams] = [q, searchParams].map(v => decamelizeKeys(omitBy(v, isNil)));
    q = generateQueryString(q);
    if (q) { searchParams.q = q; }
    searchParams.routing_style = routingStyle;
    const [request, promise] = get({
      url,
      accessToken,
      data: searchParams,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'bookings'); }
    return promise;
  },
  monthly_bookings(q, searchParams, accessToken, requestType = 'fg', requestQueue = null, { routingStyle = RoutingStyle.PARKWHIZ } = {}) {
    const url = `${this.NODE_BASE_V4_API_URL}/monthly_bookings/`;
    [q, searchParams] = [q, searchParams].map(v => decamelizeKeys(omitBy(v, isNil)));
    q = generateQueryString(q);
    if (q) { searchParams.q = q; }
    searchParams.routing_style = routingStyle;
    const [request, promise] = get({
      url,
      accessToken,
      data: searchParams,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'bookings'); }
    return promise;
  },
  frequent_locations(accessToken, requestType = 'fg', requestQueue = null, { routingStyle = RoutingStyle.PARKWHIZ } = {}) {
    const url = `${this.NODE_BASE_V4_API_URL}/accounts/me/frequent_locations/?fields=location::default,location:url,location:photos,location:timezone,booking:start_time,venue:id,venue:enhanced_airport&zoom=pw:most_recent_booking,pw:venue&routing_style=${routingStyle}`;
    const [request, promise] = get({
      url,
      accessToken,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'frequent_locations'); }
    return promise;
  },
  addVehicleToBooking(bookingId, params, accessToken, requestType = 'fg', requestQueue = null) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/bookings/${bookingId}/vehicles/`;
    [params] = [params].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = put({
      url,
      accessToken,
      data: params,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'set_vehicle_to_booking'); }
    return promise;
  },
  openGate(bookingId, params, accessToken, requestType = 'fg', requestQueue = null) {
    const url = `${this.NODE_BASE_INTERNAL_API_URL}/bookings/${bookingId}/gate_openings/`;
    const [queryParams] = [params].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = post({
      url,
      accessToken,
      data: queryParams,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'open_gate'); }
    return promise;
  },
  postAppDownloadText({ params, accessToken, requestType = 'bg', requestQueue = null }) {
    const url = `${this.NODE_BASE_INTERNAL_API_URL}/text_messages/app_link`;

    const [queryParams] = [params].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = post({
      url,
      accessToken,
      data: queryParams,
    });
    // TODO is this needed?
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'platform_inquiry'); }
    return promise;
  },
  searchEvents(query, searchText, accessToken, requestType = 'bg', requestQueue = null) {
    const url = `${this.NODE_BASE_INTERNAL_API_URL}/autocomplete/`;
    const q = generateQueryString(query);
    const [request, promise] = get({
      url,
      accessToken,
      data: {
        search_text: searchText,
        q,
      },
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'event_search'); }
    return promise;
  },
  resolveSlug(slug, accessToken, requestType = 'bg', requestQueue = null, routingStyle = RoutingStyle.PARKWHIZ) {
    const url = `${this.NODE_BASE_INTERNAL_API_URL}/slugs/?slug=${slug}&fields=${SLUG_FIELDS}&routing_style=${routingStyle}`;
    const [request, promise] = get({ url, accessToken });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'resolve_slug'); }
    return promise;
  },
  hubs({ q, queryParams, requestQueue = null, requestType = 'bg', accessToken }) {
    const url = `${this.NODE_BASE_V3_1_API_URL}/hubs/`;
    const qParam = generateQueryString(q);
    if (qParam) {
      queryParams.q = qParam;
    }
    const [request, promise] = get({ url, data: queryParams, accessToken });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'nearby_metros'); }
    return promise;
  },
  businessEnroll({ data, requestType = 'bg', requestQueue = null }) {
    const url = `${this.NODE_BASE_ENTERPRISE_API_URL}/enterprise_accounts/`;
    [data] = [data].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = post({ url, data, bypassLocaleHeader: true });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'business_enrollment'); }
    return promise;
  },
  cancelBooking({ bookingId, data, requestType = 'fg', requestQueue = null, accessToken }) {
    const url = `${this.NODE_BASE_V4_API_URL}/bookings/${bookingId}/`;
    [data] = [data].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = del({ url, data, accessToken });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'cancel_booking'); }
    return promise;
  },
  postReview({ locationId, data, requestQueue, accessToken, requestType = 'bg' }) {
    const url = `${this.NODE_BASE_V4_API_URL}/locations/${locationId}/reviews/`;
    [data] = [data].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = post({ url, data, accessToken });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'create_review'); }
    return promise;
  },
  postContactInquiry(params, accessToken, requestType = 'bg', requestQueue = null) {
    const url = `${this.NODE_BASE_INTERNAL_API_URL}/contact/press/`;
    const [queryParams] = [params].map(v => decamelizeKeys(omitBy(v, isNil)));
    const [request, promise] = post({
      url,
      accessToken,
      data: queryParams,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'contact_inquiry'); }
    return promise;
  },
  getTranslations({ locale, app, requestQueue }) {
    const path = `/locales/${locale}/${app}.json`;
    const url = env().ASSET_CDN_HOST ? cdnAsset(path) : `${this.BASE_LOCALE_API_URL}${path}`;

    const [request, promise] = get({ url, bypassLocaleHeader: true });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'translation'); }
    return promise;
  },
};

export default ClientApi;
