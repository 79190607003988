import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import cookie from 'react-cookie';

import { SAVE_PAYMENT_METHOD } from 'action-creators/payment-methods/save-payment-method';
import { SAVE_BUSINESS_PROFILE } from 'action-creators/payment-methods/save-business-profile';
import { DELETE_PAYMENT_METHOD } from 'action-creators/payment-methods/delete-payment-method';
import { SELECT_PAYMENT_METHOD } from 'action-creators/payment-methods/select-payment-method';
import { SET_DEFAULT_PAYMENT_METHOD } from 'action-creators/payment-methods/set-default-payment-method';
import initializeNewPaymentMethod, { INITIALIZE_NEW_PAYMENT_METHOD } from 'action-creators/payment-methods/initialize-new-payment-method';
import { INITIALIZE_NEW_BUSINESS_PROFILE } from 'action-creators/payment-methods/initialize-new-business-profile';
import { INITIALIZE_BUSINESS_PROFILE_CONNECT } from 'action-creators/payment-methods/initialize-business-profile-connect';
import { INITIALIZE_CHECKOUT } from 'action-creators/checkout/initialize-checkout';
import getPaymentTokenCreator, { GET_PAYMENT_TOKEN } from 'action-creators/payment-methods/get-payment-token';
import { GET_PAYMENT_METHODS } from 'action-creators/payment-methods/get-payment-methods';
import { UNLINK_CONCUR } from 'action-creators/payment-methods/unlink-concur';
import { LINK_EXPENSIFY } from 'action-creators/payment-methods/link-expensify';
import { UNLINK_EXPENSIFY } from 'action-creators/payment-methods/unlink-expensify';
import gotPaymentMethods from 'action-creators/payment-methods/got-payment-methods';
import gotPaymentToken from 'action-creators/payment-methods/got-payment-token';
import gotSession from 'action-creators/payment-methods/got-session';
import { GOT_SESSION } from 'action-creators/account/got-session';
import savedPaymentMethod from 'action-creators/payment-methods/saved-payment-method';
import savedBusinessProfile from 'action-creators/payment-methods/saved-business-profile';
import createPaymentMethodSuccess from 'action-creators/payment-methods/create-payment-method-success';
import createPaymentMethodError from 'action-creators/payment-methods/create-payment-method-error';
import unlinkConcurSuccess from 'action-creators/payment-methods/unlink-concur-success';
import unlinkConcurError from 'action-creators/payment-methods/unlink-concur-error';
import linkExpensifySuccess from 'action-creators/payment-methods/link-expensify-success';
import linkExpensifyError from 'action-creators/payment-methods/link-expensify-error';
import unlinkExpensifySuccess from 'action-creators/payment-methods/unlink-expensify-success';
import unlinkExpensifyError from 'action-creators/payment-methods/unlink-expensify-error';
import businessProfileExistsError from 'action-creators/payment-methods/business-profile-exists-error';
import unsetSubmit from 'action-creators/payment-methods/unset-submit';
import setShouldScrollToError from 'action-creators/payment-methods/set-should-scroll-to-error';
import setRequiredFieldErrors from 'action-creators/payment-methods/set-required-field-errors';

import { hasBusinessProfile } from 'models/payment-method';

import AccountApi from 'lib/api/checkout/account';
import BraintreeTokenApi from 'lib/api/checkout/braintree';
import UserApi from 'lib/api/user';

export const getUser = state => state.account.user;
export const getRequiredFieldErrors = state => state.paymentMethods.requiredFieldErrors;
export const getRequestQueue = state => state.requests.requestQueue;
export const selectPaymentMethods = state => state.paymentMethods.paymentMethods;

export function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

export function fetchPaymentMethods({ id, token, requestQueue }) {
  return AccountApi.getPaymentMethods({ id, accessToken: token, zoom: ['pw:business_profiles'] }, requestQueue)
    .then(({ body }) => ({ paymentMethods: body }))
    .catch(error => ({ error }));
}

export function updateDefaultPaymentMethod({ defaultPaymentMethodId, token, requestQueue }) {
  return AccountApi.updatePaymentMethod(defaultPaymentMethodId, token, { is_default: true }, requestQueue)
    .then(({ body }) => ({ paymentMethod: body }))
    .catch(error => ({ error }));
}

export function* setDefaultPaymentMethod(action) {
  const { defaultPaymentMethodId } = action.payload;
  const { token } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(updateDefaultPaymentMethod, { defaultPaymentMethodId, token, requestQueue });
  if (error) {
    yield put({ type: 'PAYMENT_METHODS_ERROR' });
  }
}

export function removePaymentMethod({ paymentMethodId, token, requestQueue }) {
  return AccountApi.deletePaymentMethod(paymentMethodId, token, requestQueue)
    .catch(error => ({ error }));
}

export function* deletePaymentMethod(action) {
  const { paymentMethodId } = action.payload;
  const { token } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(removePaymentMethod, { paymentMethodId, token, requestQueue });
  if (error) {
    yield put({ type: 'PAYMENT_METHODS_ERROR' });
  }
}

export function fetchPaymentToken({ token, requestQueue }) {
  return BraintreeTokenApi.get(token, requestQueue)
    .then(({ body }) => ({ paymentToken: body.token }))
    .catch(error => ({ error }));
}

export function* getPaymentToken() {
  const { token } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { paymentToken, error } = yield call(fetchPaymentToken, { token, requestQueue });

  if (error) {
    yield put({ type: 'PAYMENT_METHODS_ERROR' });
  } else {
    yield put(gotPaymentToken(paymentToken));
  }
}

export function* getPaymentMethods() {
  const { id, token } = yield select(getUser);
  yield put(getPaymentTokenCreator());
  if (id) {
    const requestQueue = yield select(getRequestQueue);
    const { paymentMethods, error } = yield call(fetchPaymentMethods, { id, token, requestQueue });

    if (error) {
      yield put({ type: 'PAYMENT_METHODS_ERROR' });
    } else {
      yield put(gotPaymentMethods(paymentMethods));
    }
  }
}

export function createPaymentMethod({ nonce, label, token, requestQueue }) {
  return AccountApi.createPaymentMethod(token, { payment_method_nonce: nonce, label }, requestQueue)
    .then(({ body }) => ({ paymentMethod: body }))
    .catch(error => ({ error }));
}

export function updatePaymentMethod({ id, nonce, label, token, requestQueue }) {
  let params = { label };
  if (nonce) {
    Object.assign(params, { payment_method_nonce: nonce });
  }

  return AccountApi.updatePaymentMethod(id, token, params, requestQueue)
    .then(({ body }) => ({ paymentMethod: body }))
    .catch(error => ({ error }));
}

export function* savePaymentMethod(action) {
  let requiredFieldErrors;

  if (action.payload.nonce || !action.payload.isUpdate) {
    yield put(setRequiredFieldErrors({ getRequiredFieldErrors }));
    requiredFieldErrors = yield select(getRequiredFieldErrors);
  }

  if (requiredFieldErrors && requiredFieldErrors.size) {
    yield put(setShouldScrollToError(true));
  } else {
    const { id, nonce, label, isUpdate } = action.payload;
    const { token } = yield select(getUser);
    const requestQueue = yield select(getRequestQueue);

    let paymentMethod;
    let error;
    if (isUpdate) {
      ({ paymentMethod, error } = yield call(updatePaymentMethod, { id, nonce, label, token, requestQueue }));
    } else {
      ({ paymentMethod, error } = yield call(createPaymentMethod, { nonce, label, token, requestQueue }));
    }

    if (error) {
      yield put(createPaymentMethodError());
      yield call(scrollToTop);
    } else {
      yield put(savedPaymentMethod(paymentMethod));
      yield put(createPaymentMethodSuccess());
      yield put(push('/account/payment-methods/'));
    }
  }
  yield put(unsetSubmit());
}

export function createBusinessProfile({ nonce, email, token, requestQueue }) {
  return AccountApi.createBusinessProfile(token, { payment_method_nonce: nonce, email }, requestQueue)
    .then(({ body }) => ({ businessProfile: body }))
    .catch(error => ({ error }));
}

export function updateBusinessProfile({ id, nonce, email, token, requestQueue }) {
  let params = { email };
  if (nonce) {
    Object.assign(params, { payment_method_nonce: nonce });
  }

  return AccountApi.updateBusinessProfile(id, token, params, requestQueue)
    .then(({ body }) => ({ paymentMethod: body }))
    .catch(error => ({ error }));
}

export function* saveBusinessProfile(action) {
  let requiredFieldErrors;

  if (action.payload.nonce || !action.payload.isUpdate) {
    yield put(setRequiredFieldErrors({ requiredFieldErrors }));
    requiredFieldErrors = yield select(getRequiredFieldErrors);
  }

  if (requiredFieldErrors && requiredFieldErrors.size) {
    yield put(setShouldScrollToError(true));
  } else {
    const { id, nonce, email, isUpdate } = action.payload;
    const { token } = yield select(getUser);
    const requestQueue = yield select(getRequestQueue);

    let businessProfile;
    let paymentMethod;
    let error;
    if (isUpdate) {
      ({ paymentMethod, error } = yield call(updateBusinessProfile, { id, nonce, email, token, requestQueue }));
    } else {
      ({ businessProfile, error } = yield call(createBusinessProfile, { nonce, email, token, requestQueue }));
    }

    if (error) {
      yield put(createPaymentMethodError());
      yield call(scrollToTop);
    } else if (isUpdate) {
      yield put(savedPaymentMethod(paymentMethod));
      yield put(push(`/account/payment-methods/business-profiles/${paymentMethod.id}/connect/`));
    } else {
      yield put(savedBusinessProfile(businessProfile));
      yield put(push('/account/payment-methods/business-profiles/new/connect/'));
    }
  }
  yield put(unsetSubmit());
}

export function* initializeNewBusinessProfile() {
  yield call(getPaymentMethods);
  const paymentMethods = yield select(selectPaymentMethods);

  if (yield call(hasBusinessProfile, paymentMethods)) {
    yield put(businessProfileExistsError());
    yield put(push('/account/payment-methods/'));
  } else {
    yield put(initializeNewPaymentMethod());
  }
}

export function deleteConcurToken({ id, token, requestQueue }) {
  return AccountApi.deleteConcurToken(id, token, requestQueue)
    .then(() => ({}))
    .catch(error => ({ error }));
}

export function createExpensifyToken({ id, token, requestQueue }) {
  return AccountApi.createExpensifyToken(id, token, requestQueue)
    .then(() => ({}))
    .catch(error => ({ error }));
}

export function deleteExpensifyToken({ id, token, requestQueue }) {
  return AccountApi.deleteExpensifyToken(id, token, requestQueue)
    .then(() => ({}))
    .catch(error => ({ error }));
}

export function updateSession({ token, requestQueue }) {
  const sessionId = cookie.load('SID');
  return UserApi.refreshSession({ token, sessionId, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }));
}

export function* unlinkConcur() {
  const { id, token } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(deleteConcurToken, { id, token, requestQueue });
  if (error) {
    yield put(unlinkConcurError());
    yield call(scrollToTop);
  } else {
    const { body } = yield call(updateSession, { token, requestQueue });
    if (body) {
      yield put(gotSession(body));
      yield put(unlinkConcurSuccess());
    }
  }
}

export function* linkExpensify() {
  const { id, token } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(createExpensifyToken, { id, token, requestQueue });
  if (error) {
    yield put(linkExpensifyError());
    yield call(scrollToTop);
  } else {
    const { body } = yield call(updateSession, { token, requestQueue });
    if (body) {
      yield put(gotSession(body));
      yield put(linkExpensifySuccess());
    }
  }
}

export function* unlinkExpensify() {
  const { id, token } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { error } = yield call(deleteExpensifyToken, { id, token, requestQueue });
  if (error) {
    yield put(unlinkExpensifyError());
    yield call(scrollToTop);
  } else {
    const { body } = yield call(updateSession, { token, requestQueue });
    if (body) {
      yield put(gotSession(body));
      yield put(unlinkExpensifySuccess());
    }
  }
}

export function* initializeBusinessProfileConnect() {
  const { token } = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { body } = yield call(updateSession, { token, requestQueue });
  if (body) {
    yield put(gotSession(body));
  }
}

function* paymentMethodsSaga() {
  yield takeEvery(GET_PAYMENT_METHODS, getPaymentMethods);
  yield takeLatest(GOT_SESSION, getPaymentMethods);
  yield takeEvery(SELECT_PAYMENT_METHOD, getPaymentMethods);
  yield takeLatest(SET_DEFAULT_PAYMENT_METHOD, setDefaultPaymentMethod);
  yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethod);
  yield takeLatest(GET_PAYMENT_TOKEN, getPaymentToken);
  yield takeLatest(INITIALIZE_NEW_PAYMENT_METHOD, getPaymentToken);
  yield takeLatest(INITIALIZE_NEW_BUSINESS_PROFILE, initializeNewBusinessProfile);
  yield takeLatest(INITIALIZE_BUSINESS_PROFILE_CONNECT, initializeBusinessProfileConnect);
  yield takeEvery(SAVE_PAYMENT_METHOD, savePaymentMethod);
  yield takeEvery(SAVE_BUSINESS_PROFILE, saveBusinessProfile);
  yield takeEvery(UNLINK_CONCUR, unlinkConcur);
  yield takeEvery(LINK_EXPENSIFY, linkExpensify);
  yield takeEvery(UNLINK_EXPENSIFY, unlinkExpensify);
  yield takeLatest(INITIALIZE_CHECKOUT, getPaymentMethods);
}

export default paymentMethodsSaga;
