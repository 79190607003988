import { Record, List, OrderedMap, Map } from 'immutable';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import get from 'lodash/get';

import BookingsGroup from 'models/bookings-group';
import Bookings from 'models/bookings';
import Booking from 'models/booking';
import BookingCounts from 'models/booking-counts';
import { Events } from 'models/event';
import { Locations, Location } from 'models/locations';
import { ParkingPasses } from 'models/parking-passes';
import PastBookings from 'models/past-bookings';
import PastBookingGroup from 'models/past-booking-group';
import Receipt from 'models/receipt';
import Review, { Reviews } from 'models/review';
import { Sellers } from 'models/seller';
import { Vehicle, Vehicles } from 'models/vehicles';
import Venue, { Venues } from 'models/venue';

import { ACTIVATE_ACCOUNT_MODAL } from 'action-creators/bookings/activate-account-modal';
import { ADD_VEHICLE } from 'action-creators/bookings/add-vehicle';
import { BOOKING_CANCELLED } from 'action-creators/bookings/booking-cancelled';
import { CLEANUP_CANCEL_BOOKING_STATE } from 'action-creators/bookings/cleanup-cancel-booking-state';
import { CLEANUP_PARKING_PASSES_STATE } from 'action-creators/parking-pass/cleanup-parking-passes-state';
import { CLEANUP_RECEIPT_STATE } from 'action-creators/bookings/cleanup-receipt-state';
import { CREATE_REVIEW } from 'action-creators/bookings/create-review';
import { DISMISS_ACTIVATE_ACCOUNT_MODAL } from 'action-creators/bookings/dismiss-activate-account-modal';
import { DISMISS_REVIEW_MODAL } from 'action-creators/bookings/dismiss-review-modal';
import { GOT_BOOKINGS } from 'action-creators/bookings/got-bookings';
import { GOT_BOOKING_COUNTS } from 'action-creators/bookings/got-booking-counts';
import { GOT_FREQUENT_LOCATIONS } from 'action-creators/bookings/got-frequent-locations';
import { GOT_SELLER } from 'action-creators/parking-pass/got-seller';
import { GOT_UPCOMING_BOOKINGS } from 'action-creators/bookings/got-upcoming-bookings';
import { GOT_PAST_BOOKINGS } from 'action-creators/bookings/got-past-bookings';
import { GOT_VEHICLE } from 'action-creators/bookings/got-vehicle';
import { INITIALIZE_CANCEL_BOOKING } from 'action-creators/bookings/initialize-cancel-booking';
import { INITIALIZE_PARKING_PASSES } from 'action-creators/bookings/initialize-parking-passes';
import { INITIALIZE_RECEIPT } from 'action-creators/bookings/initialize-receipt';
import { REVIEW_BOOKING } from 'action-creators/bookings/review-booking';
import { SET_SELECTED_PARKING_PASS_IDS } from 'action-creators/bookings/set-selected-parking-pass-ids';

export class BookingsState extends Record({
  activateAccountModalDismissed: false,
  bookingCounts: new BookingCounts(),
  bookings: Bookings([]),
  bookingsFilter: null,
  cancelBookingInitialized: false,
  events: Events(),
  frequentLocations: List(),
  frequentLocationBookings: OrderedMap(),
  locations: OrderedMap(),
  nextRequests: Map(),
  parkingPasses: OrderedMap(),
  parkingPassesInitialized: false,
  pastBookings: new PastBookings(),
  receipt: null,
  receiptInitialized: false,
  reviewModalDismissed: false,
  reviewRating: null,
  reviewedBooking: null,
  reviews: Map(),
  selectedParkingPassIds: List(),
  sellers: Map(),
  shouldShowActivateAccountModal: false,
  upcomingBookings: new BookingsGroup(),
  venue: null,
  venues: Venues(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let bookings;
    let parkingPasses;
    let receipt;
    let venue;
    let {
      events = [],
      venues = [],
      locations = [],
      vehicles = [],
      reviews = [],
      reviewedBooking,
      reviewRating,
    } = props;

    compact(concat(
      get(props, 'upcomingBookings.bookings'),
      get(props, 'pastBookings.all.transientBookings.bookings'),
      get(props, 'pastBookings.all.monthlyBookings.bookings'),
      get(props, 'pastBookings.business.transientBookings.bookings'),
      get(props, 'pastBookings.business.monthlyBookings.bookings'),
      get(props, 'pastBookings.refunded.transientBookings.bookings'),
      get(props, 'pastBookings.refunded.monthlyBookings.bookings'),
      reviewedBooking,
    )).forEach((booking) => {
      locations.push(get(booking, ['_embedded', 'pw:location'], null));
      venues.push(get(booking, ['_embedded', 'pw:venue'], null));
      events.push(get(booking, ['_embedded', 'pw:event'], null));
      reviews.push(get(booking, ['_embedded', 'pw:review'], null));
    });

    if (props.bookings && props.bookings.length > 0) {
      const embedded = props.bookings[0]._embedded;
      bookings = Bookings(props.bookings, { sort: { prop: 'startTime', direction: 'desc' } });
      parkingPasses = ParkingPasses(props.bookings);
      venue = embedded['pw:event'] ? new Venue(get(embedded, ['pw:event', '_embedded', 'pw:venue'], null)) : null;
      venues.push(venue);

      const { currency } = locations[0];
      receipt = new Receipt(embedded['pw:receipt'], { currency });
    } else {
      bookings = Bookings();
      parkingPasses = ParkingPasses();
      receipt = null;
      venue = null;
    }

    if (reviewedBooking) {
      // Do this last so that it's not parsed yet
      reviewedBooking = new Booking(reviewedBooking);
    }


    let frequentLocations = [];
    let frequentLocationBookings = {};
    if (props.frequentLocations && props.frequentLocations.length > 0) {
      for (let i = 0; i < props.frequentLocations.length; i++) {
        const location = props.frequentLocations[i];
        const booking = location._embedded['pw:most_recent_booking'];

        frequentLocations.push(new Location(location));
        frequentLocationBookings[props.frequentLocations[i].id] = new Booking(booking);
      }
    }

    frequentLocations = List(frequentLocations);
    frequentLocationBookings = OrderedMap(frequentLocationBookings);

    venues = Venues(venues.filter(v => v));
    locations = Locations(locations.filter(l => l));
    events = Events(events.filter(e => e));
    vehicles = Vehicles(vehicles.filter(v => v));
    reviews = Reviews(reviews);
    const selectedParkingPassIds = List(props.selectedParkingPassIds);
    const sellers = Sellers(props.sellers);

    const bookingCounts = new BookingCounts(props.bookingCounts);
    const activateAccountModalDismissed = props.activateAccountModalDismissed;

    reviewRating = reviewRating ? parseInt(reviewRating, 10) : null;

    super({
      activateAccountModalDismissed,
      bookingCounts,
      bookings,
      events,
      frequentLocationBookings,
      frequentLocations,
      locations,
      parkingPasses,
      pastBookings: new PastBookings({
        allBookings: new PastBookingGroup({
          transient: new BookingsGroup(get(props, 'pastBookings.all.transientBookings')),
          monthly: new BookingsGroup(get(props, 'pastBookings.all.monthlyBookings')),
        }),
        businessBookings: new PastBookingGroup({
          transient: new BookingsGroup(get(props, 'pastBookings.business.transientBookings')),
          monthly: new BookingsGroup(get(props, 'pastBookings.business.monthlyBookings')),
        }),
        refundedBookings: new PastBookingGroup({
          transient: new BookingsGroup(get(props, 'pastBookings.refunded.transientBookings')),
          monthly: new BookingsGroup(get(props, 'pastBookings.refunded.monthlyBookings')),
        }),
      }),
      receipt,
      reviewRating,
      reviewedBooking,
      reviews,
      selectedParkingPassIds,
      sellers,
      upcomingBookings: new BookingsGroup(props.upcomingBookings),
      vehicles,
      venue,
      venues,
    });
  }
}

export default function bookingsReducer(state = new BookingsState(), action = null) {
  switch (action.type) {
    case GOT_PAST_BOOKINGS: {
      const { bookingType, purchaseType } = action.payload.requestAction.payload;
      const { events, locations, bookings, reviews, venues } = action.payload.parsedModels;

      return state.merge({
        events: state.events.merge(events),
        locations: state.locations.merge(locations),
        pastBookings: state.pastBookings.merge({
          [purchaseType]: state.pastBookings.get(purchaseType).merge({
            [bookingType]: bookings,
          }),
        }),
        reviews: state.reviews.merge(reviews),
        venues: state.venues.merge(venues),
      });
    }
    case GOT_UPCOMING_BOOKINGS: {
      const { events, locations, upcomingBookings, venues } = action.payload.parsedModels;

      return state.merge({
        events: state.events.merge(events),
        locations: state.locations.merge(locations),
        upcomingBookings,
        venues: state.venues.merge(venues),
      });
    }
    case GOT_BOOKINGS: {
      const {
        bookings,
        locations,
        events,
        venue,
        queryType,
        bookingType,
        nextRequest,
        receipt,
        parkingPasses,
        reviews,
      } = action.payload;

      let { nextRequests } = state;
      if (nextRequest) {
        nextRequests = nextRequests.setIn([bookingType || 'bookings', queryType], nextRequest);
      }
      const venues = venue ? OrderedMap().set(venue.id, venue) : OrderedMap();

      return state.merge({
        bookings: state.bookings.mergeNewBookings(bookings || {}),
        locations: state.locations.merge(locations),
        events: state.events.merge(events),
        venues: state.venues.merge(venues),
        reviews: state.reviews.merge(reviews),
        venue,
        receipt,
        parkingPasses,
        nextRequests,
      });
    }
    case GOT_BOOKING_COUNTS: {
      const { type, query, count } = action.payload;
      const bookingCounts = state.bookingCounts.setCount(type, query, count);

      return state.merge({
        bookingCounts,
      });
    }
    case GOT_FREQUENT_LOCATIONS: {
      const { locations, bookings } = action.payload;
      const frequentLocationBookings = state.frequentLocationBookings.merge(new OrderedMap(bookings));

      return state.merge({
        frequentLocations: new List(locations),
        frequentLocationBookings,
      });
    }
    case INITIALIZE_RECEIPT: {
      const { selectedParkingPassIds } = action.payload;

      return state.merge({
        receiptInitialized: true,
        selectedParkingPassIds,
      });
    }
    case SET_SELECTED_PARKING_PASS_IDS: {
      const { selectedParkingPassIds } = action.payload;

      return state.merge({
        parkingPassesInitialized: true,
        selectedParkingPassIds,
      });
    }
    case GOT_SELLER: {
      const sellers = state.sellers.merge(action.payload.sellers);

      return state.merge({
        sellers,
      });
    }
    case CLEANUP_RECEIPT_STATE:
      return state.merge({
        receiptInitialized: false,
      });
    case GOT_VEHICLE: {
      const { vehicle } = action.payload;
      const { selectedParkingPassIds } = state;
      let { parkingPasses } = state;

      selectedParkingPassIds.forEach((id) => {
        let parkingPass = parkingPasses.get(id);
        parkingPass = parkingPass.merge({ vehicle, vehicleId: vehicle.id.toString() });
        parkingPasses = parkingPasses.set(id.toString(), parkingPass);
      });

      return state.merge({ parkingPasses });
    }
    case ADD_VEHICLE: {
      const { vehicleId, bookingId, plateNumber } = action.payload;

      let parkingPass = state.parkingPasses.get(bookingId.toString());

      if (plateNumber) {
        const vehicle = new Vehicle({ plate_number: plateNumber });
        parkingPass = parkingPass.merge({ vehicle, vehicleId: null });
      } else {
        parkingPass = parkingPass.merge({ vehicleId: vehicleId.toString(), vehicle: null });
      }

      return state.merge({
        parkingPasses: state.parkingPasses.set(parkingPass.id.toString(), parkingPass),
      });
    }
    case INITIALIZE_PARKING_PASSES: {
      return state.merge({
        parkingPassesInitialized: true,
      });
    }
    case ACTIVATE_ACCOUNT_MODAL:
      return state.merge({
        shouldShowActivateAccountModal: true,
      });
    case DISMISS_ACTIVATE_ACCOUNT_MODAL:
      return state.merge({
        activateAccountModalDismissed: true,
        shouldShowActivateAccountModal: false,
      });
    case BOOKING_CANCELLED: {
      const { booking } = action.payload;
      let { bookings, bookingCounts } = state;

      if (booking.businessPurchase) {
        bookingCounts = bookingCounts.setIn(['upcoming', 'businessPurchase', 'bookings'], bookingCounts.getIn(['upcoming', 'businessPurhcase', 'bookings']) - 1);
        bookingCounts = bookingCounts.setIn(['cancelled', 'businessPurchase', 'bookings'], bookingCounts.getIn(['cancelled', 'businessPurhcase', 'bookings']) + 1);
      }
      bookingCounts = bookingCounts.setIn(['upcoming', 'all', 'bookings'], bookingCounts.getIn(['upcoming', 'all', 'bookings']) - 1);
      bookingCounts = bookingCounts.setIn(['cancelled', 'all', 'bookings'], bookingCounts.getIn(['cancelled', 'all', 'bookings']) + 1);

      bookings = bookings.deleteBooking(booking.id);
      return state.merge({ bookingCounts, bookings });
    }
    case CREATE_REVIEW: {
      const review = new Review(action.payload);
      const reviews = state.reviews.set(review.bookingId.toString(), review);

      return state.merge({ reviews });
    }
    case REVIEW_BOOKING: {
      const { reviewedBooking } = action.payload;
      return state.merge({ reviewedBooking, reviewModalDismissed: false, reviewRating: null });
    }
    case DISMISS_REVIEW_MODAL:
      return state.merge({ reviewModalDismissed: true, reviewedBooking: null, reviewRating: null });
    case INITIALIZE_CANCEL_BOOKING:
      return state.merge({ cancelBookingInitialized: true });
    case CLEANUP_CANCEL_BOOKING_STATE:
      return state.merge({ cancelBookingInitialized: false });
    case CLEANUP_PARKING_PASSES_STATE:
      return state.merge({ parkingPassesInitialized: false });
    default: {
      return state;
    }
  }
}
