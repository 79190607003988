import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import cookie from 'react-cookie';
import { decamelizeKeys } from 'humps';
import moment from 'moment-timezone';
import { Map } from 'immutable';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import result from 'lodash/result';
import url from 'url';

import getPricingCreator, { GET_PRICING } from 'action-creators/checkout/get-pricing';
import getPreviewCreator, { GET_PREVIEW } from 'action-creators/checkout/get-preview';
import { CHANGE_ADD_ONS } from 'action-creators/checkout/change-add-ons';
import { INITIALIZE_CHECKOUT } from 'action-creators/checkout/initialize-checkout';
import { SUBMIT_CHECKOUT } from 'action-creators/checkout/submit-checkout';
import setLocation from 'action-creators/search/set-location';
import unsetFormSubmitted from 'action-creators/checkout/unset-form-submitted';
import setApiError from 'action-creators/checkout/set-api-error';
import setRequiredFieldErrors from 'action-creators/payment-methods/set-required-field-errors';
import { ON_POP_STATE } from 'action-creators/router/on-pop-state';
import gotPricing from 'action-creators/checkout/got-pricing';
import gotPreview from 'action-creators/checkout/got-preview';
import addMessageAndScrollToTop from 'action-creators/messaging/add-message-and-scroll-to-top';
import gotBookings from 'action-creators/bookings/got-bookings';
import gotSessionCreator from 'action-creators/account/got-session';
import refreshSearchFromHistory from 'action-creators/search/refresh-search-from-history';
import gotSelectedQuote from 'action-creators/search/got-selected-quote';
import getLocationSeller from 'action-creators/search/get-location-seller';
import disableBookExtendedTimes from 'action-creators/checkout/disable-book-extended-times';
import trackEvent from 'action-creators/analytics/track-event';
import getQuoteCreator, { GET_QUOTE } from 'action-creators/checkout/get-quote';
import gotBrand from 'action-creators/brand/got-brand';

import { checkoutSuccess } from 'lib/common/messages';
import CheckoutApi from 'lib/api/checkout/checkout';
import { CHECKOUT_PURCHASE } from 'lib/analytics/events';
import { pageProps } from 'lib/analytics/page-properties';

import { PARKING_UNAVAILABLE_MESSAGE } from 'components/checkout/modal-notices';
import { setShouldScrollToError } from 'action-creators/checkout';
import { generateRequiredFieldErrors } from 'lib/common/required-fields';

const LOG_ERROR = 'log-error';

const getCheckout = state => state.checkout;
const getUser = state => state.account.user;
const getApp = state => state.app.name;
const getBrand = state => state.brand.brand;
const getLocations = state => state.search.locations;
const getCurrentSearch = state => state.search.currentSearch;
const getSelectedQuote = state => state.search.selectedQuote;
const getSelectedLocation = state => state.search.selectedLocation;
const getSelectedAddOns = state => state.checkout.selectedAddOns;
const getAutoApplyCoupon = state => state.checkout.autoApplyCoupon;
const getCouponCode = state => state.checkout.couponCode;
const getRequestQueue = state => state.requests.requestQueue;
const getTrackingProperties = state => state.analytics.trackingProperties;
const getInsights = state => state.analytics.insights;
const getSelectedPaymentMethod = state => state.paymentMethods.selectedPaymentMethod;
const getNewPaymentMethod = state => state.paymentMethods.newPaymentMethod;
const getRequiredCheckoutFields = state => state.checkout.requiredFields;
const getRequiredPaymentFields = state => state.paymentMethods.requiredFields;
const getCheckoutError = state => state.checkout.error;
const getVenue = state => state.search.venue;
const getRouterLocation = state => state.router.location;
const getPlateNumber = state => state.checkout.plateNumber;

const fetchPricing = ({ pricingId, accessToken, requestQueue }) => (
  CheckoutApi
    .getPricing({ pricingId, accessToken }, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* getPricing() {
  const user = yield select(getUser);
  const accessToken = user.token;
  const quote = yield select(getSelectedQuote);
  const pricingId = quote && quote.pricings ? quote.pricings.get(0).id : null;
  const requestQueue = yield select(getRequestQueue);
  const { body, error } = yield call(fetchPricing, { pricingId, accessToken, requestQueue });
  if (body) {
    yield put(gotPricing({ body }));
  } else {
    yield put({ type: LOG_ERROR, error });
  }
}

const fetchPreview = ({ quoteId, addOnIds, couponCode, autoApplyCoupon, accessToken, requestQueue }) => (
  CheckoutApi
    .getPreview({ quoteId, addOnIds, couponCode, autoApplyCoupon }, accessToken, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* getPreview() {
  const currentError = yield select(getCheckoutError);
  if (currentError && currentError.message === PARKING_UNAVAILABLE_MESSAGE) { return; }

  const selectedQuote = yield select(getSelectedQuote);
  const selectedLocation = yield select(getSelectedLocation);
  const addOns = yield select(getSelectedAddOns);
  const user = yield select(getUser);
  const couponCode = yield select(getCouponCode);
  const requestQueue = yield select(getRequestQueue);
  let autoApplyCoupon = yield select(getAutoApplyCoupon);
  const accessToken = user.token;
  const currency = get(selectedLocation, 'currency', null);
  const quoteId = get(selectedQuote, 'id', null);
  const addOnIds = result(addOns, 'getApiFormattedAddOnIds', []);
  if (!quoteId || !selectedLocation) { return; }

  if (autoApplyCoupon == null) {
    autoApplyCoupon = !(couponCode);
  }

  const { body, error } = yield call(fetchPreview, {
    quoteId,
    addOnIds,
    couponCode,
    autoApplyCoupon,
    accessToken,
    requestQueue,
  });

  if (body) {
    yield put(gotPreview(body, { currency }));
  } else {
    yield put({ type: LOG_ERROR, error });
  }
}

export function* initializeCheckout(action) {
  const { quoteId } = action.payload;
  let { locationId } = action.payload;
  let selectedLocation = yield select(getSelectedLocation);
  let selectedQuote = yield select(getSelectedQuote);
  const locations = yield select(getLocations);
  if (!(get(selectedLocation, 'id') === locationId && get(selectedQuote, 'id'))) {
    yield put.resolve(refreshSearchFromHistory());
  }
  const venue = yield select(getVenue);

  if (!locationId) {
    const currentSearch = yield select(getCurrentSearch);
    locationId = currentSearch.selectedLocationId;
  }

  if (venue && venue.enhancedAirport) {
    yield put(disableBookExtendedTimes());
  }

  if (get(selectedLocation, 'id') !== locationId) {
    selectedLocation = locations.get(locationId.toString());
  }

  if (get(selectedQuote, 'id') !== quoteId) {
    selectedQuote = selectedLocation.getQuoteById(quoteId);
  }

  yield put.resolve(setLocation({ selectedLocation, selectedQuote }));
  yield put(getPreviewCreator({ autoApplyCoupon: true }));
  yield put(getPricingCreator());
  yield put(getLocationSeller({ locationId }));
}

const validateAccountInfo = ({ user }) => {
  if (!user || user.isLoggedIn()) { return Map(); }
  return Map(user.validateFields());
};

const validateAddOns = ({ selectedAddOns, selectedQuote }) => {
  if (selectedQuote.addOns) {
    return selectedQuote.addOns.validateRequiredAddOns(selectedAddOns);
  }
  return Map();
};

const validateRequiredLicensePlate = ({ selectedQuote, plateNumber }) => {
  if (get(selectedQuote.restrictions, 'capture_plate', false)) {
    return {
      requiredLicensePlate: {
        isValid: !isEmpty(plateNumber),
        isEmpty: isEmpty(plateNumber),
        displayName: 'License plate',
      },
    };
  }
  return Map();
};

const validateBraintree = ({ selectedPaymentMethod, newPaymentMethod }) => {
  if (selectedPaymentMethod) {
    return Map();
  }
  return Map(newPaymentMethod.toJS());
};

export function* getRequiredFieldErrors() {
  const user = yield select(getUser);
  const selectedQuote = yield select(getSelectedQuote);
  const selectedAddOns = yield select(getSelectedAddOns);
  const selectedPaymentMethod = yield select(getSelectedPaymentMethod);
  const newPaymentMethod = yield select(getNewPaymentMethod);

  const accountFields = yield call(validateAccountInfo, { user });
  const plateNumber = yield select(getPlateNumber);
  const requiredLicensePlateField = yield call(validateRequiredLicensePlate, { selectedQuote, plateNumber });
  const addOnFields = yield call(validateAddOns, { selectedQuote, selectedAddOns });
  const braintreeFields = yield call(validateBraintree, { selectedPaymentMethod, newPaymentMethod });
  const fields = accountFields.merge(addOnFields).merge(braintreeFields).merge(requiredLicensePlateField);

  const requiredCheckoutFields = yield select(getRequiredCheckoutFields);
  const requiredPaymentFields = yield select(getRequiredPaymentFields);
  const requiredFields = requiredCheckoutFields.merge(requiredPaymentFields);

  const requiredFieldErrors = yield call(generateRequiredFieldErrors, { requiredFields, fields });
  return requiredFieldErrors;
}

const getSessionIdFromCookie = () => (cookie.load('SID'));

const postCheckout = ({ params, accessToken, requestQueue }) => (
  CheckoutApi
    .submitCheckout(params, accessToken, requestQueue)
    .then(({ body, response }) => ({ body, response }))
    .catch(({ error, response }) => ({ error, response }))
);

export function* submitCheckout(action) {
  const requiredFieldErrors = yield call(getRequiredFieldErrors);
  if (requiredFieldErrors.size > 0) {
    yield put(setRequiredFieldErrors({ requiredFieldErrors }));
    yield put(setShouldScrollToError(true));
    yield put(unsetFormSubmitted());
    return;
  }
  let params = action.payload || {};
  const brand = yield select(getBrand);
  const user = yield select(getUser);
  const accessToken = user.token;
  const { firstname, lastname, phone: phoneNumber, email } = user;
  let { marketingAllowed } = user;
  if (!brand.requireMarketingOptIn) { marketingAllowed = true; }

  const guestCheckout = !user.isLoggedIn();

  const sessionId = yield call(getSessionIdFromCookie);

  const currentSearch = yield select(getCurrentSearch);
  const selectedQuote = yield select(getSelectedQuote);
  const { id: quoteId } = selectedQuote;
  const eventId = currentSearch.isEventSearch ? currentSearch.destination.id : null;
  const venueId = currentSearch.isVenueSearch ? currentSearch.destination.id : null;

  const trackingProperties = yield select(getTrackingProperties);
  const { campaignId, medium, source, mailchimpEmailId } = trackingProperties;

  const insights = yield select(getInsights);
  const { analyticsID: distinctId } = insights;

  const requestQueue = yield select(getRequestQueue);

  const {
    affiliateId,
    portalAffiliateId,
    couponCode,
    saveCreditCard,
    monthlyStart,
    checkoutTotal: finalPrice,
    deviceData,
    bookExtendedTimes,
    plateNumber,
  } = yield select(getCheckout);

  const selectedPaymentMethod = yield select(getSelectedPaymentMethod);
  const savedPaymentToken = selectedPaymentMethod ? selectedPaymentMethod.id : null;
  const addOns = yield select(getSelectedAddOns);
  const addOnIds = addOns.getApiFormattedAddOnIds();
  const selectedLocation = yield select(getSelectedLocation);
  const { currency } = selectedLocation;

  // Meta Data used for MailChimp
  const metaDataObject = decamelizeKeys(
    Object.assign({
      campaignId,
      medium,
      source,
      mailchimpEmailId,
    }),
  );
  const metaData = JSON.stringify(metaDataObject);

  // Forter fraud checking.
  const forterToken = cookie.load('forterToken');
  const forterFields = forterToken
    ? { forter_token_cookie: forterToken, source: 'WEB' }
    : null;

  params = Object.assign(params, {
    addOnIds,
    affiliateId,
    bookExtendedTimes,
    couponCode,
    currency,
    deviceData,
    distinctId,
    email,
    eventId,
    finalPrice,
    firstname,
    forterFields,
    lastname,
    marketingAllowed,
    metaData,
    monthlyStart,
    phoneNumber,
    plateNumber,
    portalAffiliateId,
    saveCreditCard,
    savedPaymentToken,
    sessionId,
    venueId,
    quoteId,
  });

  if (currentSearch.isMonthlySearch) {
    params.monthlyStart = moment(params.monthlyStart).format('YYYY-MM-DD');
  } else {
    delete params.monthlyStart;
  }

  const { body, error, response } = yield call(postCheckout, { params, accessToken, requestQueue });

  if (error) {
    yield put(unsetFormSubmitted());
    yield put(setApiError({ error, response }));
    return;
  }

  // Clear apiError so we don't see an error from a failed previous submission attempt.
  yield put(setApiError());

  yield put(gotBookings({ body }));
  const pageBrand = get(body, '[0]._embedded[pw:partner]._embedded[pw:brand]', null);
  if (pageBrand) {
    yield put(gotBrand({ brand: pageBrand }));
  }

  const firstBooking = body[0];
  if (firstBooking) {
    const session = firstBooking._embedded['pw:session'];
    if (session) {
      yield put(gotSessionCreator({ sessionId: session.id, token: session.token, user: session.user, type: 'stats', query: 'upcoming' }));
    }
  }

  const bookingIDs = body.map(b => b.id);
  const bookingCodes = body[0].authorization_code;
  yield put(push(`/reserve/thankyou/?ids=${bookingIDs}&u=${bookingCodes}`));
  yield put(addMessageAndScrollToTop(checkoutSuccess({ bookingId: bookingIDs[0] })));

  const app = yield select(getApp);
  const bookingCount = body.length;

  yield put(trackEvent({
    ...pageProps({ app, currentSearch }),
    ...CHECKOUT_PURCHASE,
    properties: {
      quoteID: quoteId,
      bookingIDs,
      bookingCount,
      guestCheckout,
      isMonthly: currentSearch.isMonthlySearch,
    },
  }));
}

const fetchQuote = ({ quoteId, requestQueue, accessToken }) => (
  CheckoutApi.getQuote({ quoteId, accessToken }, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* getQuote(action) {
  const { quoteId } = action.payload;
  const requestQueue = yield select(getRequestQueue);
  const user = yield select(getUser);
  const { token: accessToken } = user;
  const brand = yield select(getBrand);
  const { nonBookableRules } = brand;
  const { body, error } = yield call(fetchQuote, { quoteId, requestQueue, accessToken });

  if (error) {
    // Redirect home with quote expired error message
    return;
  }

  yield put(gotSelectedQuote({ body, quoteId, nonBookableRules }));
}

export function* onPopState(action) {
  const app = yield select(getApp);
  const { historyState } = action.payload;
  if (historyState && historyState.search && app === 'Checkout') {
    let { quoteId } = historyState.search;
    if (!quoteId) {
      const routerLocation = yield select(getRouterLocation);
      ({ quote_id: quoteId } = url.parse(routerLocation.search, true).query);
    }
    yield put.resolve(getQuoteCreator({ quoteId }));
  }
}

export default function* root() {
  yield takeLatest(INITIALIZE_CHECKOUT, initializeCheckout);
  yield takeLatest(GET_PRICING, getPricing);
  yield takeLatest(GET_PREVIEW, getPreview);
  yield takeLatest(CHANGE_ADD_ONS, getPreview);
  yield takeLatest(ON_POP_STATE, onPopState);
  yield takeLatest(SUBMIT_CHECKOUT, submitCheckout);
  yield takeLatest(GET_QUOTE, getQuote);
}
