import { Record, Set } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';

import CancellableStatus from 'models/cancellable-status';
import MonthlyPricing from 'models/monthly-pricing';
import DeepLink from 'models/deep-link';
import BookingShare from 'models/booking-share';
import Partner from 'models/partner';

const VALID_PRICES = Set(['pricePaid', 'fullPrice']);
const UpcomingBookingDefinition = Record({ beforeProp: 'startTime', threshold: moment() });

export default class Booking extends Record({
  id: null,
  authorizationCode: null,
  bookingShare: null,
  packageAuthorizationCode: null,
  startTime: null,
  endTime: null,
  pricePaid: null,
  fullPrice: null,
  passValue: null,
  parkingPassURL: null,
  parkingPassId: null,
  package: {
    id: null,
    name: null,
  },
  purchasedAt: null,
  locationId: null,
  type: null,
  eventId: null,
  venueId: null,
  cancelledAt: null,
  // TODO: Remove `cancellable` once cancel Booking logic updated. Replaced by cancellable_status.
  cancellable: false,
  cancellableStatus: null,
  // TODO: Remove `noncancellableReason` once cancel Booking logic updated. Also replaced by cancellable_status.
  noncancellableReason: null,
  monthlyPricing: null,
  businessPurchase: false,
  expenseMemo: null,
  airport: false,
  monthlyIFrameURI: null,
  accountActivated: false,
  onDemand: false,
  showNameOnPass: false,
  hasTBDTimes: false,
  partner: new Partner(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const locationProps = get(props, '_embedded.pw:location', {});
    const parkingPassProps = get(props, '_embedded.pw:parking_pass', {});
    const bookingShareProps = get(props, '_embedded.pw:booking_share', {});

    const pricePaid = get(props, ['price_paid', locationProps.currency]);
    const fullPrice = get(props, ['full_price', locationProps.currency]);
    const passValue = get(props, ['pass_value', locationProps.currency]);

    const startTime = locationProps.timezone
      ? moment(props.start_time).tz(locationProps.timezone)
      : moment(props.start_time);
    const endTime = locationProps.timezone ? moment(props.end_time).tz(locationProps.timezone) : moment(props.end_time);

    const purchasedAt = locationProps.timezone
      ? moment(props.purchased_at).tz(locationProps.timezone)
      : moment(props.purchasedAt);
    const cancelledAt = props.cancelled_at ? moment(props.cancelled_at).tz(locationProps.timezone) : null;

    const cancellableStatus = new CancellableStatus(props.cancellable_status);

    const eventId = props._embedded && props._embedded['pw:event'] ? props._embedded['pw:event'].id : null;
    const venueId = props._embedded && props._embedded['pw:venue'] ? props._embedded['pw:venue'].id : null;

    const airport = props._embedded && props._embedded['pw:venue'] ? props._embedded['pw:venue'].enhanced_airport : false;

    const monthlyPricingProps = props._embedded && props._embedded['pw:monthly_pricing'] ? props._embedded['pw:monthly_pricing'] : null;
    const monthlyIFrameURI = !!(monthlyPricingProps) && locationProps.monthly_iframe_uri;

    const accountActivated = props._embedded && props._embedded['pw:customer'] ? props._embedded['pw:customer'].account_activated : false;

    const showNameOnPass = get(props, '_embedded[pw:parking_pass].validation.display.customer_name', false);

    const partner = new Partner(get(props, '_embedded[pw:partner]', {}));

    super({
      authorizationCode: props.authorization_code,
      bookingShare: new BookingShare(bookingShareProps),
      packageAuthorizationCode: props.package_authorization_code,
      id: props.id,
      locationId: locationProps.id,
      package: {
        id: props.package ? props.package.id : null,
        name: props.package ? props.package.name : null,
      },
      parkingPassURL: props.parking_pass_web_url,
      parkingPassId: parkingPassProps.id,
      type: props.type,
      eventId,
      venueId,
      startTime,
      endTime,
      pricePaid,
      fullPrice,
      passValue,
      purchasedAt,
      cancelledAt,
      cancellable: cancellableStatus.cancellableNow,
      cancellableStatus,
      noncancellableReason: cancellableStatus.message,
      monthlyPricing: monthlyPricingProps ? new MonthlyPricing(monthlyPricingProps) : null,
      businessPurchase: props.business_purchase,
      expenseMemo: props.expense_memo,
      airport,
      monthlyIFrameURI,
      accountActivated,
      onDemand: !!props.on_demand,
      showNameOnPass: showNameOnPass === 'required',
      hasTBDTimes: props.times_tbd,
      partner,
    });
  }

  static upcomingThreshold({ type = 'transient_booking' } = {}) {
    switch (type) {
      case 'monthly_booking':
        return new UpcomingBookingDefinition({ beforeProp: 'startTime', threshold: moment().add(1, 'day') });
      case 'transient_booking':
      default:
        return new UpcomingBookingDefinition({ beforeProp: 'endTime', threshold: moment().subtract(4, 'hours') });
    }
  }

  getLocation(locations) {
    if (this.locationId) {
      return locations.get(this.locationId.toString());
    }
    return null;
  }

  getEvent(events) {
    return events.find(event => event.id === this.eventId);
  }

  getVenue(venues, events) {
    const event = this.getEvent(events);

    if (event && event.venueId) {
      return venues.get(event.venueId.toString());
    }

    return null;
  }

  getFormattedPrice(type) {
    if (!type || !VALID_PRICES.has(type) || this[type] == null) { return null; }

    let price = this[type].toString();
    const cents = price.split('.')[1];
    if (cents && cents.length < 2) { price = `${price}0`; }

    return price;
  }

  getDeepLink(brand, trackingParams = {}, admin = false, { trackingProperties } = {}, authorizationCode = null) {
    return (
      new DeepLink({
        brand,
        view: 'ticket',
        params: {
          id: this.id,
          u: (authorizationCode || this.authorizationCode),
        },
        trackingParams,
        admin,
        trackingProperties,
      })
    );
  }

  // TODO: Refactor when updating cancel booking logic. Most of this logic is replaced by cancellable_status.
  uncancellableReason() {
    let reason = null;

    if (this.startTime < moment()) {
      reason = 'surpassed_start_time';
    } else if (!this.cancellable && this.isPackage) {
      reason = 'package';
    } else if (!this.cancellable && this.isMonthly) {
      reason = 'monthly';
    } else if (!this.cancellable) {
      reason = 'partner';
    }

    return reason;
  }

  get anonymousReceiptUrl() {
    return `${this.receiptUrl}?u=${this.authorizationCode}`;
  }

  get receiptUrl() {
    switch (this.type) {
      case 'monthly_booking':
        return `/ticket/monthly_receipt/${this.id}`;
      default:
        return `/ticket/receipt/${this.id}`;
    }
  }

  get isMonthly() {
    return this.type === 'monthly_booking';
  }

  get isTransient() {
    return this.type === 'transient_booking';
  }

  get isPackage() {
    return !!this.package.id;
  }

  get isUpcoming() {
    const { beforeProp, threshold } = this.constructor.upcomingThreshold({ type: this.type });
    return this[beforeProp].diff(threshold) > 0;
  }

  get isActive() {
    return !this.cancelledAt;
  }
}

export function hasBusinessPurchase(bookings) {
  return bookings.reduce((v, b) => (v || b.businessPurchase), false);
}

