import { Record, List, Set, Map } from 'immutable';
import moment from 'moment-timezone';
import uuid from 'uuid/v4';
import max from 'lodash/max';
import min from 'lodash/min';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

// Models
import Event from 'models/event';
import Hub from 'models/hub';
import User from 'models/user';
import Venue from 'models/venue';
import DeepLink from 'models/deep-link';
import TrackingProperties from 'models/tracking-properties';

import * as AppContext from 'lib/app-context';
import * as RoutingStyle from 'lib/routing-style';

export const HUB_TYPE = 'hub';
export const POINT_TYPE = 'point';
export const KEYWORD_TYPE = 'keyword';
export const VENUE_TYPE = 'venue';
export const EVENT_TYPE = 'event';
export const CURRENT_LOCATION_TYPE = 'parking-near-me';

let searchId = uuid();

export default class Search extends Record({
  startTime: moment(),
  endTime: moment(),
  defaultStartTime: moment(),
  defaultEndTime: moment(),
  parkingType: null,
  destinationType: null,
  destination: null,
  msa: null,
  postalCode: null,
  bounds: null,
  lat: null,
  lng: null,
  anchorLat: null,
  anchorLng: null,
  zoomLevel: 15,
  sort: 'closest',
  selectedLocationId: null,
  selectedQuoteId: null,
  mobileOnly: null,
  sellerId: null,
  locationId: null,
  timezone: moment.tz.guess(),
  isParkingNearMeSearch: false,
  pendingGeolocationPermission: false,
  endTimeChanged: false,
  userInput: null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const destMap = {
      hub: Hub,
      venue: Venue,
      event: Event,
    };
    let { destination } = props;
    let { timezone } = destination || {};
    let { destinationType } = props;
    if (!timezone) { ({ timezone } = props); }
    if (!timezone) { timezone = 'America/Chicago'; }
    if (destination === 'current location') {
      destinationType = CURRENT_LOCATION_TYPE;
    }
    const MappedDest = destMap[destinationType];
    if (MappedDest && !(destination instanceof MappedDest)) {
      destination = new MappedDest(destination);
    }

    const defaultStartTime = moment.tz(props.defaultStartTime, timezone);
    const defaultEndTime = moment.tz(props.defaultEndTime, timezone);

    const startTime = props.startTime ? moment.tz(props.startTime, timezone) : defaultStartTime;
    const endTime = props.endTime ? moment.tz(props.endTime, timezone) : defaultEndTime;
    const locationId = props.locationId || props.location_id;

    if (!destinationType && destination) {
      switch (destination.constructor.name) {
        case 'Hub':
          destinationType = HUB_TYPE;
          break;
        case 'Venue':
          destinationType = VENUE_TYPE;
          break;
        case 'Event':
          destinationType = EVENT_TYPE;
          break;
        case 'Point':
          destinationType = POINT_TYPE;
          break;
        default:
          destinationType = KEYWORD_TYPE;
      }
    }

    super({
      parkingType: props.parkingType,
      lat: props.lat,
      lng: props.lng,
      anchorLat: props.anchorLat || props.lat,
      anchorLng: props.anchorLng || props.lng,
      startTime,
      endTime,
      defaultStartTime,
      defaultEndTime,
      timezone,
      destinationType,
      destination,
      msa: props.msa,
      postalCode: props.postalCode,
      bounds: List(Search.boundsWithBackup(props)),
      zoomLevel: props.zoomLevel,
      sort: props.sort,
      selectedLocationId: props.selectedLocationId,
      selectedQuoteId: props.selectedQuoteId,
      mobileOnly: props.mobileOnly,
      sellerId: props.sellerId,
      locationId,
      isParkingNearMeSearch: props.isParkingNearMeSearch,
      pendingGeolocationPermission: props.pendingGeolocationPermission,
    });
  }

  static boundsWithBackup(props) {
    const { lat, lng, bounds } = props;

    if (bounds && bounds.size > 0) { return bounds.toJS(); }

    const latMultiplier = lat >= 0 ? 1 : -1;
    const lngMultiplier = lng >= 0 ? 1 : -1;

    const topLeftPoint = [lat + (0.02 * latMultiplier), lng + (0.02 * lngMultiplier)];
    const bottomRightPoint = [lat - (0.02 * latMultiplier), lng - (0.02 * lngMultiplier)];

    return [topLeftPoint.join(','), bottomRightPoint.join(',')];
  }

  static get DAILY_PARKING_TYPE() { return 'Daily'; }
  static get MONTHLY_PARKING_TYPE() { return 'Monthly'; }
  static get EVENT_PARKING_TYPE() { return 'Event'; }
  static get PACKAGE_PARKING_TYPE() { return 'Package'; }
  static get AIRPORT_PARKING_TYPE() { return 'Airport'; }
  static get PARKING_NEAR_ME_PARKING_TYPE() { return 'parking-near-me'; }
  static get INSIGHTS_DAILY_PARKING_TYPE() { return 'Transient'; }

  /**
   * Returns the event the user is searching.
   * @return {Event/null}
   *         Either the event model if there is one, or null if nothing is found.
   */
  get event() {
    return this.destinationType === EVENT_TYPE ? this.destination : null;
  }

  get isAirportSearch() {
    return this.destinationType === VENUE_TYPE && this.venue.isAirportVenue;
  }

  get isEnhancedAirportSearch() {
    return this.isVenueSearch && this.destination && this.destination.enhancedAirport;
  }

  get isMonthlySearch() {
    return this.parkingType === Search.MONTHLY_PARKING_TYPE;
  }

  get isPackageSearch() {
    return this.parkingType === Search.PACKAGE_PARKING_TYPE;
  }

  get isDailySearch() {
    return this.parkingType === Search.DAILY_PARKING_TYPE || !this.parkingType;
  }

  get isEventSearch() {
    return this.parkingType === Search.EVENT_PARKING_TYPE;
  }

  get isVenueSearch() {
    return this.destinationType === VENUE_TYPE;
  }

  get searchTerm() {
    if (Set([HUB_TYPE, POINT_TYPE, VENUE_TYPE, EVENT_TYPE]).has(this.destinationType)) {
      return this.destination.name;
    }
    return this.destination;
  }

  get searchId() {
    return searchId;
  }

  set searchId(newVal) {
    searchId = newVal;
  }

  brandedMonthlyPhoneNumber(brand) {
    if (brand.isBestParking) {
      return '(888) 462-0085';
    }

    return '(888) 462-0265';
  }

  getSelectedLocation(locations) {
    if (!this.isLocationsObject(locations) || !this.selectedLocationId) { return null; }
    return locations.get(this.selectedLocationId.toString());
  }

  getSelectedQuote(locations) {
    if (!this.isLocationsObject(locations) || !this.selectedLocationId) { return null; }
    const selectedLocation = this.getSelectedLocation(locations);

    let quote = null;
    if (selectedLocation) {
      if (this.selectedQuoteId) {
        quote = selectedLocation.getQuoteById(this.selectedQuoteId);
      }

      if (!quote) {
        quote = selectedLocation.getQuote();
      }
    }

    return quote;
  }

  getDeepLink(brand, trackingParams = {}, trackingProperties = new TrackingProperties(), coupon = null, affiliateId = null, portalAffiliateId = null, admin = false, app = 'Search') {
    let view = '';
    const params = {};

    if (this.sellerId) {
      params.seller_id = this.sellerId.join(',');
    }

    if (this.locationId) {
      params.location_id = this.locationId.join(',');
    }

    if (this.selectedLocationId) {
      view = 'location';
      params.id = this.selectedLocationId;
    } else {
      switch (this.destinationType) {
        case EVENT_TYPE:
          view = 'event';
          params.id = this.destination.id;
          break;
        case VENUE_TYPE:
          view = app === 'Venue' ? 'venue_events' : 'venue';
          params.id = this.destination.id;
          break;
        case HUB_TYPE:
          view = 'city';
          params.name = this.destination.name;
          params.lat = this.anchorLat;
          params.lng = this.anchorLng;
          break;
        default:
          view = 'search';
          params.name = this.destination;
          params.lat = this.anchorLat;
          params.lng = this.anchorLng;
          break;
      }
    }
    return new DeepLink({
      view,
      params,
      trackingParams,
      trackingProperties,
      coupon,
      affiliateId,
      portalAffiliateId,
      admin,
      brand,
    });
  }

  isLocationsObject(locations) {
    return !!(locations && typeof locations.get === 'function');
  }

  toJS() {
    const rawStartTime = this.startTime.format();
    const rawEndTime = this.endTime.format();
    const rawDefaultStartTime = this.defaultStartTime.format();
    const rawDefaultEndTime = this.defaultEndTime.format();

    return (
      Object.assign(super.toJS(), {
        startTime: rawStartTime,
        endTime: rawEndTime,
        defaultStartTime: rawDefaultStartTime,
        defaultEndTime: rawDefaultEndTime,
      })
    );
  }

  toJSON() {
    const obj = this.toJS();

    /**
     * Venues have a lot of information
     * We need to cut out the info we don't care about
     * Otherwise, we cannot write to the cookie
     */
    if (obj.destinationType === VENUE_TYPE) {
      const attributes = ['id', 'name', 'address', 'city', 'state', 'postalCode', 'enhancedAirport', 'url', 'lat', 'lng', 'packageAvailability', 'transientAvailability', 'searchSuggestions'];
      const venue = obj.destination;
      Object.keys(venue).forEach((key) => {
        if (attributes.indexOf(key) === -1) {
          delete venue[key];
        }
      });
      obj.destination = venue;
    }

    return JSON.stringify(obj);
  }

  isDirty(otherSearch) {
    const timeChanged = (!!otherSearch.startTime.diff(this.startTime) || !!otherSearch.endTime.diff(this.endTime));
    const typeChanged = (otherSearch.parkingType !== this.parkingType);
    const destinationTypeChange = otherSearch.destinationType !== this.destinationType;
    const latLngChanged = (this.destinationType === Search.PARKING_NEAR_ME_PARKING_TYPE)
      && ((otherSearch.anchorLat !== this.anchorLat) || (otherSearch.anchorLng !== this.anchorLng));

    let destinationChanged = false;
    if (
      (this.destination && !otherSearch.destination) ||
      (!this.destination && otherSearch.destination) ||
      (!this.selectedLocationId && otherSearch.selectedLocationId)
    ) {
      destinationChanged = true;
    } else if (this.destination && otherSearch.destination) {
      if (this.destination.id && otherSearch.destination.id && this.destinationType !== POINT_TYPE) {
        destinationChanged = this.destination.id.toString() !== otherSearch.destination.id.toString();
      } else if (this.destination.id !== otherSearch.destination.id) {
        destinationChanged = true;
      } else if (
        this.destination.lat !== otherSearch.destination.lat ||
        this.destination.lng !== otherSearch.destination.lng
      ) {
        destinationChanged = true;
      }
    }

    return timeChanged || typeChanged || destinationTypeChange || destinationChanged || latLngChanged;
  }

  route(
    location,
    displayMap = true,
    appContext = AppContext.DESKTOP,
    {
      eventPackageId,
      eventPackageIds,
      routingStyle = RoutingStyle.PARKWHIZ,
      forceMonthly = null,
    } = {},
  ) {
    const { destination, destinationType, isMonthlySearch: monthly } = this;
    const params = {};
    let newRoute = '';

    if (appContext === AppContext.MOBILE) params.view = displayMap ? 'map' : 'list';

    if (this.parkingType === 'Package') {
      if (location && destinationType === VENUE_TYPE) {
        // PW-style location URLs don't come back with a trailing slash,
        // but BP-style ones do. So we adjust for that here.
        let slash = '';
        if (routingStyle === RoutingStyle.PARKWHIZ) { slash = '/'; }

        return `${location.url}${slash}?event_package_id=${eventPackageId}&venue_id=${destination.id}`;
      }

      if (eventPackageIds && eventPackageIds.size) {
        return `${destination.url}packages/?event_package_ids=${eventPackageIds.join(',')}`;
      }

      return `${destination.url}packages/`;
    } else if ((monthly && forceMonthly !== false) || forceMonthly === true) {
      if (destination instanceof Hub) {
        if (routingStyle === RoutingStyle.BESTPARKING) {
          return `${destination.url}monthly-parking/`;
        }

        if (get(destination, 'url', false)) {
          return destination.url.replace(/-parking(-)*(\d)*\/map\//, '-monthly-parking/');
        }
        return null;
      }
      params.monthly = 1;
    } else if (this.startTime.diff(this.defaultStartTime) !== 0 || this.endTime.diff(this.defaultEndTime) !== 0) {
      if (this.timezone) {
        // Make sure that the times are scoped in the correct timezone
        // for the location, otherwise search is going to potentially
        // bomb by sending GMT times to search (still works, user just
        // sees a time error)
        params.start = moment(this.startTime).tz(this.timezone).format();
        params.end = moment(this.endTime).tz(this.timezone).format();
      } else {
        // We could not extract a timezone.  Take what we have and hope
        // for the best.
        params.start = this.startTime.format();
        params.end = this.endTime.format();
      }
    }

    // URL IS A RELATIVE URL
    if (location) {
      // UNTESTED!!
      newRoute = location.url || '';
      if (destinationType === EVENT_TYPE) {
        params.event_id = destination.id;
      } else if (destinationType === VENUE_TYPE) {
        params.venue_id = destination.id;
      }
    } else if (this.destinationType === Search.PARKING_NEAR_ME_PARKING_TYPE) {
      newRoute = '/parking-near-me/';
      Object.assign(params, {
        lat: this.lat,
        lng: this.lng,
      });
    } else if (destinationType === EVENT_TYPE) {
      newRoute = destination.url || '';
    } else if (destinationType === POINT_TYPE) {
      // We need to extract lat & lng from the URL and add them to params,
      // remove from querystring here and will get placed back when query is assigned below
      newRoute = destination.url.split('?').shift();
      params.lat = destination.lat;
      params.lng = destination.lng;
    } else if (destinationType === VENUE_TYPE) {
      newRoute = destination.url || '';
      if (!params.monthly && !destination.enhancedAirport) {
        params.daily = 1;
      }
    } else if (destinationType === HUB_TYPE) {
      newRoute = destination.url || '';
    } else if (get(destination, 'gplaces_id', null)) {
      // @DEPRECATED - we should no longer get these from search
      params.gplaces_placeid = destination.get('gplaces_id');
      newRoute = '/search/';
    } else {
      // @DEPRECATED - we should no longer get these from search
      if (destination && destination.replace) {
        newRoute = `/destination/${destination.replace(/\s/g, '-')}`;
      }
      Object.assign(params, {
        lat: this.lat,
        lng: this.lng,
      });
    }

    if (this.sellerId) { params.seller_id = this.sellerId; }
    if (this.locationId) { params.location_id = this.locationId; }

    if (Object.keys(params).length === 0) {
      return newRoute;
    }
    if (!newRoute.endsWith('/')) { newRoute = newRoute.concat('/'); }
    return `${newRoute}?${this.buildQueryParams(params)}`;
  }

  getDestinationType() {
    if (this.destination instanceof Event) { return EVENT_TYPE; }
    if (this.parkingType === 'Package' || this.destination instanceof Venue) {
      return VENUE_TYPE;
    }
    return null;
  }

  apiTimeFormat(time) {
    if (!time || !time.format) { return null; }
    return time.format('YYYY-MM-DDTHH:mm:ss');
  }

  apiCoordinatesFormat(lat, lng) {
    return `${lat},${lng}`;
  }

  buildQueryParams(params) {
    // If nothing was sent in or an empty length set then return an empty string
    if (!params || Object.keys(params).length === 0) {
      return '';
    }

    // Build out a k=v string with & separator
    return Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');
  }

  params({ searchType = 'coordinates', user = new User(), options = {}, eventPackageIds = new List(), eventPackageId, nonBookableRules = { display: false } } = {}) {
    const query = {
      anchorCoordinates: this.apiCoordinatesFormat(this.anchorLat, this.anchorLng),
      sellerId: this.sellerId,
      locationId: this.locationId,
    };
    let returns = 'curated';
    if (this.parkingType !== 'Package') {
      returns = `${returns} offstreet_bookable_sold_out`;
    }
    if (this.mobileOnly) {
      returns = `${returns} offstreet_bookable_mobile`;
    } else {
      returns = `${returns} offstreet_bookable`;
    }
    if (nonBookableRules.display) {
      returns = `${returns} offstreet_non_bookable`;
    }
    const params = {
      startTime: this.apiTimeFormat(this.startTime),
      endTime: this.apiTimeFormat(this.endTime),
      email: user.email,
      fields: 'quote::default,quote:shuttle_times,location::default,location:timezone,location:site_url,location:address2,location:description,location:msa,location:rating_summary',
      optionTypes: 'all',
      returns,
    };

    if (nonBookableRules.display) {
      params.fields = `${params.fields},location:hours`;
    }

    switch (this.parkingType) {
      case 'Monthly':
        query.searchType = 'monthly';
        break;
      case 'Package':
        query.searchType = 'event_package';
        if (eventPackageIds && eventPackageIds.size > 0) {
          query.eventPackageId = eventPackageIds.join(',');
        } else if (eventPackageId) {
          query.eventPackageId = eventPackageId;
        }
        break;
      case 'Daily':
      default:
        if (this.destination && this.destination.enhancedAirport) {
          query.searchType = 'airport';
        } else {
          query.searchType = 'transient';
        }
        break;
    }

    if (this.selectedLocationId) {
      searchType = 'location'; // eslint-disable-line no-param-reassign
    }

    switch (searchType) {
      case 'location':
        query.locationId = this.selectedLocationId;
        break;
      case 'bounds':
        query.bounds = Search.boundsWithBackup(this);
        break;
      case 'coordinates':
      default: {
        const coords = options ? options.coordinates : null;
        if (coords && Array.isArray(coords)) {
          query.coordinates = this.apiCoordinatesFormat(...coords);
        } else {
          query.coordinates = query.anchorCoordinates;
        }
        break;
      }
    }

    switch (this.getDestinationType()) {
      case VENUE_TYPE:
        query.venueId = this.destination.id;
        delete query.coordinates;
        query.bounds = Search.boundsWithBackup(this);
        break;
      case EVENT_TYPE:
        query.eventId = this.destination.id;
        delete query.coordinates;
        query.bounds = Search.boundsWithBackup(this);
        break;
      default:
        break;
    }

    return { query, params };
  }

  get monthly() {
    return this.set('parkingType', Search.MONTHLY_PARKING_TYPE);
  }

  get daily() {
    return this.set('parkingType', Search.DAILY_PARKING_TYPE);
  }

  get airport() {
    return this.set('parkingType', Search.AIRPORT_PARKING_TYPE);
  }

  get title() {
    const parkingNoun = this.isMonthlySearch ? 'Monthly Parking' : 'Parking';

    switch (this.destinationType) {
      case EVENT_TYPE:
        return `${this.destination.name} Parking - ${this.destination.startTime.format('MMM D')}`;
      case VENUE_TYPE:
        return `${this.destination.name} ${parkingNoun} - Find ${parkingNoun} near ${this.destination.name}`;
      case HUB_TYPE:
        if (this.destination.title) {
          return this.destination.title + (this.isMonthlySearch ? ' - Monthly Parking' : '');
        }
        return `${this.destination.name} ${parkingNoun} - Find Parking`;
      case CURRENT_LOCATION_TYPE:
        return `${parkingNoun} near your Current Location`;
      case POINT_TYPE: {
        let destinationName;
        if (typeof this.destination === 'string') {
          destinationName = this.destination;
        } else {
          destinationName = this.destination.name;
        }
        return `${parkingNoun} Near ${destinationName} - Find Parking`;
      }
      default:
        return `${parkingNoun} Near ${this.destination}`;
    }
  }

  get boundLimits() {
    const bounds = Search.boundsWithBackup(this);
    const lats = bounds.map(b => parseFloat(b.split(',')[0]));
    const lngs = bounds.map(b => parseFloat(b.split(',')[1]));

    return Map({
      latMax: max(lats),
      latMin: min(lats),
      lngMax: max(lngs),
      lngMin: min(lngs),
    });
  }

  /*
   * Rules for Default Times:
   * 1) Always Round Down
   * 2) Times are always in half hour increments
   * 3) Should be a 2 hour gap between start and end
   */
  static getDefaultTimes(time, timezone = moment.tz.guess(), { airport, searchSuggestions } = { airport: false }) {
    const baseTime = moment(time);
    baseTime.set('second', 0);
    if (baseTime.get('minute') >= 30) {
      baseTime.set('minute', 30);
    } else {
      baseTime.set('minute', 0);
    }

    let startTime = baseTime.clone();
    let endTime = baseTime.clone();

    startTime = startTime.tz(timezone);
    if (airport) {
      endTime = endTime.add(24, 'hours').tz(timezone);
    } else {
      endTime = endTime.add(2, 'hours').tz(timezone);
    }

    if (get(searchSuggestions, 'hasSuggestions', false)) {
      const { minimumStartTimeBufferSeconds, minimumDurationSeconds } = searchSuggestions;
      let duration = endTime.diff(startTime, 'seconds');

      if (minimumStartTimeBufferSeconds) {
        startTime = startTime.add(minimumStartTimeBufferSeconds, 'seconds');
      }

      if (minimumDurationSeconds && duration < minimumDurationSeconds) {
        duration = minimumDurationSeconds;
      }

      endTime = startTime.clone().add(duration, 'seconds');
    }

    return { startTime, endTime };
  }

  get insightsParkingType() {
    const { parkingType } = this;
    if (parkingType === Search.DAILY_PARKING_TYPE) {
      return Search.INSIGHTS_DAILY_PARKING_TYPE;
    }
    return parkingType;
  }

  // we are currently able to correctly identify the search category for venues, hubs, and events
  // for now, we will only identify those and return "Other" for the rest
  get insightsSearchCategory() {
    const { destinationType } = this;
    if ([VENUE_TYPE, HUB_TYPE, EVENT_TYPE].includes(destinationType)) {
      return startCase(destinationType);
    }
    return 'Other';
  }

  clearSelection() {
    return this.merge({ selectedLocationId: null, selectedQuoteId: null });
  }
}
