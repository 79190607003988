import { Record, List, Map, Set } from 'immutable';

// Define const names for display options, to prevent typos.
// This pattern lifted from exported action names in redux.
export const PASS_VALUE_METHOD = 'pass_value';
export const GATE_BUTTON_METHOD = 'gate_button';
export const LICENSE_PLATE_METHOD = 'license_plate';
export const PASS_NUMBER_METHOD = 'pass_number';
export const PRICING_CODE_METHOD = 'pricing_codes';
export const SCAN_CODE_METHOD = 'scan_code';
export const PRINTED_PASS_METHOD = 'print_instructions';
export const METHODS = List([
  PASS_VALUE_METHOD,
  GATE_BUTTON_METHOD,
  LICENSE_PLATE_METHOD,
  PASS_NUMBER_METHOD,
  PRICING_CODE_METHOD,
  SCAN_CODE_METHOD,
  PRINTED_PASS_METHOD,
]);

export const BLE_FLASH = 'ble_flash';

export class ValidationStep extends Record({
  instructions: null,
  icon: null,
}) {
  constructor(props) {
    super({
      instructions: props.instructions,
      icon: props.icon ? props.icon.path : null,
    });
  }
}

export default class Validation extends Record({
  display: null,
  displayMessage: null,
  gateTechnologies: List(),
  requireLicensePlate: null,
  scanCode: null,
  steps: null,
  validationColors: null,
}) {
  constructor(props) {
    const stepProps = props.validation_steps || props.steps;
    const steps = stepProps ? List(stepProps.map(step => new ValidationStep(step))) : null;

    super({
      display: Map(props.display),
      displayMessage: props.display_message,
      gateTechnologies: List(props.gate_technologies),
      requireLicensePlate: props.require_license_plate,
      scanCode: Map(props.scan_code),
      steps,
      validationColors: props.validation_colors,
    });
  }

  get requiredMethods() {
    const { display } = this;
    return Set(METHODS.filter(m => display.get(m) === 'required'));
  }

  get optionalMethods() {
    const { display } = this;
    return Set(METHODS.filter(m => display.get(m) === 'optional'));
  }
}
