import superagent from 'superagent';
import isEmpty from 'lodash/isEmpty';
import { Promise } from 'es6-promise';
import { PAGE_BRAND_FIELDS } from 'models/brand';

import env, { isProduction } from 'lib/env';

const httpAuthUser = env().HTTP_AUTH_USER;

function makePromise(request) {
  const TIMEOUT = 30000;

  return new Promise((resolve, reject) => {
    request
      .set('Accept', 'application/json')
      .timeout(TIMEOUT)
      .end((error, response) => {
        if (error) {
          if (!isProduction()) {
            console.warn({ request, response });
          }
          reject({ error, response });
        } else {
          const body = response.body;
          resolve({
            body,
            response,
          });
        }
      });
  });
}

export function get({ url, data, accessToken, httpAuth, headers, bypassLocaleHeader = false, locale: localeParam }) {
  const params = data || {};
  const request = superagent.get(url).query(params);

  if (!bypassLocaleHeader) {
    const locale = localeParam || env().CURRENT_LOCALE || 'en-us';
    request.set('X-Language-Locale', locale);
  }

  if (accessToken) {
    request.set('Authorization', `Bearer ${accessToken}`);
  }

  if (httpAuth && httpAuthUser) {
    request.auth(`${httpAuthUser}`, '');
  }

  if (headers) {
    Object.keys(headers).forEach(headerName => request.set(headerName, headers[headerName]));
  }

  // request.type('text/plain');
  return [request, makePromise(request)];
}

export function post({ url, data, accessToken, type, headers, bypassLocaleHeader = false, locale: localeParam }) {
  const params = data || {};
  const request = superagent.post(url).send(params);
  const postType = type || 'json';

  if (!bypassLocaleHeader) {
    const locale = localeParam || env().CURRENT_LOCALE || 'en-us';
    request.set('X-Language-Locale', locale);
  }

  if (accessToken) {
    request.set('Authorization', `Bearer ${accessToken}`);
  }

  if (headers) {
    Object.keys(headers).forEach(headerName => request.set(headerName, headers[headerName]));
  }

  request.type(postType);
  // request.type('application/x-www-form-urlencoded');
  // request.set('Access-Control-Allow-Methods','GET, POST, PUT, DELETE, OPTIONS');

  return [request, makePromise(request)];
}

export function put({ url, data, accessToken, type, headers, locale: localeParam }) {
  const params = data || {};
  const request = superagent.put(url).send(params);
  const postType = type || 'json';

  const locale = localeParam || env().CURRENT_LOCALE || 'en-us';
  request.set('X-Language-Locale', locale);

  if (accessToken) {
    request.set('Authorization', `Bearer ${accessToken}`);
  }

  request.type(postType);

  return [request, makePromise(request)];
}

export function del({ url, data, accessToken, type, headers, locale: localeParam }) {
  const params = data || {};
  const request = superagent.delete(url).send(params);
  const postType = type || 'json';

  const locale = localeParam || env().CURRENT_LOCALE || 'en-us';
  request.set('X-Language-Locale', locale);

  if (accessToken) {
    request.set('Authorization', `Bearer ${accessToken}`);
  }

  request.type(postType);

  return [request, makePromise(request)];
}

export function generateQueryString(q) {
  if (isEmpty(q)) { return null; }
  let baseUrl = '';
  for (const key of Object.keys(q)) {
    if (Array.isArray(q[key])) { q[key] = q[key].join(','); }
    baseUrl += `${key}:${q[key]} `;
  }
  return baseUrl.trim();
}

export const BOOKING_CANCELLABLE_FIELDS = [
  'booking::default',
  'booking:authorization_code',
  // TODO: Remove booking:cancellable once cancellable_status integrated into Booking Cancellation logic
  'booking:cancellable',
  'booking:cancellable_status',
  'booking:parking_pass_web_url',
  'booking:times_tbd',
];

export const BOOKING_FIELDS = [
  'booking:package_authorization_code',
  'booking:order_id',
  'booking:package',
  'booking:event_id',
  'booking:full_name',
  'booking:pass_value',
  'add_on:name',
  'add_on:type',
  'add_on:items',
  'add_on:booking_id',
  'event:name',
  'event:id',
  'event:venue_id',
  'seller::default',
  'seller:logo',
  'location::default',
  'location:timezone',
  'location:monthly_iframe_uri',
  'location:seller_logo',
  'location:display_seller_logo',
  'location:directions',
  'location:supports_booking_extension',
  'partner::default',
  'parking_pass::default',
  'parking_pass:customer_id',
  'parking_pass:display_cancel_link',
  'partner:marketing_eligible',
  'parking_pass:shuttle_times',
  'parking_pass:pickup_instructions',
  'parking_pass:dropoff_instructions',
  'venue:id',
  'venue:name',
  'venue:enhanced_airport',
  'customer:first_name',
  'customer:last_name',
  'customer:account_activated',
  `${PAGE_BRAND_FIELDS}`, // TODO: Concat instead of stick a big string in here?
].concat(BOOKING_CANCELLABLE_FIELDS).join(',');

export const SLUG_FIELDS = [
  'location::default',
  'location:url',
  'location:timezone',
  'location:description',
  'hub::default',
  'hub:city',
  'hub:state',
  'hub:country',
  'hub:type',
  'hub:site_url',
  'hub:postal_code',
  'hub:availability',
  'hub:seo_meta',
  'hub:timezone',
  'hub:hub_site_url',
  'hub:map_site_url',
].join(',');
