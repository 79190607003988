import moment from 'moment-timezone';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { Record, OrderedSet, Map, fromJS } from 'immutable';

import isEmailValid from 'lib/checkout/isEmailValid';
import env from 'lib/env';

const { ENTERPRISE_HOST, ENTERPRISE_TS_PADDING, RUBYAPP_SECRET } = env();
const displayNames = {
  firstname: 'first name',
  lastname: 'last name',
  email: 'email address',
  phone: 'phone number',
};

export default class User extends Record({
  id: null,
  email: '',
  firstname: '',
  lastname: '',
  fullName: '',
  phone: '',
  remembercs: null,
  token: null,
  admin: false,
  seller: false,
  affiliate: false,
  validated: false,
  isBusinessAdmin: false,
  jwt: null,
  referralCode: null,
  reseller: false,
  bookingCount: '',
  bookingCounts: Map(),
  privatePricingCount: 0,
  authorizedServices: OrderedSet(),
  accountActivated: false,
  feedbackReminder: false,
  marketingAllowed: false,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const {
      id,
      email,
      phone_number: phoneNumber,
      name,
      api_key: remembercs,
      token,
      jwt,
      referral_code: referralCode,
      validated,
      is_reseller: isReseller,
      bookingCount,
      account_type: accountType = {},
      booking_counts: bookingCounts,
      privatePricingCount,
    } = props;

    let {
      first_name: firstname,
      last_name: lastname,
    } = props;

    let authorizedServices = props._embedded && props._embedded['pw:authorized_services'];
    if (authorizedServices) {
      authorizedServices = authorizedServices.map(s => s.provider);
    }

    if (!firstname) {
      ({ firstname = '' } = props);
    }
    if (!lastname) {
      ({ lastname = '' } = props);
    }
    const accountActivated = props.account_activated;

    super({
      id,
      email: email || '',
      firstname,
      lastname,
      fullName: name || (firstname && lastname ? `${firstname} ${lastname}` : ''),
      phone: User.formatPhoneNumber(phoneNumber) || '',
      remembercs,
      token,
      admin: Boolean(accountType.admin),
      seller: !!accountType.seller,
      affiliate: !!accountType.legacy_affiliate,
      validated: !!validated,
      isBusinessAdmin: !!accountType.business_admin,
      jwt,
      referralCode,
      reseller: !!isReseller,
      authorizedServices: OrderedSet(authorizedServices),
      bookingCounts: fromJS(bookingCounts),
      bookingCount,
      privatePricingCount,
      accountActivated,
      feedbackReminder: !!props.feedback_reminder,
      marketingAllowed: props.marketing_allowed,
    });
  }

  get name() {
    const { fullName, firstname, lastname } = this;
    return fullName || `${firstname || ''} ${lastname || ''}`.trim();
  }

  get businessAdminURL() {
    const expirationTime = moment().add(ENTERPRISE_TS_PADDING, 'seconds');
    const hmac = hmacSHA256(`${this.id}-${expirationTime}`, RUBYAPP_SECRET);

    let url = `${ENTERPRISE_HOST}/sso/`;
    url += `?user_id=${this.id}`;
    url += `&expiration_time=${expirationTime}`;
    url += `&hmac=${hmac}`;

    return url;
  }

  isFirstnameValid() {
    return !!(this.firstname && typeof this.firstname === 'string');
  }

  isFirstnameEmpty() {
    return !this.firstname;
  }

  isLastnameValid() {
    return !!(this.lastname && typeof this.lastname === 'string');
  }

  isLastnameEmpty() {
    return !this.lastname;
  }

  isEmailValid() {
    return this.email && isEmailValid(this.email);
  }

  isEmailEmpty() {
    return !this.email;
  }

  isEmpty(prop) {
    return !!(prop && typeof prop === 'string');
  }

  isLoggedIn() {
    return !!this.id;
  }

  validateFields() {
    return {
      email: {
        isValid: this.isEmailValid(),
        isEmpty: this.isEmailEmpty(),
        displayName: displayNames.email,
      },
      firstname: {
        isValid: this.isFirstnameValid(),
        isEmpty: this.isFirstnameEmpty(),
        displayName: displayNames.firstname,
      },
      lastname: {
        isValid: this.isLastnameValid(),
        isEmpty: this.isLastnameEmpty(),
        displayName: displayNames.lastname,
      },
    };
  }

  static formatPhoneNumber(number) {
    if (!number) { return ''; }
    return `${number}`.replace(/\D/g, '');
  }
}
