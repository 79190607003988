import { Map, Record } from 'immutable';

const featureFlagDefaults = {
  name: null,
  slug: null,
  platforms: {},
};

class FeatureFlag extends Record(featureFlagDefaults) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { name, slug } = props;
    const platforms = new Map(props.platforms);

    super({ name, slug, platforms });
  }

  activeFor(platform) {
    return this.platforms.get(platform);
  }
}

export default class FeatureFlags extends Record({ flags: {} }) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let flags = new Map();
    if (props && props.forEach) {
      props.forEach((featureFlag) => { flags = flags.set(featureFlag.slug, new FeatureFlag(featureFlag)); });
    }
    super({ flags });
  }

  desktopActivated(slug) {
    const featureFlag = this.flags.get(slug);
    return featureFlag && featureFlag.activeFor('desktop-web');
  }

  mobileActivated(slug) {
    const featureFlag = this.flags.get(slug);
    return featureFlag && featureFlag.activeFor('mobile-web');
  }
}
