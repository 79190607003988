import { CurrencySymbol } from 'components/common/currency-symbol';

export const eventExpiredMessage = {
  message: 'Event Expired',
  details: 'Off-roading! This event already happened. Find parking for upcoming events',
  type: 'warning',
  id: 'pw-messages-expired-event',
};

export const somethingWentWrongMessage = {
  message: 'Aw Shucks',
  details: 'Something went wrong. Please try searching again to book your space.',
};

export const locationUnavailableMessage = {
  id: 'pw-messages-location-unavailable',
  message: 'Aw Shucks!',
  details: 'This location isn\'t available for the time you selected',
  type: 'danger',
};

export const parkingPassExpiredMessage = {
  id: 'pw-messages-pass-expired',
  message: 'Aw Shucks!',
  details: 'This parking pass is expired.&nbsp;&nbsp;&nbsp;&nbsp;<a href="/account/"><strong>VIEW MY ACCOUNT</strong></a>',
  type: 'danger',
};

export const vendgateErrorMessage = {
  id: 'pw-messages-vendgate-error',
  message: 'Let\'s get personal!',
  details: 'Vendgate\'s not working right now, but our speedy customer service team will help you out and get you on your way. Please call <a href="tel:888-472-7594">888-472-7594</a>',
  type: 'danger',
};

export const vendgateEarlyMessage = {
  id: 'pw-messages-vendgate-early',
  message: 'OK, Speedy!',
  details: 'You\'re early. Please come back to open the gate within an hour of your parking start time.',
  type: 'warning',
};

export const ticketmasterEarlyMessage = {
  id: 'pw-messages-ticketmaster-early',
  message: 'Nearly there!',
  details: 'We\'re still processing that Ticketmaster booking. Please try again in a few minutes.',
  type: 'warning',
};

export const somethingWentWrongPassMessage = {
  message: 'Aw Shucks',
  details: 'Something went wrong. Please try <a href="/account">signing in</a> to find your parking pass.',
};

export const unsupportedBrowserMessage = {
  message: 'Traffic jam!',
  details: 'You\'re using an outdated browser. We can get you where you need to go so much faster if you <a href=\'http://browsehappy.com/?locale=en\' target=\'_new\'>upgrade it</a>.',
  type: 'warning',
  id: 'pw-message-unsupported-browser-warning-1',
};

export const paymentMethodCreationSuccess = {
  id: 'pw-messages-payment-save-success',
  message: 'Right On!',
  details: 'Your payment method has been saved.',
  type: 'success',
};

export const paymentMethodCreationError = {
  id: 'pw-messages-payment-save-error',
  message: 'Aw Shucks!',
  details: 'There was a problem saving your credit card.',
  type: 'danger',
};

export const paymentTokenizationFailureMessage = {
  id: 'pw-messages-payment-tokenization-failure',
  message: 'Aw Shucks!',
  details: 'There was an error processing your payment',
  type: 'danger',
};

export const duplicateBusinessProfileError = {
  id: 'pw-messages-duplicate-business-profile',
  message: 'Aw Shucks!',
  details: 'It looks like you already have a business profile! Please try editing or deleting your profile to proceed.',
  type: 'danger',
};

export const businessProfileLinkPending = {
  id: 'pw-messages-concur-link-success',
  message: 'Right On!',
  details: 'Your link to Concur is being processed.',
  type: 'success',
};

export const businessProfileCreationSuccess = {
  id: 'pw-messages-business-profile-save-success',
  message: 'Right On!',
  details: 'Your business profile has been saved.',
  type: 'success',
};

export const concurUnlinkSuccess = {
  id: 'pw-messages-concur-unlink-success',
  message: 'Right On!',
  details: 'Your Concur profile has been unlinked.',
  type: 'success',
};

export const concurUnlinkError = {
  id: 'pw-messages-concur-unlink-error',
  message: 'Aw Shucks!',
  details: 'There was a problem unlinking your Concur profile.',
  type: 'danger',
};

export const expensifyUnlinkSuccess = {
  id: 'pw-messages-expensify-unlink-success',
  message: 'Right On!',
  details: 'Your Expensify profile has been unlinked.',
  type: 'success',
};

export const expensifyUnlinkError = {
  id: 'pw-messages-expensify-unlink-error',
  message: 'Aw Shucks!',
  details: 'There was a problem unlinking your Expensify profile.',
  type: 'danger',
};

export const expensifyLinkSuccess = {
  id: 'pw-messages-expensify-link-success',
  message: 'Right On!',
  details: 'Your Expensify profile has been linked.',
  type: 'success',
};

export const expensifyLinkError = {
  id: 'pw-messages-expensify-link-error',
  message: 'Aw Shucks!',
  details: 'There was a problem linking your Expensify profile.',
  type: 'danger',
};
export const surpassedStartTimeCannotCancel = {
  id: 'pw-messages-surpassed-start-time-cannot-cancel-error',
  message: 'Heads Up!',
  details: 'All parking must be cancelled before the start of the parking period. It is too late to cancel this booking.',
  type: 'danger',
};

export const notLoggedInCannotCancel = {
  id: 'pw-messages-not-logged-in-cannot-cancel-error',
  message: 'Whoops!',
  details: 'You must be logged in to cancel your booking.',
  type: 'danger',
};

export const packageCannotCancel = {
  id: 'pw-messages-package-cannot-cancel-error',
  message: 'Whoops!',
  details: 'Parking purchased as part of a parking package is non-refundable.',
  type: 'danger',
};

export const monthlyCannotCancel = {
  id: 'pw-messages-monthly-cannot-cancel-error',
  message: 'Hang on!',
  details: 'For help cancelling monthly parking, please call us at (888)&nbsp;472&#8209;7594.',
  type: 'danger',
};

export const denyGeolocationPermission = {
  id: 'pw-messages-deny-geolocation-permission',
  message: 'Going incognito?',
  details: 'No worries. Search by address, place, or event, and get parking!',
  type: 'success',
};

export const invalidTimeChangeWarning = {
  id: 'pw-messages-invalide-time-change',
  message: 'Sorry, that time has come and gone.',
  details: 'Select an upcoming time instead.',
  type: 'warning',
};

export const invalidStartTimeChangeWarning = {
  id: 'pw-messages-invalid-start-time-change',
  message: 'Edit your start time:',
  details: "Start time can't be more than 30 min ago.",
  type: 'warning',
};

export const invalidEndTimeChangeWarning = {
  id: 'pw-messages-invalid-end-time-change',
  message: 'Check your end time:',
  details: 'End time must be later than start time.',
  type: 'warning',
};

export const businessEnrollSuccess = businessURL => ({
  id: 'pw-messages-business-success',
  message: "Congratulations, you're all set!",
  details: `Your company is now enrolled at ParkWhiz for Business. <a href="${businessURL}">Please continue on to your portal to set up your payment method.</a>`,
  type: 'success',
});

export const businessEnrollError = error => ({
  id: 'pw-messages-business-error',
  message: 'There was a problem with your enrollment.',
  details: error,
  type: 'danger',
});

export const businessEnrollIncompleteError = {
  id: 'pw-messages-business-enroll-incomplete-error',
  message: 'Hang on!',
  details: 'Please complete the enrollment form before continuing.',
  type: 'danger',
};

export const businessEnrollTermsError = {
  id: 'pw-messages-business-enroll-terms-error',
  message: 'Hang on!',
  details: 'Please accept the Terms of Use to continue.',
  type: 'danger',
};

export const enterpriseInviteSuccess = {
  id: 'pw-messages-pw4b-accept-invite-success',
  message: 'Your account is linked!',
  details: 'Now you can bill your company directly for work-related parking.',
  type: 'success',
  persistent: true,
};

export const enterpriseInviteError = {
  id: 'pw-messages-pw4b-accept-invite-error',
  message: 'Rats, that didn\'t work.',
  details: 'Your account didn\'t link. Please email <a href="mailto:help@parkwhiz.com">help@parkwhiz.com</a>.',
  type: 'danger',
  persistent: true,
};

export const passwordResetSuccess = {
  id: 'pw-messages-reset-password-confirmation',
  message: "We've sent you a link to create a new password. Check your email.",
  details: '',
  type: 'success',
};

export const passwordResetError = {
  id: 'pw-messages-reset-password-failure',
  message: "Wait up! We don't show an account by that name.",
  details: "<a href='/account/signup'>Sign up now.</a>",
  type: 'danger',
};

export const passwordRecoverySuccess = {
  id: 'pw-messages-recover-password-confirmation',
  message: 'Your new password has been saved!',
  details: '',
  type: 'success',
  persistent: true,
};

export const passwordRecoveryError = {
  id: 'pw-messages-recover-password-failure',
  message: 'There was a problem resetting your password.',
  details: "Please try <a href='/account/''>signing up</a>.",
  type: 'danger',
};

export const appTextSuccess = {
  id: 'pw-app-text-success',
  message: 'Success!',
  details: 'You should receive a text shortly.',
  type: 'success',
};

export const appTextError = {
  id: 'pw-app-text-error',
  message: 'Aww shucks.',
  details: 'Please try again',
  type: 'danger',
};

export const genericError = {
  message: 'Aw Shucks',
  details: 'Something went wrong. Please refresh the page to try again .',
  type: 'danger',
};

export const checkoutSuccess = ({ bookingId }) => ({
  id: `pw-checkout-success-${bookingId}`,
  message: 'Easy Street!',
  details: "You've got a confirmed booking.",
  type: 'success',
});

export const userUpdateError = {
  id: 'pw-user-update-error',
  message: 'There was a problem updating your account.',
  details: 'Please check your account information.',
  type: 'danger',
};

export const userUpdateSuccess = {
  id: 'pw-user-update-success',
  message: 'Right On!',
  details: 'Your account information has been saved.',
  type: 'success',
};

export const bookingNotFoundMessage = ({ brand }) => ({
  id: 'pw-booking-not-found',
  message: 'Aw Shucks, This Booking Cannot Be Found.',
  details: `If you need help, please contact <a href='mailto:${brand.helpEmail}'>${brand.helpEmail}</a>.`,
  type: 'danger',
  messageValues: {
    helpEmail: brand.helpEmail,
  },
});

export const bookingCancelUnauthorizedMessage = ({ brand }) => ({
  id: 'pw-booking-cancel-unauthorized',
  message: 'Aw Shucks, You Do Not Have Permission to Cancel This Booking.',
  details: `If you need help, please contact <a href='mailto:${brand.helpEmail}'>${brand.helpEmail}</a>.`,
  type: 'danger',
  messageValues: {
    helpEmail: brand.helpEmail,
  },
});

export const bookingNotCancellableMessage = ({ brand, message }) => ({
  id: 'pw-cannot-cancel',
  message: "Aw Shucks, This Booking Can't be cancelled.",
  details: message || `If you need help, please contact <a href='mailto:${brand.helpEmail}'>${brand.helpEmail}</a>.`,
  type: 'danger',
  customMessage: !!message,
  messageValues: {
    helpEmail: brand.helpEmail,
  },
});

export const bookingCancelSuccessMessage = ({ booking, location, locale }) => {
  const currencySymbol = CurrencySymbol({
    location,
    intl: { locale },
  });
  return ({
    id: 'pw-booking-cancelled',
    message: 'In Motion!',
    details: `Your booking #${booking.id} has been canceled and $${booking.getFormattedPrice('pricePaid')} has been refunded, allow up to 5 days to process.`,
    type: 'success',
    messageValues: {
      bookingID: booking.id.toString(),
      bookingPricePaid: `${currencySymbol}${booking.getFormattedPrice('pricePaid')}`,
    },
  });
};

export const vehicleSaveError = {
  id: 'pw-vehicle-save-error',
  message: 'Aw Shucks, There Was a Problem Saving Your Vehicle.',
  details: 'Please try again.',
  type: 'danger',
};

export const vehicleSaveSuccess = {
  id: 'pw-vehicle-save-success',
  message: 'Easy Street!',
  details: 'Your vehicle has been saved.',
  type: 'success',
};

export const vehicleDeleteError = {
  id: 'pw-vehicle-delete-error',
  message: 'Aw Shucks, There Was a Problem Removing Your Vehicle.',
  details: 'Please try again.',
  type: 'danger',
};

export const createReviewError = {
  id: 'pw-create-review-error',
  message: 'Aw Shucks, There Was a Problem Submitting Your Feedback.',
  details: 'Please reload and try again.',
  type: 'danger',
};

export const createReviewSuccess = {
  id: 'pw-create-review-success',
  message: 'Your Feedback Has Been Received!',
  details: 'Please <a href="/account/signin">sign in</a> to view your bookings.',
  type: 'success',
};

export const webGLMessage = {
  id: 'pw-web-gl-message',
  message: 'Hang On!',
  details: 'Your browser does not have WebGL enabled. Please visit <a href="https://support.apple.com/kb/PH21426?locale=en_US" target="_blank">Apple</a> for instructions.',
  type: 'danger',
};

export const ticketmasterEventNotFoundError = {
  id: 'pw-tm-event-not-found',
  message: 'Aw Shucks!',
  details: "We can't find your Ticketmaster event, but you can search for events and other destinations below.",
  type: 'danger',
};
