import { Record, Map } from 'immutable';
import lowerCase from 'lodash/lowerCase';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import snakeCase from 'lodash/snakeCase';

import Announcement from 'models/announcement';

const STREET_PARKING_CITIES = [
  { city: 'New York', state: 'NY' },
  { city: 'Staten Island', state: 'NY' },
  { city: 'Brooklyn', state: 'NY' },
  { city: 'Queens', state: 'NY' },
  { city: 'Bronx', state: 'NY' },
  { city: 'Manhattan', state: 'NY' },
  { city: 'Seattle', state: 'WA' },
];

// Attributes we never want overwritten from the initial style
export const DIFF_BLACKLIST = [
  'routingStyle',
];

export const PAGE_BRAND_FIELDS = [
  'brand:logo_url_light',
  'brand:help_phone',
  'brand:help_website_url',
  'brand:show_app_download_information',
  'brand:show_footer',
  'brand:show_simplified_header',
  'brand:show_transition_interstitial_on_visit',
  'brand:hide_receipt_ctas',
  'brand:show_header',
].join(',');

export const COUNTRY_RESTRICTION_PROPS = {
  CA: {
    show_referral_program: false,
    require_marketing_opt_in: true,
  },
  US: {
    require_marketing_opt_in: false,
  },
};

export default class Brand extends Record({
  displayName: '',
  logoURL: null,
  logoLightURL: null,
  logoDarkURL: null,
  mobileLogoURL: null,
  businessLogoURL: null,
  mobileAppIconURL: null,
  ogImageURL: null,
  textToDownloadURL: null,
  appDownloadURLs: {
    ios: null,
    android: null,
    onelink: null,
  },
  appImageURLs: {
    text: null,
    poster: null,
    main: null,
  },
  appVideoURLs: {
    ios: null,
    android: null,
  },
  privacyPolicy: null,
  termsOfService: null,
  nonBookableRules: {
    display: null,
    locationCutoff: null,
  },
  showBusinessPaymentMethods: null,
  showReferralProgram: null,
  showAppDownloadInformation: null,
  showTransitionInterstitialOnVisit: null,
  showSimplifiedHeader: null,
  routingStyle: null,
  homepageLogoURL: '',
  helpPhone: '',
  helpWebsiteURL: '',
  helpHours: '',
  summaryOfServices: '',
  helpEmail: '',
  showFooter: null,
  showMobileSearchLogo: null,
  requireMarketingOptIn: true,
  hideReceiptCTAs: true,
  announcement: new Announcement(),
  showHeader: true,
}) {
  static cascadedBrand(props, { country } = { country: 'US' }) {
    const { locale = 'en-us' } = props;
    const countryRestrictions = COUNTRY_RESTRICTION_PROPS[country];
    let brand = new Brand({ ...props.brand, ...countryRestrictions, locale });
    const { affiliateBrand, pageBrand } = props;

    let brandProps;
    let brandDiff;

    if (affiliateBrand) {
      brandProps = Object.assign(props.brand, affiliateBrand);
      brand = new Brand({ ...brandProps, ...countryRestrictions, locale });
    }

    if (pageBrand) {
      brandProps = Object.assign(props.brand, pageBrand);
      brandDiff = brand.diff(new Brand({ ...pageBrand, ...countryRestrictions, locale }));
      brand = new Brand({ ...brandProps, ...countryRestrictions, locale });
    }

    return { brand, brandDiff };
  }

  static internationalizeHelpURL(url, { locale = 'en-us' } = { locale: 'en-us' }) {
    if (!url) { return url; }
    let localizedURL = `${url}/lang/${snakeCase(locale)}/`;
    localizedURL = localizedURL.replace('//lang', '/lang');
    return localizedURL;
  }

  static buildKey(displayName) {
    return lowerCase(displayName).replace(/\s+/g, '');
  }

  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let appImageURLs;
    if (props.app_image_urls) {
      appImageURLs = {
        text: props.app_image_urls.ios,
        poster: props.app_image_urls.android,
        main: props.app_image_urls.main,
      };
    }

    const helpWebsiteURL = Brand.internationalizeHelpURL(props.help_website_url, { locale: props.locale });
    const requireMarketingOptIn = Brand.buildKey(props.display_name) === 'bestparking' || props.require_marketing_opt_in;
    const announcement = new Announcement(props.announcement);

    super({
      displayName: props.display_name,
      logoURL: props.logo_url_light,
      logoLightURL: props.logo_url_light,
      logoDarkURL: props.logo_url_dark,
      mobileLogoURL: props.mobile_logo_url,
      mobileAppIconURL: props.mobile_app_icon_url,
      ogImageURL: props.og_image_url,
      businessLogoURL: props.business_logo_url,
      textToDownloadURL: props.text_to_download_url,
      appDownloadURLs: props.app_download_urls,
      appImageURLs,
      appVideoURLs: props.app_video_urls,
      privacyPolicy: props.privacy_policy,
      termsOfService: props.terms_of_service,
      nonBookableRules: props.non_bookable_rules,
      showBusinessPaymentMethods: props.show_business_payment_methods,
      showReferralProgram: props.show_referral_program,
      showAppDownloadInformation: props.show_app_download_information,
      showTransitionInterstitialOnVisit: props.show_transition_interstitial_on_visit,
      showSimplifiedHeader: props.show_simplified_header,
      routingStyle: props.routing_style || 'parkwhiz',
      homepageLogoURL: props.logo_url_home,
      helpPhone: props.help_phone_number,
      helpWebsiteURL,
      helpHours: props.help_hours,
      summaryOfServices: props.summary_of_services,
      helpEmail: props.help_email,
      showFooter: props.show_footer,
      showMobileSearchLogo: props.show_mobile_search_logo,
      requireMarketingOptIn,
      hideReceiptCTAs: props.hide_receipt_ctas,
      announcement,
      showHeader: props.show_header,
    });
  }

  get pid() {
    return `${this.displayName}.com`;
  }

  get key() {
    return Brand.buildKey(this.displayName);
  }

  get isBestParking() {
    return this.key === 'bestparking' || false;
  }

  get isParkWhiz() {
    return this.key === 'parkwhiz';
  }

  get curationEligible() {
    return this.isParkWhiz;
  }

  streetParkingEligible(locations) {
    if (!this.isBestParking) { return false; }
    return (
      locations.reduce((e, l) => (
        e || STREET_PARKING_CITIES.reduce((i, c) => (i || isEqual(c, { city: l.city, state: l.state })), false)
      ), false)
    );
  }

  diff(otherBrand) {
    let diff = Map();
    const keys = otherBrand.keys();
    let done = false;
    let key;
    while (!done) {
      ({ value: key, done } = keys.next());
      if (
        !isUndefined(otherBrand.get(key)) &&
        !DIFF_BLACKLIST.includes(key) &&
        !isEqual(this.get(key), otherBrand.get(key))
      ) {
        diff = diff.set(key, this.get(key));
      }
    }
    return diff;
  }
}
