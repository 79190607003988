import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Brand from 'models/brand';
import * as RoutingStyle from 'lib/routing-style';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  showParkingCitiesModal: PropTypes.func.isRequired,
  showSummaryOfServices: PropTypes.func.isRequired,
  showFAQ: PropTypes.func.isRequired,
};

class MinimalFooter extends React.Component {
  constructor(props) {
    super(props);
    this.renderCityTooltip = this.renderCityTooltip.bind(this);
    this.renderHelpTooltip = this.renderHelpTooltip.bind(this);
  }

  render() {
    return (
      <footer className="minimal-footer hidden-print hidden-lte-sm">
        <div className="col-sm-12">
          <ul className="footer-links">
            <li>
              <a href="https://www.arrive.com/about/">ABOUT US</a>
            </li>
            <li>
              <a href="/how-it-works/">HOW IT WORKS</a>
            </li>
            <li>
              <a
                href="#"
                className="cities-dropup"
                data-for="cities-dropup"
                data-event="click"
                data-tip
              >OTHER CITIES<span className="caret-up" />
              </a>
            </li>
            <li>
              <a href="/business/" target="_blank">BUSINESS</a>
            </li>
            <li>
              <a
                href="#"
                className="help-dropup"
                data-for="help-dropup"
                data-event="click"
                data-tip
              >HELP<span className="caret-up" /></a>
            </li>
          </ul>
        </div>
        {this.renderCityTooltip('cities-dropup')}
        {this.renderHelpTooltip('help-dropup')}
      </footer>
    );
  }

  renderCityTooltip(key) {
    const { brand } = this.props;
    return (
      <ReactTooltip
        id={key}
        effect="solid"
        globalEventOff="click"
        place="top"
        type="light"
        delayHide={50}
        offset={{ top: 0, left: 0 }}
      >
        <ul>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/boston-ma-parking/destinations/' : '/p/boston-parking/'}>Boston Parking</a>
          </li>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/chicago-il-parking/destinations/' : '/p/chicago-parking/'}>Chicago Parking</a>
          </li>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/chicago-il-parking/monthly-parking/' : '/p/chicago-monthly-parking/'}>Chicago Monthly Parking</a>
          </li>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/new-york-ny-parking/destinations/' : '/p/new-york-parking/'}>New York Parking</a>
          </li>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/new-york-ny-parking/monthly-parking/' : '/p/new-york-monthly-parking/'}>New York Monthly Parking</a>
          </li>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/san-francisco-ca-parking/destinations/' : '/p/san-francisco-parking/'}>San Francisco Parking</a>
          </li>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/toronto-on-parking/destinations/' : '/p/toronto-parking/'}>Toronto Parking</a>
          </li>
          <li>
            <a rel="external" href={brand.routingStyle === RoutingStyle.BESTPARKING ? '/washington-dc-parking/destinations/' : '/p/washington-dc-parking/'}>Washington DC Parking</a>
          </li>
          <li>
            <a rel="external" href="/parking-near-me/">Parking Near Me</a>
          </li>
          <li>
            <a className="visible-xs" onClick={this.props.showParkingCitiesModal}>Browse All Cities</a>
            <a className="hidden-xs" href="#citiesModal" role="button" data-toggle="modal" rel="external">Browse All Cities</a>
          </li>
        </ul>
      </ReactTooltip>
    );
  }

  renderHelpTooltip(key) {
    const { brand } = this.props;
    return (
      <ReactTooltip
        id={key}
        effect="solid"
        globalEventOff="click"
        place="top"
        type="light"
        delayHide={50}
        offset={{ top: 0, left: 30 }}
      >
        <ul className="help-tooltip">
          <li>
            <a href={brand.helpWebsiteURL} rel="noopener noreferrer" target="_blank">HELP CENTER</a>
          </li>
          <li>
            <a className="visible-xs text-nowrap" onClick={this.props.showSummaryOfServices}>SUMMARY OF SERVICES</a>
            <a className="hidden-xs text-nowrap" href="#summaryOfServiceModal" role="button" data-toggle="modal">SUMMARY OF SERVICES</a>
          </li>
          <li>
            <a href="#faqModal" role="button" data-toggle="modal" className="hidden-xs">FAQs</a>
            <a className="visible-xs" onClick={this.props.showFAQ}>FAQs</a>
          </li>
        </ul>
      </ReactTooltip>
    );
  }
}

MinimalFooter.propTypes = propTypes;
export default MinimalFooter;
