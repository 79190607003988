import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import cookie from 'react-cookie';
import moment from 'moment-timezone';
import { push } from 'connected-react-router';
import url from 'url';
import get from 'lodash/get';

import getBookingsCreator, { GET_BOOKINGS } from 'action-creators/bookings/get-bookings';
import getUpcomingBookingsCreator, { GET_UPCOMING_BOOKINGS } from 'action-creators/bookings/get-upcoming-bookings';
import { GET_PAST_BOOKINGS } from 'action-creators/bookings/get-past-bookings';
import gotBookingsCreator, { GOT_BOOKINGS } from 'action-creators/bookings/got-bookings';
import gotUpcomingBookings from 'action-creators/bookings/got-upcoming-bookings';
import gotPastBookings from 'action-creators/bookings/got-past-bookings';
import gotBookingCountsCreator from 'action-creators/bookings/got-booking-counts';
import getFrequentLocationsCreator, { GET_FREQUENT_LOCATIONS } from 'action-creators/bookings/get-frequent-locations';
import gotFrequentLocationsCreator from 'action-creators/bookings/got-frequent-locations';
import { INITIALIZE_BOOKINGS } from 'action-creators/bookings/initialize-bookings';
import { INITIALIZE_PARKING_PASSES } from 'action-creators/bookings/initialize-parking-passes';
import addMessage from 'action-creators/messaging/add-message';
import addMessageAndScrollToTop from 'action-creators/messaging/add-message-and-scroll-to-top';
import { ADD_VEHICLE } from 'action-creators/bookings/add-vehicle';
import gotVehicle from 'action-creators/bookings/got-vehicle';
import { ACTIVATE_ACCOUNT } from 'action-creators/account/activate-account';
import { DISMISS_ACTIVATE_ACCOUNT_MODAL, DISMISS_ACTIVATE_MODAL_COOKIE_NAME } from 'action-creators/bookings/dismiss-activate-account-modal';
import { DISMISS_ACTIVATE_ACCOUNT } from 'action-creators/bookings/dismiss-activate-account';
import { CANCEL_BOOKING } from 'action-creators/bookings/cancel-booking';
import bookingCancelled from 'action-creators/bookings/booking-cancelled';
import { INITIALIZE_CANCEL_BOOKING } from 'action-creators/bookings/initialize-cancel-booking';
import getSellerCreator from 'action-creators/parking-pass/get-seller';
import { CREATE_REVIEW } from 'action-creators/bookings/create-review';
import dismissReviewModal from 'action-creators/bookings/dismiss-review-modal';
import gotBrand from 'action-creators/brand/got-brand';

import BookingRequest from 'models/requests/bookings';
import { Vehicle } from 'models/vehicles';
import { PAGE_BRAND_FIELDS } from 'models/brand';
import { BOOKING_CANCELLABLE_FIELDS, BOOKING_FIELDS } from 'lib/api/base';

import ClientApi from 'lib/api/client';
import { genericError, bookingNotFoundMessage, bookingCancelUnauthorizedMessage, bookingNotCancellableMessage, bookingCancelSuccessMessage, parkingPassExpiredMessage, createReviewError, createReviewSuccess } from 'lib/common/messages';

const getUser = state => state.account.user;
const getBookings = state => state.bookings.bookings;
const getLocations = state => state.bookings.locations;
const getRequestQueue = state => state.bookings.requestQueue;
const getNextRequests = state => state.bookings.nextRequests;
const getBrand = state => state.brand.brand;
const getRouterLocation = state => state.router.location;
const getParkingPasses = state => state.bookings.parkingPasses;
const getLocale = state => state.app.locale;

function fetchBookings({ user, type, bookingsQuery, bookingRequest, requestQueue, nextRequests, routingStyle }) {
  const { token } = user;

  /**
   * This is an unfortunate workaround due to 'query' being used in
   * multiple actions.  Specifically, from checkout->receipt, changeApp
   * needs to occur as well as a getBookings stats request meaning
   * multiple ouput.query need to be defined.  In that case, we use
   * bookingsQuery.
   */
  const query = bookingsQuery;

  let requestObject = new BookingRequest(bookingRequest || nextRequests.get([type || 'bookings', query]));

  if (Array.isArray(query)) {
    query.forEach((q) => { requestObject = (requestObject[type] || requestObject)[q] || requestObject; });
  } else {
    requestObject = (requestObject[type] || requestObject)[query] || requestObject;
  }

  if (!requestObject.shouldRequest || !user.isLoggedIn()) {
    return { notRequested: true };
  }

  const { q, params, endpoint, requestType } = requestObject;

  return ClientApi[endpoint](q, params, token, requestType, requestQueue, { routingStyle })
    .then(({ body, response }) => {
      requestObject = requestObject.set('totalPages', parseInt(body.count || response.header['x-pagination-total-pages'], 10));
      const headers = response.header;
      return { body, requestObject, query, headers };
    })
    .catch(error => ({ error }));
}

export function* getBookingsFromAPI(action) {
  yield call(getBookingsWithCallback, action, gotBookingsCreator);
}

function* getBookingsWithCallback(action, successAction) {
  const { type, bookingRequest, fetchAll } = action.payload;
  const bookingsQuery = action.payload.query || action.payload.bookingsQuery;
  const user = yield select(getUser);
  const brand = yield select(getBrand);
  const requestQueue = yield select(getRequestQueue);
  const nextRequests = yield select(getNextRequests);

  const {
    body,
    error,
    query,
    requestObject,
    notRequested,
    headers,
  } = yield call(
    fetchBookings,
    {
      user,
      type,
      bookingsQuery,
      bookingRequest,
      nextRequests,
      requestQueue,
      routingStyle: brand.routingStyle,
    },
  );

  if (error) {
    yield put(addMessage(genericError));
  } else if (!notRequested) {
    const nextRequest = requestObject.merge({ page: requestObject.page + 1, requestType: 'bg' });
    if (body) {
      const payload = { queryType: query, bookingType: type, body, nextRequest, headers, requestAction: action };
      yield put(successAction(payload));
    }
    if (fetchAll) {
      yield put(getBookingsCreator({ type, query, fetchAll, bookingRequest: nextRequest }));
    }
  }
}

function getBookingCountsFromAPI({ query, type, user, requestQueue }) {
  let requestObject = new BookingRequest({
    fields: 'count',
    resultFormat: 'stats',
    endpoint: type,
    zoom: null,
    page: null,
    perPage: null,
    sort: null,
  });

  if (Array.isArray(query)) {
    query.forEach((q) => { requestObject = requestObject[q] || requestObject; });
  } else {
    requestObject = requestObject[query] || requestObject;
  }

  const { q, params, endpoint, requestType } = requestObject;
  const { token } = user;

  return ClientApi[endpoint](q, params, token, requestType, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }));
}

export function* getBookingCounts(action) {
  const { type } = action.payload;
  let { query } = action.payload;
  if (!query.find(q => q === 'cancelled')) { query = query.concat('active'); }
  const user = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  const { body, error } = yield call(getBookingCountsFromAPI, { type, query, user, requestQueue });
  if (error) {
    yield put(addMessage(genericError));
  } else {
    yield put(gotBookingCountsCreator({ type, query, body }));
  }
}

function getFrequentLocationsFromAPI({ user, routingStyle }) {
  const { token } = user;

  return ClientApi.frequent_locations(token, 'fg', null, { routingStyle })
    .then(({ body }) => ({ results: body }))
    .catch(error => ({ error }));
}

export function* getFrequentLocations() {
  const user = yield select(getUser);
  const brand = yield select(getBrand);

  const { results, error } = yield call(getFrequentLocationsFromAPI, { user, routingStyle: brand.routingStyle });

  if (error) {
    yield put(addMessage(genericError));
  } else {
    yield put(gotFrequentLocationsCreator({ results }));
  }
}

const putVehicleOnBooking = ({
  bookingId,
  vehicleId,
  isDefault,
  plateNumber,
  bookingAuthorizationCode,
  accessToken,
  requestQueue,
}) => (
  ClientApi
    .addVehicleToBooking(bookingId, { vehicleId, default: isDefault, plateNumber, bookingAuthorizationCode }, accessToken, 'fg', requestQueue)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* addVehicle(action) {
  const { bookingId, vehicleId, isDefault, plateNumber } = action.payload;
  const user = yield select(getUser);
  const requestQueue = yield select(getRequestQueue);
  
  const routerLocation = yield select(getRouterLocation);
  const { u: bookingAuthorizationCode } = url.parse(routerLocation.search, true).query || {};

  const { body, error } = yield call(putVehicleOnBooking, {
    bookingId,
    vehicleId,
    isDefault,
    plateNumber,
    bookingAuthorizationCode,
    accessToken: user.token,
    requestQueue,
  });

  if (error) {
    return;
  }

  const vehicle = new Vehicle(body);
  yield put(gotVehicle({ vehicle }));
}

export function* getUpcomingBookings(action) {
  const requestAction = {
    payload: {
      bookingsQuery: 'upcoming',
      bookingRequest: {
        page: action.payload.page,
        perPage: 6,
      },
    },
  };
  yield call(getBookingsWithCallback, requestAction, gotUpcomingBookings);
}

export function* getPastBookings(action) {
  yield call(getBookingsWithCallback, action, gotPastBookings);
}

export function* initializeBookings() {
  yield put(getFrequentLocationsCreator());
}

function* dismissActivate() {
  cookie.save(DISMISS_ACTIVATE_MODAL_COOKIE_NAME, true, { path: '/', expires: moment().add(1, 'week').toDate() });
  yield put({ type: DISMISS_ACTIVATE_ACCOUNT_MODAL });
}

function* activateUser() {
  const bookings = yield select(getBookings);
  const bookingActive = bookings.first() ? bookings.first().accountActivated : false;
  if (bookingActive) { yield put({ type: ACTIVATE_ACCOUNT }); }
}

const deleteBooking = ({ bookingId, authorizationCode, accessToken, requestQueue }) => (
  ClientApi.cancelBooking({
    bookingId,
    accessToken,
    requestQueue,
    requestType: 'fg',
    data: {
      authorizationCode,
      sendEmailConfirmation: true,
    },
  }).then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

function* cancelBooking(action) {
  const { booking } = action.payload;
  const locations = yield select(getLocations);
  const locale = yield select(getLocale);
  const location = locations.get(booking.locationId.toString());
  const user = yield select(getUser);
  const { token: accessToken } = user;
  const brand = yield select(getBrand);
  const requestQueue = yield select(getRequestQueue);
  if (!booking) {
    yield put(addMessage(bookingNotFoundMessage({ brand })));
    return;
  }

  const { cancellableNow, message } = get(booking, 'cancellableStatus', {});
  const putBookingNotCancellableMessage = () => put(addMessage(bookingNotCancellableMessage({ brand, message })));

  if (!cancellableNow) {
    yield putBookingNotCancellableMessage();
    return;
  }

  if (!user.isLoggedIn() && !booking.authorizationCode) {
    yield put(addMessage(bookingCancelUnauthorizedMessage({ brand })));
    return;
  }

  const { error } = yield call(deleteBooking, {
    bookingId: booking.id,
    authorizationCode: booking.authorizationCode,
    accessToken,
    requestQueue,
  });

  if (error) {
    yield putBookingNotCancellableMessage();
    return;
  }

  yield put(getUpcomingBookingsCreator({ page: 1 }));
  yield put.resolve(bookingCancelled({ booking }));
  yield put(addMessageAndScrollToTop(bookingCancelSuccessMessage({ booking, location, locale })));

  if (user.isLoggedIn()) {
    yield put(push('/account/'));
  } else {
    yield put(push('/'));
  }
}

const fetchBooking = ({ bookingId, accessToken, requestQueue, fields, zoom, authorizationCode = null }) => (
  ClientApi.getBookings({
    bookingId,
    queryParams: {
      fields,
      zoom,
      authorizationCode,
    },
  }, accessToken, 'fg', requestQueue)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* initializeCancelBooking(action) {
  const { selectedParkingPassIds } = action.payload;
  const user = yield select(getUser);
  const { token: accessToken } = user;
  const requestQueue = yield select(getRequestQueue);

  const routerLocation = yield select(getRouterLocation);
  const { u: authorizationCode } = url.parse(routerLocation.search, true).query || {};

  const { body } = yield call(fetchBooking, {
    bookingId: selectedParkingPassIds.first(),
    accessToken,
    requestQueue,
    authorizationCode,
    // TODO: Remove noncancellable_message once cancellable_status integrated into booking cancellation logic
    fields: `${BOOKING_CANCELLABLE_FIELDS},booking:noncancellable_message,location::default,location:timezone,partner::default,${PAGE_BRAND_FIELDS}`,
    zoom: 'pw:customer,pw:receipt,pw:location,pw:partner,pw:event,pw:parking_pass,pw:brand',
  });

  if (body) {
    yield put(gotBookingsCreator({
      body: [body],
      query: 'upcoming',
      bookingType: 'bookings',
      nextRequest: null,
      user,
    }));
  }

  const brand = get(body, '_embedded[pw:partner]._embedded[pw:brand]');
  if (brand) {
    yield put(gotBrand({ brand }));
  }
}
export function* initializeParkingPasses(action) {
  const { selectedParkingPassIds } = action.payload;

  let bookings = [];
  const user = yield select(getUser);
  const { token: accessToken } = user;
  const requestQueue = yield select(getRequestQueue);

  const routerLocation = yield select(getRouterLocation);
  const { query } = url.parse(routerLocation.search, true);
  let { u: authorizationCode } = query;
  if (!authorizationCode) { ({ p: authorizationCode } = query); }

  for (let i = 0; i < selectedParkingPassIds.size; i++) {
    const { body } = yield call(fetchBooking, {
      bookingId: selectedParkingPassIds.get(i),
      accessToken,
      requestQueue,
      authorizationCode,
      zoom: 'pw:parking_pass,pw:customer,pw:receipt,pw:location,pw:partner,pw:brand,pw:venue',
      fields: BOOKING_FIELDS,
    });

    if (body) {
      bookings = bookings.concat(body);
    }
  }

  yield put(gotBookingsCreator({
    body: bookings,
    query: 'upcoming',
    bookingType: 'bookings',
    nextRequest: null,
    user,
  }));

  const brand = get(bookings, '[0]._embedded[pw:partner]._embedded[pw:brand]');
  if (brand) {
    yield put(gotBrand({ brand }));
  }

  const parkingPasses = yield select(getParkingPasses);
  if (parkingPasses.first() && parkingPasses.first().isExpired) {
    yield put(addMessage(parkingPassExpiredMessage));
  }
  for (let i = 0; i < selectedParkingPassIds.size; i++) {
    const parkingPassId = selectedParkingPassIds.get(i);
    const parkingPass = parkingPasses.get(parkingPassId.toString());
    if (parkingPass) {
      const { sellerId } = parkingPasses.get(parkingPassId.toString());
      yield put(getSellerCreator({ sellerId }));
    }
  }
}

const postReview = ({
  rating,
  comment = null,
  bookingId,
  locationId,
  authorizationCode = null,
  accessToken,
  requestQueue = null,
}) => (
  ClientApi.postReview({
    locationId,
    data: {
      rating,
      comment,
      bookingId,
      authorizationCode,
    },
    accessToken,
    requestQueue,
  })
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* createReview(action) {
  const { rating, comment, bookingId, locationId, authorizationCode } = action.payload;
  const user = yield select(getUser);
  const { token: accessToken } = user;
  const requestQueue = yield select(getRequestQueue);

  const { error } = yield call(postReview, {
    rating,
    comment,
    bookingId,
    locationId,
    accessToken,
    requestQueue,
    authorizationCode,
  });

  if (error) {
    yield put(addMessage(createReviewError));
    yield put(dismissReviewModal());
    if (!user.isLoggedIn()) {
      yield put(push('/'));
    }
  } else if (!user.isLoggedIn) {
    yield put(addMessage(createReviewSuccess));
  }
  yield put(dismissReviewModal());
}

export default function* bookingsSaga() {
  yield takeEvery(GET_BOOKINGS, getBookingsFromAPI);
  yield takeEvery(GET_UPCOMING_BOOKINGS, getUpcomingBookings);
  yield takeEvery(GET_PAST_BOOKINGS, getPastBookings);
  yield takeEvery(GET_FREQUENT_LOCATIONS, getFrequentLocations);
  yield takeEvery(ADD_VEHICLE, addVehicle);
  yield takeEvery(INITIALIZE_BOOKINGS, initializeBookings);
  yield takeEvery(DISMISS_ACTIVATE_ACCOUNT, dismissActivate);
  yield takeEvery(GOT_BOOKINGS, activateUser);
  yield takeLatest(CANCEL_BOOKING, cancelBooking);
  yield takeLatest(CREATE_REVIEW, createReview);
  yield takeLatest(INITIALIZE_CANCEL_BOOKING, initializeCancelBooking);
  yield takeLatest(INITIALIZE_PARKING_PASSES, initializeParkingPasses);
}
