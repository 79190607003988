import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import moment from 'moment-timezone';
import Waypoint from 'react-waypoint';
import VisibilitySensor from 'react-visibility-sensor';
import Insights from '@parkwhiz-js/insights-sdk';
import { Map } from 'immutable';

import Coordinates from 'models/coordinates';
import UserModel from 'models/user';
import SearchModel from 'models/search';
import VenueModel from 'models/venue';
import Brand from 'models/brand';
import Hub from 'models/hub';

import { AppConsumer } from 'providers/app-provider';

import NavLogo from 'components/common/header/nav-logo';
import OurAppsLink from 'components/common/header/our-apps-link';
import MobileSearchHeader from 'components/search/mobile-header';
import MapboxAutocomplete from 'containers/mapbox/autocomplete';

import * as AppContext from 'lib/app-context';
import * as Apps from 'lib/app-names';
import { HOME_PAGE_NAV_VISIBLE } from 'lib/analytics/events';
import env from 'lib/env';
import { routerLocationShape } from 'lib/common/prop-types';
import { buildRedirectURI } from 'lib/common/url-helpers';

import trackEventCreator from 'action-creators/analytics/track-event';

const HYBRID_NAVIGATION_STYLE = 'hybrid';
const { PORTAL_URL, ADMIN_HOST } = env();

const propTypes = {
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  showMobileMenu: PropTypes.bool,
  navHref: PropTypes.string,
  displayMap: PropTypes.bool,
  navigationStyle: PropTypes.string,
  handleNavbarToggle: PropTypes.func.isRequired,
  app: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(UserModel),
  currentSearch: PropTypes.instanceOf(SearchModel),
  monthlyStart: PropTypes.instanceOf(moment),
  venue: PropTypes.instanceOf(VenueModel),
  timezone: PropTypes.string.isRequired,
  changeModalState: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  appContext: PropTypes.string,
  insights: PropTypes.instanceOf(Insights).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  affiliatePartner: PropTypes.instanceOf(Map),
  routerLocation: routerLocationShape.isRequired,
  hub: PropTypes.instanceOf(Hub),
  mounted: PropTypes.bool,
};

const defaultProps = {
  user: new UserModel(),
  navigationStyle: 'floating',
  showMobileMenu: false,
  navHref: '/',
  app: '',
  displayMap: null,
  currentSearch: null,
  monthlyStart: moment(),
  venue: null,
  appContext: AppContext.DESKTOP,
  affiliatePartner: Map(),
  hub: null,
  mounted: false,
};

export class FullHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hamburgerViewed: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.onNavbarVisibilityChange = this.onNavbarVisibilityChange.bind(this);
  }

  get navigationStyle() {
    if (this.props.navigationStyle === HYBRID_NAVIGATION_STYLE) {
      return 'static';
    }

    return 'floating';
  }

  get shouldRenderDistractionElements() {
    return this.props.app !== Apps.CHECKOUT_APP;
  }

  onNavbarVisibilityChange(isVisible) {
    if (!this.state.hamburgerViewed && this.props.app === 'Home' && this.props.appContext === AppContext.MOBILE) {
      this.setState({ hamburgerViewed: isVisible });
      if (isVisible) {
        this.props.trackEvent({
          ...HOME_PAGE_NAV_VISIBLE,
        });
      }
    }
  }

  renderAdminLink() {
    return (
      <li>
        <a href={ADMIN_HOST}>
          <div className="no-icon main-text uppercase">
            <FormattedMessage id="common.admin" defaultMessage="PARKWHIZ ADMIN" />
          </div>
        </a>
      </li>
    );
  }

  renderSellerLink() {
    return (
      <li>
        <a href={ADMIN_HOST}>
          <div className="no-icon main-text">
            <FormattedMessage id="common.seller-console" defaultMessage="SELLER CONSOLE" />
          </div>
        </a>
      </li>
    );
  }

  renderAffiliateLink() {
    return (
      <li>
        <a href={PORTAL_URL}>
          <div className="no-icon main-text">
            <FormattedMessage id="common.affiliate-console" defaultMessage="AFFILIATE CONSOLE" />
          </div>
        </a>
      </li>
    );
  }

  renderValidationLink() {
    return (
      <li>
        <a href="/account/" title="Parking Passes">
          <div className="no-icon main-text">
            <FormattedMessage id="common.validation-console" defaultMessage="PARKING VALIDATION" />
          </div>
        </a>
      </li>
    );
  }

  renderMyPasses() {
    if (!this.shouldRenderDistractionElements) { return null; }

    return (
      <li className="with-vertical-bar">
        <a href="/account/" title="My Passes">
          <FormattedMessage id="common.my-passes" defaultMessage="MY PASSES" />
          { this.props.user ? this.renderBookingCount() : null }
        </a>
      </li>
    );
  }

  renderAccountMenu() {
    return (
      <li>
        <a href="/account/" title="Account" role="button" className="dropdown-toggle">
          <span className="hidden-sm">{ this.props.user.email }</span>
          <span className="caret" />
        </a>
        <ul className="dropdown-menu" role="menu">
          <li>
            <div className="callout-up-outer">
              <div className="callout-up-inner" />
              <div className="callout-up-bottom-bar" />
            </div>
          </li>
          <li>
            <a href="/account/" title="My Parking">
              <div className="ticket-icon icon main-text">
                <FormattedMessage id="common.my-bookings" defaultMessage="MY PARKING" />
              </div>
              <div className="subtext">
                <FormattedMessage id="common.all-passes" defaultMessage="Parking passes, active and past" />
              </div>
            </a>
          </li>
          <li>
            <a href="/account/payment-methods/" title="Payment Methods">
              <div className="deco-credit-card-grey icon main-text">
                <FormattedMessage id="common.payment-methods" defaultMessage="PAYMENT METHODS" />
              </div>
              <div className="subtext">
                <FormattedMessage id="common.cards-and-payments" defaultMessage="Credit cards and business payments" />
              </div>
            </a>
          </li>
          <li>
            <a href="/account/settings/" title="Account Settings">
              <div className="gear-icon icon main-text">
                <FormattedMessage id="common.account-settings" defaultMessage="ACCOUNT SETTINGS" />
              </div>
              <div className="subtext">
                <FormattedMessage id="common.personal-info" defaultMessage="Personal info, passwords, and saved vehicles" />
              </div>
            </a>
          </li>
          <li className="divider" />
          { this.props.user.admin ? this.renderAdminLink() : null }
          { this.props.user.seller ? this.renderSellerLink() : null }
          { this.props.user.affiliate ? this.renderAffiliateLink() : null }
          { this.props.user.validated ? this.renderValidationLink() : null }
          <li>
            <a href="/signout/" title="Sign Out">
              <div className="sign-out-icon icon main-text">
                <FormattedMessage id="common.sign-out" defaultMessage="SIGN OUT" />
              </div>
            </a>
          </li>
          { !this.props.brand.isBestParking &&
            <Fragment>
              <li className="divider" />
              { this.renderReferralLink() }
              <li>
                <a href="/business/" title="Parkwhiz for Business">
                  <div className="suitcase-icon icon main-text uppercase">
                    <FormattedMessage id="common.for-business" defaultMessage="ParkWhiz for Business" />
                  </div>
                  <div className="subtext">
                    <FormattedMessage id="common.let-us-handle-business" defaultMessage="Let us handle the parking so you can handle the business" />
                  </div>
                </a>
              </li>
            </Fragment>
          }
        </ul>
      </li>
    );
  }

  renderSignUp() {
    if (!this.shouldRenderDistractionElements) { return null; }

    const { routerLocation, app, mounted } = this.props;
    let redirectURI;
    if (routerLocation && mounted) {
      redirectURI = buildRedirectURI(routerLocation);
    }

    const signUpButtonClassNames = cx({
      'header-signup': true,
      'all-white': ['Hub', 'Home'].includes(app),
      'all-slate': this.isDarkOnWhite,
    });

    return (
      <li className="link-button">
        <Link
          to={{
            pathname: '/account/signup/',
            search: redirectURI,
          }}
          title="Sign Up"
          className={signUpButtonClassNames}
        >
          <FormattedMessage id="common.sign-up" defaultMessage="SIGN UP" />
        </Link>
      </li>
    );
  }

  renderLogIn() {
    if (!this.shouldRenderDistractionElements) { return null; }

    const { routerLocation, mounted } = this.props;

    let redirectURI;
    if (routerLocation && mounted) {
      redirectURI = buildRedirectURI(routerLocation);
    }

    return (
      <li>
        <Link
          to={{
            pathname: '/account/signin/',
            search: redirectURI,
          }}
          title="Log In"
        >
          <FormattedMessage id="common.sign-in" defaultMessage="SIGN IN" />
        </Link>
      </li>
    );
  }

  renderReferralLink() {
    const { brand } = this.props;
    if (!brand.showReferralProgram) { return null; }

    return (
      <li>
        <a href="#" className="squatchpop">
          <div className="refer-a-friend-icon icon main-text">
            INVITE A FRIEND
          </div>
          <div className="subtext">
            <b><FormattedMessage id="common.give-5-get-10" defaultMessage="Give $5 get $10" /></b><br />
            <FormattedMessage id="common.every-2-friends" defaultMessage="For every two friends who give us a try" />
          </div>
        </a>
      </li>
    );
  }

  renderBookingCount() {
    if (this.props.user.bookingCount > 0) {
      return (
        <div className="nav-booking-count">{this.props.user.bookingCount}</div>
      );
    }
    return null;
  }

  setNavigationStyle(style) {
    this.setState({ navigationStyle: style });
  }

  handleScroll(e) {
    if (this.props.displayMap) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  renderMarginKillingStyles() {
    if (this.props.navigationStyle !== HYBRID_NAVIGATION_STYLE) { return null; }

    const disgustingCSS = `
      #non-header-content {
        margin: 0;
      }

      @media (max-width: 991px) {
        .with-deep-link-banner:not(.show-mobile-menu) #non-header-content {
          margin-top: 70px;
        }
      }
    `;

    return (
      <style>
        {disgustingCSS}
      </style>
    );
  }

  renderStyleWaypoint() {
    if (this.props.navigationStyle !== HYBRID_NAVIGATION_STYLE) { return null; }

    return (
      <Waypoint
        onEnter={() => { this.setNavigationStyle('static'); }}
        onLeave={() => { this.setNavigationStyle('floating'); }}
        scrollableAncestor={'window'}
      >
        <div style={{ height: 450, position: 'absolute' }} />
      </Waypoint>
    );
  }

  renderSearchField() {
    if (!this.shouldRenderDistractionElements) { return null; }

    const { app, geoIPLocation, currentSearch, hub, venue, brand } = this.props;

    if (app === 'Search') { return null; }

    const { lat, lng } = currentSearch;
    const buttonBackgroudColorClassName = brand.isBestParking ? 'background-color-bp-blue' : 'background-color-green';

    return (
      <form role="form" className="navbar-form navbar-left navbar-search" action="/search/" method="get">
        <fieldset>
          <div className="form-group padding-left-sm-10">
            <div className="input-group input-group-sm">
              <MapboxAutocomplete
                buttonClassName={`search-button ${buttonBackgroudColorClassName}`}
                buttonText="Find Parking"
                dropdownClassName="location-suggestion-bar-navbar"
                inputClassName="search-input form-control gplaces-input-dropdown"
                geoIPLocation={geoIPLocation}
                mapPin
                pinClassName="text-color-watermelon"
                placeholder="Where are you going?"
                placeholderId="common.where-are-you-going"
                currentDestination={{ lat, lng }}
                insights={this.props.insights}
                currentSearch={this.props.currentSearch}
                app={app}
                hub={hub}
                venue={venue}
                trackEvent={this.props.trackEvent}
                id="header-autocomplete"
              />
            </div>
          </div>
        </fieldset>
      </form>
    );
  }

  get isDarkOnWhite() {
    const darkOnWhiteApps = [
      Apps.HOW_IT_WORKS_APP,
      Apps.LEGAL_APP,
      Apps.PRESS_APP,
    ];
    return darkOnWhiteApps.includes(this.props.app) || false;
  }

  render() {
    const { user, app, showMobileMenu, navigationStyle, brand, affiliatePartner } = this.props;
    const navClasses = cx({
      'hidden-print': true,
      'show-navbar-form': true,
      'floating-nav': this.navigationStyle === 'floating',
      'static-nav': this.navigationStyle === 'static',
      'active': showMobileMenu,
      'slide-up': this.navigationStyle === 'static' && navigationStyle === HYBRID_NAVIGATION_STYLE,
      'slide-down': this.navigationStyle === 'floating' && navigationStyle === HYBRID_NAVIGATION_STYLE,
      'background-color-dark-slate-lte-sm': this.props.app === 'Search' && !this.props.brand.isBestParking,
      'background-color-bp-dark-blue-lte-sm': this.props.app === 'Search' && this.props.brand.isBestParking,
    });
    const floatingContentClasses = cx({
      'floating-only-content': true,
      'hide-float': this.navigationStyle === 'static',
      'col-xs-8': true,
      'col-md-12': true,
      'col-gutter-0': true,
    });
    const mobileMenuToggleClasses = cx({
      'nav-bar': true,
      'active': showMobileMenu,
      'hidden': !this.shouldRenderDistractionElements,
    });
    const mainNavClasses = cx({
      'container-fluid': true,
      'hidden-lte-sm': app === 'Search',
    });
    const navbarClasses = cx({
      'navbar navbar-default': true,
      'navbar-dark': this.isDarkOnWhite,
    });

    return (
      <div>
        {/* This guy needs to stick to the top of the page to register window scroll events. */}
        { this.renderStyleWaypoint() }
        <div id="desktop-nav" className={navClasses} onTouchMove={this.handleScroll}>
          { this.renderMarginKillingStyles() }
          <MobileSearchHeader
            showMobileMenu={this.props.showMobileMenu}
            currentSearch={this.props.currentSearch}
            monthlyStart={this.props.monthlyStart}
            handleNavbarToggle={this.props.handleNavbarToggle}
            venue={this.props.venue}
            timezone={this.props.timezone}
            app={this.props.app}
            brand={this.props.brand}
            event={this.props.timezone}
            changeModalState={this.props.changeModalState}
          />
          <div id="main-top-nav" className={mainNavClasses}>
            <div className="row">
              <nav className={navbarClasses} role="navigation">
                <div className="collapse navbar-collapse">
                  <div className="col-xs-12 col-md-6">
                    <div className="row">
                      <div className="col-xs-2 col-sm-1 visible-lte-sm">
                        <div className="navbar-header">
                          { this.shouldRenderDistractionElements &&
                            <VisibilitySensor onChange={this.onNavbarVisibilityChange} partialVisibility>
                              <a id="navbar-toggle" className={mobileMenuToggleClasses} onClick={this.props.handleNavbarToggle}>
                                <span />
                                <span />
                                <span />
                              </a>
                            </VisibilitySensor>
                          }
                        </div>
                      </div>
                      <div className={floatingContentClasses}>
                        <div className="margin-left-xs-0 margin-left-sm-15">
                          <NavLogo
                            brand={brand}
                            href={this.props.navHref}
                            app={this.props.app}
                            affiliatePartner={affiliatePartner}
                          />
                        </div>
                        { this.renderSearchField() }
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 hidden-xs hidden-sm">
                    <ul className="nav navbar-nav navbar-right list-inline pull-right">
                      { this.shouldRenderDistractionElements &&
                        <OurAppsLink brand={brand} />
                      }
                      <li className="with-vertical-bar">
                        <a href={brand.helpWebsiteURL} title="Help" target="_blank" rel="noopener noreferrer">
                          <FormattedMessage id="common.help" defaultMessage="HELP" />
                        </a>
                      </li>
                      { user.isLoggedIn() ? this.renderMyPasses() : this.renderLogIn() }
                      { user.isLoggedIn() ? this.renderAccountMenu() : this.renderSignUp() }
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FullHeader.propTypes = propTypes;
FullHeader.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    trackEvent: trackEventCreator,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const {
    hub,
    venue,
    timezone,
  } = state.search;

  const { insights } = state.analytics;

  const {
    name: app,
    appContext,
    affiliatePartner,
  } = state.app;

  const {
    location: routerLocation,
  } = state.router;

  const { brand } = state.brand;

  return ({
    app,
    appContext,
    hub,
    venue,
    timezone,
    insights,
    routerLocation,
    brand,
    affiliatePartner,
  });
};

const FullHeaderWrapper = props => (
  <AppConsumer><FullHeader {...props} /></AppConsumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(FullHeaderWrapper);
