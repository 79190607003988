import { Record, Map } from 'immutable';
import pick from 'lodash/pick';
import { BOOKING_CANCELLABLE_FIELDS } from 'lib/api/base';
import Booking from 'models/booking';

export default class BookingsRequest extends Record({
  page: 1,
  perPage: 30,
  totalPages: null,
  requestType: 'fg',
  resultFormat: '',
  query: Map(),
  zoom: 'pw:location,pw:vehicle,pw:event,pw:venue,pw:review,pw:booking_share',
  fields: `${BOOKING_CANCELLABLE_FIELDS},location::default,location:url,location:timezone,location:monthly_iframe_uri,booking:business_purchase,booking:expense_memo,venue:id,venue:enhanced_airport,review:booking_id,review:location_id,review:id,review:rating,review:created_at`,
  sort: 'start_time:asc id:asc',
  endpoint: 'bookings',
}) {
  static propToQuery(prop) {
    switch (prop) {
      case 'startTime':
        return 'starting';
      case 'endTime':
        return 'ending';
      default:
        return null;
    }
  }

  get q() {
    return this.query.toJS();
  }

  get params() {
    return pick(this, ['zoom', 'fields', 'sort', 'page', 'perPage', 'resultFormat']);
  }

  get stats() {
    return this.merge({
      resultFormat: 'stats',
      zoom: null,
      fields: 'count',
    });
  }

  get bookingType() {
    switch (this.endpoint) {
      case 'monthly_bookings':
        return 'monthly_booking';
      case 'bookings':
      default:
        return 'transient_booking';
    }
  }

  get upcoming() {
    const { beforeProp, threshold } = Booking.upcomingThreshold({ type: this.bookingType });

    return this.active.mergeDeep({
      query: {
        [`${this.constructor.propToQuery(beforeProp)}_after`]: threshold.format(),
      },
      sort: 'start_time:asc id:asc',
    });
  }

  get past() {
    const { beforeProp, threshold } = Booking.upcomingThreshold({ type: this.bookingType });

    return this.mergeDeep({
      query: {
        [`${this.constructor.propToQuery(beforeProp)}_before`]: threshold.format(),
      },
      sort: 'start_time:desc',
    });
  }

  get active() {
    return this.mergeDeep({
      query: {
        active: true,
      },
    });
  }

  get cancelled() {
    return this.mergeDeep({
      query: {
        cancelled: true,
      },
    });
  }

  get businessPurchase() {
    return this.mergeDeep({
      query: {
        businessPurchase: true,
      },
    });
  }

  get monthly() {
    return this.merge({
      endpoint: 'monthly_bookings',
      zoom: 'pw:location,pw:vehicle,pw:monthly_pricing',
      fields: 'location::default,location:timezone,location:url',
      sort: 'start_time:desc',
    });
  }

  get shouldRequest() {
    if (this.totalPages == null) {
      return true;
    }

    return this.page <= this.totalPages;
  }
}
