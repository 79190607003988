import { Record } from 'immutable';
import { get } from 'lodash';

import QuestionMark from 'components/svgs/icons/question-mark';

export class Fee extends Record({
  price: null,
  type: null,
}) {
  constructor(props, { currency } = {}) {
    let formattedPrice;
    const price = get(props, ['price', currency]);
    if (price) {
      formattedPrice = Number(price);

      // If float, display two decimal places
      if (formattedPrice % 1 !== 0) {
        formattedPrice = formattedPrice.toFixed(2);
      }
    }

    super({
      price: formattedPrice,
      type: props.type,
    });
  }

  get displayConfig() {
    switch (this.type) {
      case 'convenience_fee':
        return {
          child: this.constructor.getServiceFeeTooltipText,
          icon: QuestionMark,
          label: 'Service Fee',
          priority: 1,
        };
      case 'fee_rebate':
        return {
          label: 'Fee Waived',
          priority: 2,
        };
      default:
        return {
          label: 'Other Fee',
          priority: 100,
        };
    }
  }

  static getServiceFeeTooltipText(search) {
    if (search.event || search.isEventSearch) {
      return 'This helps us partner with operators to offer reserved parking for busy events where parking often sells out.';
    } else if (search.isDailySearch) {
      return 'This helps us run our business, but we’ll waive it if our price & the facility’s standard price are the same.';
    }
    return 'This helps us run our business and offer exceptional customer support.';
  }
}
