import { Record } from 'immutable';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';

// see core-api spec for booking_share
export default class BookingShare extends Record({
  acceptedAt: null,
  sharedAt: null,
  sharedStatus: null,
  sharedWith: [],
}) {
  constructor(props) {
    super(mapKeys(props, (v, k) => camelCase(k)));
  }

  static get SHARED_STATUS_PENDING() { return 'pending'; }
  static get SHARED_STATUS_ACCEPTED() { return 'accepted'; }

  get isSharedStatusAccepted() {
    return this.sharedStatus === BookingShare.SHARED_STATUS_ACCEPTED;
  }

  get isSharedStatusPending() {
    return this.sharedStatus === BookingShare.SHARED_STATUS_PENDING;
  }

  // sharedWith is an array  of objects like { shared_over, value }
  get sharedWithEmail() {
    const { sharedWith } = this;
    if (!sharedWith) {
      return '';
    }

    // eslint-disable-next-line camelcase
    return get(sharedWith.find(el => el.shared_over === 'email'), 'value', '');
  }
}

